import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Box } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { PartnerModel } from "../../../models/PartnerModel";
import { deletePartner } from "../../../services/partners/deletePartner";
import { FormateDate, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

interface ListTableProps {
  partners?: PartnerModel[];
  refetch?: () => void;
}

export function ListTable({ partners, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()

  const handleDelete = async () => {
    onClose();
    const response = await deletePartner(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} minW={"350"}>Nome da empresa</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {partners?.map((partner) => {
            return (
              <Tr key={partner.uuid}>
                <Td pl={"0"}>
                  <Box>
                    <Text fontWeight="bold">{partner.social_name}</Text>
                    <Text fontSize="sm" color="gray.300">
                      {partner.legal_name}
                    </Text>
                  </Box>
                </Td>
                <Td title={formatDistanceToNow(new Date(partner.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(partner.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {partner.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={"/partners/edit/" + partner.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(partner.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
