import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexPhone } from "../../services/hooks/useIndexPhone";
import { IndexPhoneProps } from "../../services/phones/indexPhone";
import { CompaniesNavigator } from "../../components/Form/Navigator/CompaniesNavigator";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";

export function List() {
  const { parent_uuid, parent } = useParams();

  const indexProps: IndexPhoneProps = {
    companyUUID: parent == "companies" ? parent_uuid : undefined,
    driverUUID: parent == "drivers" ? parent_uuid : undefined,
    partnerUUID: parent == "partners" ? parent_uuid : undefined
  }

  const { data, isFetching, refetch } = useIndexPhone(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "companies" ?
        <CompaniesNavigator step={3} uuid={parent_uuid} /> :
        parent == "partners" ?
          <PartnersNavigator step={3} uuid={parent_uuid} /> :
          <DriversNavigator step={3} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Telefones"}
            createNew={{
              to: "/phones/" + parent + "/" + parent_uuid + "/new",
              label: "Cadastrar novo",
            }}
            isLoading={isFetching}
            refetch={refetch}
          />

          {data?.phones.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable phones={data?.phones} refetch={refetch} parent={parent!} parent_uuid={parent_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
