import { ResponseApi } from "../../models/Api/ResponseApi";
import { DriverModel } from "../../models/DriverModel";
import { api } from "../api";
import { serialize } from 'object-to-formdata';

export async function updateDriver(driverData: DriverModel, photo: File, photoCnh: File, photoCnhBack: File): Promise<ResponseApi> {
    let response = {} as ResponseApi
    const formData = serialize(driverData, { indices: true, nullsAsUndefineds: true })
    if (photo != undefined) formData.append("photo", photo || "")
    if (photoCnh != undefined) formData.append("photo_cnh", photoCnh || "")
    if (photoCnhBack != undefined) formData.append("photo_cnh_back", photoCnhBack || "")

    await api.patch('driver/' + driverData.uuid, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}