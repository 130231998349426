import { ResponseApi } from "../../models/Api/ResponseApi";
import { api } from "../api";

interface CreateSessionData {
    email: string;
    password: string;
}
export async function createSession(sessionData: CreateSessionData): Promise<ResponseApi> {
    let response = {} as ResponseApi

    await api.post('sessions', sessionData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}