import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { FormToJson, Toast } from "../../utils/utils";
import { Form } from "../drivers/components/Form";
import { DriverModel } from "../../models/DriverModel";
import { createDriver } from "../../services/drivers/createDriver";

const formShema = yup.object().shape({
  name: yup.string().required("Obrigatório"),
  kind: yup.string().required("Obrigatório"),
  cpf: yup.string().required("Obrigatório"),
  name_mother: yup.string().required("Obrigatório"),
  cnh_register: yup.string().required("Obrigatório"),
  cnh_code: yup.string().required("Obrigatório"),
  cnh_maturity: yup.string().required("Obrigatório"),
  cnh_category: yup.string().required("Obrigatório"),
  cnh_uf: yup.string().required("Obrigatório"),
  // address: yup.object().shape({
  //   address: yup.string().required("Obrigatório"),
  //   city: yup.string().required("Obrigatório"),
  //   district: yup.string().required("Obrigatório"),
  //   postal_code: yup.string().required("Obrigatório"),
  //   uf: yup.string().required("Obrigatório"),
  // }),
  phone: yup.object().shape({
    number: yup.string().required("Obrigatório"),
  }),
  // bank_account: yup.object().shape({
  //   bank: yup.string().required("Obrigatório"),
  //   beneficiary_name: yup.string().required("Obrigatório"),
  //   beneficiary_document: yup.string().required("Obrigatório"),
  // })
});

export function New() {
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | DriverModel> = async (values) => {
    const photo = values.photo_file[0] as File;
    const photoCnh = values.photo_cnh_file[0] as File;
    const photoCnhBack = values.photo_cnh_back_file[0] as File;

    const data = JSON.parse(FormToJson(values)) as DriverModel
    const response = await createDriver(data, photo, photoCnh, photoCnhBack);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/drivers/edit/" + response.data.uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar motorista"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
