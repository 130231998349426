import { ResponseApi } from "../../models/Api/ResponseApi";
import { BankAccountModel } from "../../models/BankAccountModel";
import { api } from "../api";

export async function createBankAccount(addressData: BankAccountModel): Promise<ResponseApi> {

    let urlApi = "bank_accounts/"
    if (addressData.partner_uuid != undefined) {
        urlApi = urlApi + "partner/" + addressData.partner_uuid
    } else if (addressData.driver_uuid != undefined) {
        urlApi = urlApi + "driver/" + addressData.driver_uuid
    }

    let response = {} as ResponseApi
    await api.post(urlApi, addressData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}