import { Button, Flex, Icon, SimpleGrid, Stack } from "@chakra-ui/react";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputFile } from "../../components/Form/InputFile";
import { useEffect, useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import InputMask from "react-input-mask";
import { UserModel } from "../../models/UserModel";
import { updateUser } from "../../services/users/updateUser";
import { Toast } from "../../utils/utils";
import { showUser } from "../../services/users/showUser";

const formShema = yup.object().shape({
  name: yup.string().required("Obrigatório"),
  cpf: yup.string().required("Obrigatório"),
  email: yup.string().email("Formato inválido").required("Obrigatório"),
});

export function Edit() {

  const navigate = useNavigate();
  const { uuid } = useParams();
  const [userData, setUserData] = useState<UserModel>();
  const getUserData = async () => {
    const response = await showUser(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/users");
      return;
    }
    if (response?.data) {
      setUserData(response.data)
    }
  }

  useEffect(() => {
    getUserData();
  }, [])

  const [imageSelected, setImageSelected] = useState("");
  const { register, handleSubmit, setError, formState, setValue } = useForm({
    resolver: yupResolver(formShema),
  });
  const { errors } = formState;
  useEffect(() => {
    if (userData) {
      setValue("name", userData?.name);
      setValue("email", userData?.email);
      setValue("cpf", userData?.cpf);
    }
  }, [userData])

  const handleFormSubmit: SubmitHandler<FieldValues | UserModel> = async (values) => {
    const file = values.photo_file[0] as File;
    const userData = {
      uuid: uuid!,
      name: values.name.toUpperCase(),
      email: values.email.toUpperCase(),
      cpf: values.cpf.toUpperCase(),
      password: values.password.toUpperCase(),
    } as UserModel;
    const response = await updateUser(userData, file);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/users");
    }
  };

  return (
    <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
      <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
        <ContainerHeader title={"Editar Usuário"} />
        <Stack direction={"column"} spacing={"4"}>
          <Input type="file" display={"none"}
            {...register("photo_file", {
              onChange: (e) => {
                if (e.target.files[0] !== undefined) {
                  setImageSelected(e.target.files[0].name);
                } else {
                  setImageSelected("");
                }
              },
            })}
          />
          <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
            <Input
              label="Nome completo"
              error={errors.name}
              {...register("name")}
            />
            <Input
              as={InputMask}
              label="CPF"
              error={errors.cpf}
              mask={"999.999.999-99"}
              defaultValue={userData?.cpf}
              {...register("cpf")}
            />
          </SimpleGrid>
          <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
            <InputFile
              label="Foto"
              placeholder={"Selecione uma imagem"}
              error={errors.photo}
              value={imageSelected}
              onClick={() => {
                document.getElementById("photo_file")?.click();
              }}
              {...register("photo")}
            />
          </SimpleGrid>
          <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
            <Input
              label="Email"
              type="email"
              error={errors.email}
              {...register("email")}
            />
            <Input
              label="Senha"
              type="password"
              error={errors.password}
              {...register("password")}
            />
          </SimpleGrid>
        </Stack>

        <Flex mt={"8"} justify={"flex-end"}>
          <Stack direction={"row"} spacing={"4"}>
            <Button
              onClick={() => { navigate(-1) }}
              colorScheme={"orange"}
              size={"lg"}
              leftIcon={<Icon as={RiCloseCircleLine} fontSize={"20"} />}
            >
              Cancelar
            </Button>
            <Button
              type={"submit"}
              colorScheme={"teal"}
              size={"lg"}
              leftIcon={<Icon as={RiCheckboxCircleLine} fontSize={"20"} />}
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </Stack>
        </Flex>
      </DefaultBox>
    </Flex>
  );
}
