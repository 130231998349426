import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";
import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { CompanyModel } from "../../models/CompanyModel";
import { api } from "../api";

type GetCompaniesResponse = {
    companies: CompanyModel[];
    paginator: FilterPaginatorApi;
}

export async function indexCompany(paginator?: FilterPaginatorApi): Promise<GetCompaniesResponse> {
    const { data } = await api.get('companies', {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })

    const response = data as ResponseApi
    const companiesData = response.data.results as CompanyModel[]
    const paginatorData = response.data.paginator as FilterPaginatorApi

    return {
        companies: companiesData,
        paginator: paginatorData,
    };
}


export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = [
            {
                field: "social_name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "legal_name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "document",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            }
        ]
    }

    return filter;
}