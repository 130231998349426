import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateDate, FormateMoneyFloat, FormateQuilo, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { StorageModel } from "../../../models/StorageModel";
import { deleteStorage } from "../../../services/storages/deleteStorage";

interface ListTableProps {
  storages?: StorageModel[];
  parent: string;
  parent_uuid: string;
  refetch?: () => void;
}

export function ListTable({ storages, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteStorage(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} pr={"0"}># Nº</Th>
            <Th>Operação</Th>
            <Th>Produto</Th>
            <Th>Peso (Kg)</Th>
            <Th>Placa</Th>
            <Th>Motorista</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {storages?.map((storage) => {
            return (
              <Tr key={storage.uuid}>
                <Td pl={"0"} pr={"0"}>
                  <Text fontWeight="bold"># {storage.number.toUpperCase()}</Text>
                </Td>
                <Td>{(storage.kind.toUpperCase())}</Td>
                <Td>{(storage.product_name.toUpperCase())}</Td>
                <Td>{FormateQuilo(storage.amount.toString() ?? "")}</Td>
                <Td>{(storage.vehicle_plate.toUpperCase())}</Td>
                <Td>{(storage.driver_name.toUpperCase())}</Td>
                <Td title={formatDistanceToNow(new Date(storage.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(storage.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {storage.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={parent != undefined ? "/storages/" + parent + "/" + parent_uuid + "/edit/" + storage.uuid : "/storages-general" + "/edit/" + storage.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(storage.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
