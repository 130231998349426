import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react"
import React from "react"
import { useConfirmDelete } from "../../contexts/ConfirmDeleteContext";

interface DeleteconfirmAlertProps {
  onDelete: () => void;
}

export function ConfirmDeleteAlert({ onDelete }: DeleteconfirmAlertProps) {
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>
  const { isOpen, onClose } = useConfirmDelete()

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Confirmação de exclusão
          </AlertDialogHeader>

          <AlertDialogBody>
            Tem certeza que deseja excluir este registro?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme='red' onClick={onDelete} ml={3}>
              Deletar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}