import { Box, BoxProps, useColorMode } from "@chakra-ui/react";

interface DefaultBoxProps extends BoxProps {
  children: React.ReactNode;
}

export function DefaultBox({ children, ...rest }: DefaultBoxProps) {
  const { colorMode } = useColorMode();

  return (
    <Box
      flex={1}
      borderRadius={8}
      bg={colorMode === "dark" ? "gray.800" : "white"}
      borderColor={colorMode === "dark" ? "gray.700" : "gray.800"}
      boxShadow={"md"}
      p={"8"}
      height={"fit-content"}
      {...rest}
    >
      {children}
    </Box>
  );
}
