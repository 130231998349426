import { Button, Flex, Icon, Stack } from "@chakra-ui/react"
import { ReactNode } from "react"
import { FieldValues, UseFormReturn } from "react-hook-form"
import { RiCloseCircleLine, RiCheckboxCircleLine } from "react-icons/ri"
import { NavigateFunction } from "react-router-dom"

interface FormActionsProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    children?: ReactNode
}

export function FormActions({ navigate, useFormReturn, children }: FormActionsProps) {
    const { formState } = useFormReturn

    return (
        <Flex mt={"8"} justify={"flex-end"}>
            <Stack direction={"row"} spacing={"4"}>
                {children}
                <Button
                    onClick={() => { navigate(-1) }}
                    colorScheme={"orange"}
                    size={"lg"}
                    leftIcon={<Icon as={RiCloseCircleLine} fontSize={"20"} />}>
                    Cancelar
                </Button>
                <Button
                    type={"submit"}
                    colorScheme={"teal"}
                    size={"lg"}
                    leftIcon={<Icon as={RiCheckboxCircleLine} fontSize={"20"} />}
                    isLoading={formState.isSubmitting}>
                    Salvar
                </Button>
            </Stack>
        </Flex>
    )
}