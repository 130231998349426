import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexTransportInvoice } from "../../services/hooks/useIndexTransportInvoice";
import { TransportsNavigator } from "../../components/Form/Navigator/TransportsNavigator";

export function List() {
  const { transport_uuid } = useParams();
  const { data, isFetching, refetch } = useIndexTransportInvoice(transport_uuid!);
  return (
    <Stack w={"100%"} spacing={"0"} overflow={"auto"}>

      <TransportsNavigator step={2} uuid={transport_uuid} /> :

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Notas fiscais"}
            createNew={
              parent != undefined ? {
                to: "/transports-general/invoices/" + transport_uuid + "/new",
                label: "Cadastrar novo",
              } : undefined
            }
            isLoading={isFetching}
            refetch={refetch}
          />

          {data?.transportInvoices?.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable transportInvoices={data?.transportInvoices} refetch={refetch} transport_uuid={transport_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
