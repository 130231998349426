import { createContext, useContext, useEffect, useState } from "react";
import { ResponseApi } from "../models/Api/ResponseApi";
import { createSession } from "../services/sessions/createSessions";
import { setCookie, parseCookies, destroyCookie } from "nookies"
import { UserModel } from "../models/UserModel";
import { showUser } from "../services/users/showUser";
import jwtDecode from "jwt-decode"
import { api } from "../services/api";
import { useNavigate } from "react-router-dom";

type SingInCredentials = {
    email: string;
    password: string;
};

type AuthContextData = {
    singIn(credentials: SingInCredentials): Promise<ResponseApi>;
    userData?: UserModel
}

const AuthContext = createContext({} as AuthContextData);

type AuthProviderProps = {
    children: React.ReactNode;
}

export function SingOut() {
    destroyCookie(undefined, "Multigrãos.token");
    destroyCookie(undefined, "Multigrãos.refresh_token");
    window.location.href = "/login";
}

export function SetupCookies(token: string, refresh_token: string) {
    setCookie(undefined, "Multigrãos.token", token, {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: "/",
    })
    setCookie(undefined, "Multigrãos.refresh_token", refresh_token, {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: "/",
    })
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function AuthProvider({ children }: AuthProviderProps) {
    const [userData, setUserData] = useState<UserModel>()
    const navigate = useNavigate();

    const setupUserData = async () => {
        const { "Multigrãos.token": token } = parseCookies();
        if (token) {
            const payload = jwtDecode(token) as any;
            const response = await showUser(payload.data)
            if (response.data) {
                setUserData(response.data)
                return
            }
        }

        destroyCookie(undefined, "Multigrãos.token");
        destroyCookie(undefined, "Multigrãos.refresh_token");
        navigate("/login");
    }

    useEffect(() => {
        setupUserData()
    }, [])

    async function singIn({ email, password }: SingInCredentials) {

        const response = await createSession({
            email: email,
            password: password,
        });

        if (response.data) {
            const { token, refresh_token } = response.data;
            SetupCookies(token, refresh_token);
            setupUserData();
        }
        return response;
    }
    return (
        <AuthContext.Provider value={{ singIn, userData }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);
