import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportDataToCreateModel } from "../../models/TransportModel";
import { api } from "../api";

export async function showTransportDataToCreate(): Promise<TransportDataToCreateModel> {
    let urlApi = "transports/data-to-create"

    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const transportsData = response.data as TransportDataToCreateModel

    return transportsData;
}