import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Box } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateQuilo, Toast } from "../../../utils/utils";
import { TransportContractModel } from "../../../models/TransportContractModel";
import { deleteTransportContract } from "../../../services/transportContracts/deleteTransportContract";

interface ListTableProps {
  transportContracts?: TransportContractModel[];
  parent?: string;
  parent_uuid?: string;
  refetch?: () => void;
}

export function ListTable({ transportContracts, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteTransportContract(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} pr={"0"}>ORIGEM / DESTINO</Th>
            <Th>MOTORISTA</Th>
            <Th>SACAS CARREGADAS</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {transportContracts?.map((transportContract) => {
            return (
              <Tr key={transportContract.uuid}>
                <Td pl={"0"} pr={"0"}>
                  <Box>
                    <Text fontSize="sm">{(transportContract.transport_origin.toUpperCase())}-{(transportContract.transport_origin_uf.toUpperCase())}</Text>
                    <Text fontSize="sm">{(transportContract.transport_destiny.toUpperCase())}-{(transportContract.transport_destiny_uf.toUpperCase())}</Text>
                  </Box>
                </Td>
                <Td>
                  <Box>
                    <Text fontSize="sm">{(transportContract.vehicle_plate.toUpperCase())}</Text>
                    <Text fontSize="sm" color="gray.300">{(transportContract.driver_name.toUpperCase())}</Text>
                  </Box>
                </Td>
                <Td>{FormateQuilo(transportContract.total_weight.toString())}</Td>
                <Td textAlign={"end"} pr={"0"}>
                  {transportContract.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton mr={"4"} as={Link} to={"/transport_contracts/" + parent + "/" + parent_uuid + "/edit/" + transportContract.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
