import { SimpleGrid, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface FleetsNavigatorProps {
    uuid?: string;
    step: number;
}

export function FleetsNavigator({ uuid, step }: FleetsNavigatorProps) {
    return (
        <SimpleGrid columns={2} spacing={"4"} w={"100%"} mb={"4"}>
            <Button as={Link} to={"/vehicles/fleet/edit/" + uuid} colorScheme={step == 1 ? "teal" : "gray"} variant={step == 1 ? "solid" : "outline"}>1. Informações base</Button>
            <Button as={Link} to={"/maintenances/fleet/" + uuid} colorScheme={step == 2 ? "teal" : "gray"} variant={step == 2 ? "solid" : "outline"}>2. Manutenções</Button>
        </SimpleGrid>
    )
}