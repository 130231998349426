import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { StorageModel } from "../../models/StorageModel";
import { api } from "../api";
import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";

type GetStoragesResponse = {
    storages: StorageModel[];
    paginator?: FilterPaginatorApi;
}

export type IndexStorageProps = {
    driverUUID?: string,
    vehicleUUID?: string,
    contractUUID?: string,
    paginator?: FilterPaginatorApi
}

export async function indexStorage({ driverUUID, vehicleUUID, contractUUID, paginator }: IndexStorageProps): Promise<GetStoragesResponse> {
    let urlApi = "storages"
    if (driverUUID != undefined) {
        urlApi = urlApi + "/driver/" + driverUUID
    } else if (vehicleUUID != undefined) {
        urlApi = urlApi + "/vehicle/" + vehicleUUID
    } else if (contractUUID != undefined) {
        urlApi = urlApi + "/contract/" + contractUUID
    }

    const { data } = await api.get(urlApi, {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
    let storagesData: StorageModel[] = []
    let paginatorData = undefined

    const response = data as ResponseApi
    if (response.data.results != undefined) {
        storagesData = response.data.results as StorageModel[]
        paginatorData = response.data.paginator as FilterPaginatorApi
    } else {
        storagesData = response.data as StorageModel[]
    }

    return {
        storages: storagesData,
        paginator: paginatorData
    };
}

export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = [
            {
                field: "products.name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "drivers.name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "vehicles.plate",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            }
        ]
    }

    return filter;
}