import { ResponseApi } from "../../models/Api/ResponseApi";
import { BankAccountModel } from "../../models/BankAccountModel";
import { api } from "../api";

export async function updateBankAccount(bankAccountData: BankAccountModel): Promise<ResponseApi> {
    let response = {} as ResponseApi

    await api.patch('bank_account/' + bankAccountData.uuid, bankAccountData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}