import { Flex, Heading, Icon, IconButton, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiFileLine, RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexTransport } from "../../services/hooks/useIndexTransport";
import { IndexTransportProps, makeFilters } from "../../services/transports/indexTransport";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { useFilter } from "../../contexts/FilterContext";
import { SearchList } from "../../components/ContainerSearch/ContainerSearch";
import { Pagination } from "../../components/Pagination/Pagination";
import { TransportReportList } from "../../components/ContainerTransportReport/ContainerTransportReport";
import { useTransportReport } from "../../contexts/TransportReportContext";

export function List() {
  const { parent_uuid, parent } = useParams();
  const { filterData } = useFilter();
  const hasPaginator = (parent == "vehicles" || parent == "drivers" || parent == "contracts-general")
  const { onToggle: toggleTransportReport } = useTransportReport();

  const indexProps: IndexTransportProps = {
    driverUUID: parent == "drivers" ? parent_uuid : undefined,
    vehicleUUID: parent == "vehicles" ? parent_uuid : undefined,
    contractUUID: parent == "contracts-general" ? parent_uuid : undefined,
    paginator: !hasPaginator ? makeFilters(filterData) : undefined,
  }
  const { data, isFetching, refetch } = useIndexTransport(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"} overflow={"auto"}>

      {parent == "drivers" ?
        <DriversNavigator step={7} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={4} uuid={parent_uuid} /> :
          <></>
      }

      <SearchList />
      <TransportReportList />

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Carregamentos"}
            createNew={{
              to: parent != undefined ? "/transports/" + parent + "/" + parent_uuid + "/new" : "/transports-general/new",
              label: "Cadastrar novo",
            }}
            isLoading={isFetching}
            refetch={refetch}
            search={!hasPaginator}
          >
            <IconButton
              aria-label={"Relatório"}
              size={"sm"}
              fontSize={"sm"}
              colorScheme={"teal"}
              variant={"outline"}
              icon={<Icon as={RiFileLine} fontSize={"20"} />}
              onClick={(e) => {
                toggleTransportReport()
              }}
            />
          </ContainerHeader>

          {data?.transports.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable transports={data?.transports} refetch={refetch} parent={parent!} parent_uuid={parent_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}

          <Pagination
            limit={data?.paginator?.details.limit}
            page={data?.paginator?.details.page}
            results={data?.transports?.length}
          />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
