import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DriverModel } from "../../models/DriverModel";
import { updateDriver } from "../../services/drivers/updateDriver";
import { FormToJson, Toast } from "../../utils/utils";
import { showDriver } from "../../services/drivers/showDriver";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { Form } from "../drivers/components/Form";

const formShema = yup.object().shape({
  name: yup.string().required("Obrigatório"),
  kind: yup.string().required("Obrigatório"),
  cpf: yup.string().required("Obrigatório"),
  name_mother: yup.string().required("Obrigatório"),
  cnh_register: yup.string().required("Obrigatório"),
  cnh_code: yup.string().required("Obrigatório"),
  cnh_maturity: yup.string().required("Obrigatório"),
  cnh_category: yup.string().required("Obrigatório"),
  cnh_uf: yup.string().required("Obrigatório"),
  // address: yup.object().shape({
  //   address: yup.string().required("Obrigatório"),
  //   city: yup.string().required("Obrigatório"),
  //   district: yup.string().required("Obrigatório"),
  //   postal_code: yup.string().required("Obrigatório"),
  //   uf: yup.string().required("Obrigatório"),
  // }),
  phone: yup.object().shape({
    number: yup.string().required("Obrigatório"),
  }),
  // bank_account: yup.object().shape({
  //   bank: yup.string().required("Obrigatório"),
  //   beneficiary_name: yup.string().required("Obrigatório"),
  //   beneficiary_document: yup.string().required("Obrigatório"),
  // })
});

export function Edit() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [driverData, setDriverData] = useState<DriverModel>();
  const getDriverData = async () => {
    const response = await showDriver(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/drivers");
      return;
    }
    if (response?.data) {
      setDriverData(response.data)
    }
  }
  useEffect(() => {
    getDriverData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | DriverModel> = async (values) => {
    const photo = values.photo_file[0] as File;
    const photoCnh = values.photo_cnh_file[0] as File;
    const photoCnhBack = values.photo_cnh_back_file[0] as File;

    const data = JSON.parse(FormToJson(values)) as DriverModel
    data.uuid = uuid!

    const response = await updateDriver(data, photo, photoCnh, photoCnhBack);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/drivers");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      <DriversNavigator step={1} uuid={uuid} />
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar dados do motorista"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} driverData={driverData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
