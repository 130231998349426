import { Link, LinkProps } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { cloneElement, ReactElement } from "react";
import { useColorMode } from "@chakra-ui/react";

interface ActiveLinkProps extends LinkProps {
  children: ReactElement;
  shouldMatchExactHref?: boolean;
}

export function ActiveLink({
  children,
  shouldMatchExactHref = false,
  ...rest
}: ActiveLinkProps) {
  const { pathname } = useLocation();

  let isActive = false;

  if (shouldMatchExactHref && pathname === rest.to && !isActive) {
    isActive = true;
  }

  if (!shouldMatchExactHref && pathname.startsWith(String(rest.to)) && !isActive) {
    isActive = true;
  }

  if (!isActive) {
    const secondPath = pathname.split("/")
    const restTo = String(rest.to).split("/")
    if (secondPath.length >= 3) {
      if (secondPath[2] == restTo[restTo.length - 1]) {
        isActive = true;
      }
    }
  }

  const { colorMode } = useColorMode();

  return (
    <Link {...rest}>
      {cloneElement(children, {
        color: isActive
          ? "teal.400"
          : colorMode === "dark"
            ? "gray.50"
            : "gray.500",
      })}
    </Link>
  );
}
