import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { createBankAccount } from "../../services/bankAccounts/createBankAccount";
import { BankAccountModel } from "../../models/BankAccountModel";
import { FormToJson, Toast } from "../../utils/utils";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { Form } from "../bankAccounts/components/Form";

const formShema = yup.object().shape({
  bank: yup.string().required("Obrigatório"),
  beneficiary_name: yup.string().required("Obrigatório"),
  // beneficiary_document: yup.string().required("Obrigatório"),
});

export function New() {
  const { parent_uuid, parent } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | BankAccountModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as BankAccountModel
    data.driver_uuid = parent == "drivers" ? parent_uuid : undefined
    data.partner_uuid = parent == "partners" ? parent_uuid : undefined

    const response = await createBankAccount(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/bank_accounts/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "partners" ?
        <PartnersNavigator step={4} uuid={parent_uuid} /> :
        <DriversNavigator step={4} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar Conta Bancaria"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}