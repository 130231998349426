import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction, useParams } from "react-router-dom";
import { StorageDataToCreateModel, StorageModel } from "../../../models/StorageModel";
import { FormActions } from "../../../components/Form/FormActions";
import { FormateQuilo, HandleMaskQuilo } from "../../../utils/utils";
import { showStorageDataToCreate } from "../../../services/storages/showStorageDataToCreate";
import { OptionsProps, Select2 } from "../../../components/Form/Select";
import { indexVehicle } from "../../../services/vehicles/indexVehicle";
import { InputFile } from "../../../components/Form/InputFile";

interface FormProps {
  navigate: NavigateFunction
  useFormReturn: UseFormReturn<FieldValues, any>
  storageData?: StorageModel
}

export function Form({ navigate, useFormReturn, storageData }: FormProps) {
  const { parent_uuid, parent } = useParams();

  const { reset, register, formState, setValue } = useFormReturn
  const { errors } = formState;

  const [storageDataToCreate, setStorageDataToCreate] = useState<StorageDataToCreateModel>();
  const getStorageDataToCreate = async () => {
    const dataToCreate = await showStorageDataToCreate();
    setStorageDataToCreate(dataToCreate)
  }

  const [OptionsVehicles, setOptionsVehicles] = useState<OptionsProps[]>([{ label: "Selecione o motorista", value: "" }]);
  const getVehiclesByDriver = async (driverUUID: string) => {
    const vehicleResponse = await indexVehicle({ driverUUID: driverUUID });
    let vehiclesToPush: OptionsProps[] = []
    if (vehicleResponse.vehicles.length > 0) {
      for (let index = 0; index < vehicleResponse.vehicles.length; index++) {
        if (vehicleResponse.vehicles[index].tractor_uuid == undefined) {
          vehiclesToPush.push({ label: vehicleResponse.vehicles[index].plate, value: vehicleResponse.vehicles[index].uuid })
        }
      }
    }

    if (vehiclesToPush.length > 0) {
      setOptionsVehicles(vehiclesToPush);
    } else {
      setOptionsVehicles([{ label: "Nenhum trator encontrado", value: "" }])
    }
    setValue("vehicle_uuid", "");
  }

  const [OptionsDrivers, setOptionsDrivers] = useState<OptionsProps[]>([]);
  const [OptionsContracts, setOptionsContracts] = useState<OptionsProps[]>([]);

  useEffect(() => {
    getStorageDataToCreate();
    if (parent == "drivers" && parent_uuid != undefined) {
      getVehiclesByDriver(parent_uuid)
    }
    if (storageData != undefined && storageData.driver_uuid) {
      getVehiclesByDriver(storageData.driver_uuid)
    }
  }, [storageData])

  useEffect(() => {
    if (storageDataToCreate != undefined) {
      let driversData: OptionsProps[] = []
      let contractsData: OptionsProps[] = []

      if (storageDataToCreate?.drivers != undefined) {
        for (let index = 0; index < storageDataToCreate?.drivers.length; index++) {
          const element = storageDataToCreate?.drivers[index];
          driversData.push({ label: element.name.toUpperCase(), value: element.uuid })
        }
      }

      if (storageDataToCreate?.contracts != undefined) {
        for (let index = 0; index < storageDataToCreate?.contracts.length; index++) {
          const element = storageDataToCreate?.contracts[index];
          contractsData.push({ label: element.name.toUpperCase(), value: element.uuid })
        }
      }
      setOptionsContracts(contractsData);
      setOptionsDrivers(driversData);
      reset(OptionsDrivers);
      reset(OptionsContracts);
    }
  }, [storageDataToCreate, storageData])

  useEffect(() => {
    if (storageData) {
      if (storageData?.lost_amount != undefined) setValue("lost_amount", FormateQuilo(storageData?.lost_amount.toString() ?? ""));
      setValue("amount", FormateQuilo(storageData?.amount.toString() ?? ""));
      setValue("kind", storageData?.kind);
      setValue("contract_uuid", storageData?.contract_uuid);
      setValue("vehicle_uuid", storageData?.vehicle_uuid);
      setValue("driver_uuid", storageData?.driver_uuid);
    }
  }, [OptionsDrivers, OptionsContracts, OptionsVehicles, storageData])

  const OptionsKind: OptionsProps[] = [
    { label: "RECEBIMENTO DE PRODUTO", value: "RECEBIMENTO" },
    { label: "RETIRADA DE PRODUTO", value: "RETIRADA" },
  ]

  const [proofSelected, setProofSelected] = useState("");
  return (
    <>
      <Input type="file" display={"none"}
        {...register("proof_file", {
          onChange: (e) => {
            if (e.target.files[0] !== undefined) {
              setProofSelected(e.target.files[0].name);
            } else {
              setProofSelected("");
            }
          },
        })}
      />

      <Stack direction={"column"} spacing={"4"}>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>

          <Select2
            label="Contrato"
            options={OptionsContracts}
            error={errors.contract_uuid}
            useFormReturn={useFormReturn}
            name={"contract_uuid"}
            selectedValue={storageData?.contract_uuid}
          />

          <Select2
            label="Operação de Armazenamento"
            options={OptionsKind}
            error={errors.kind}
            useFormReturn={useFormReturn}
            name={"kind"}
            selectedValue={storageData?.kind}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Select2
            label="Motorista"
            options={OptionsDrivers}
            error={errors.driver_uuid}
            useFormReturn={useFormReturn}
            name={"driver_uuid"}
            onChangeSelect={(driverUUID) => { getVehiclesByDriver(driverUUID) }}
            selectedValue={storageData?.driver_uuid}
          />

          <Select2
            label="Trator"
            options={OptionsVehicles}
            error={errors.vehicle_uuid}
            useFormReturn={useFormReturn}
            name={"vehicle_uuid"}
            selectedValue={storageData?.vehicle_uuid}
          />

        </SimpleGrid>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Peso Total (Quilos)"
            error={errors.amount}
            onKeyUp={HandleMaskQuilo}
            {...register("amount")}
          />

          <Input
            label="Quebra Total (Quilos)"
            error={errors.lost_amount}
            onKeyUp={HandleMaskQuilo}
            {...register("lost_amount")}
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <InputFile
            label="Tiket Balança (Imagem ou PDF)"
            placeholder={"Selecione uma imagem"}
            error={errors.proof}
            value={proofSelected}
            imageURL={storageData?.proof}
            onClick={() => {
              document.getElementById("proof_file")?.click();
            }}
            {...register("proof")}
          />
        </SimpleGrid>
      </Stack>

      <FormActions navigate={navigate} useFormReturn={useFormReturn} />
    </>
  )
}