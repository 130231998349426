import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { BankAccountModel } from "../../../models/BankAccountModel";
import InputMask from "react-input-mask";
import { FormActions } from "../../../components/Form/FormActions";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    bankAccountData?: BankAccountModel
}

export function Form({ navigate, useFormReturn, bankAccountData }: FormProps) {
    const [maskBeneficiaryDocument, setMaskBeneficiaryDocument] = useState("999.999.999-99")

    const { register, formState, setValue } = useFormReturn
    const { errors } = formState;

    useEffect(() => {
        if (bankAccountData) {
            setValue("bank", bankAccountData?.bank);
            setValue("account", bankAccountData?.account);
            setValue("banch", bankAccountData?.banch);
            setValue("pix_key", bankAccountData?.pix_key);
            setValue("beneficiary_document", bankAccountData?.beneficiary_document);
            setValue("beneficiary_name", bankAccountData?.beneficiary_name);
        }
    }, [bankAccountData])

    return (
        <>
            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Banco"
                        error={errors.bank}
                        {...register("bank")}
                    />
                    <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                        <Input
                            label="Agência"
                            error={errors.banch}
                            {...register("banch")}
                        />
                        <Input
                            label="Conta"
                            error={errors.account}
                            {...register("account")}
                        />
                    </SimpleGrid>
                </SimpleGrid>
                <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                    <Input
                        label="Chave PIX"
                        error={errors.pix_key}
                        {...register("pix_key")}
                    />
                </SimpleGrid>
                <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                    <Input
                        label="Beneficiário Nome"
                        error={errors.beneficiary_name}
                        {...register("beneficiary_name")}
                    />
                    <Input
                        as={InputMask}
                        label="Beneficiário CPF ou CNPJ"
                        mask={maskBeneficiaryDocument}
                        maskChar={''}
                        defaultValue={bankAccountData?.beneficiary_document}
                        error={errors.beneficiary_document}
                        {...register("beneficiary_document", {
                            onChange: (e) => {
                                const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                                if (clearValue.length <= 11) {
                                    setMaskBeneficiaryDocument("999.999.999-999")
                                } else {
                                    setMaskBeneficiaryDocument("99.999.999/9999-99")
                                }
                            },
                        })}
                    />
                </SimpleGrid>
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}