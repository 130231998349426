import { Table, Box, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { UserModel } from "../../../models/UserModel";
import { deleteUser } from "../../../services/users/deleteUser";
import { FormateDate, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

interface ListTableProps {
  users?: UserModel[];
  refetch?: () => void;
}

export function ListTable({ users, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()

  const handleDelete = async () => {
    onClose();
    const response = await deleteUser(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"}>Usuário</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.map((user) => {
            return (
              <Tr key={user.uuid}>
                <Td pl={"0"}>
                  <Box>
                    <Text fontWeight="bold">{user.name}</Text>
                    <Text fontSize="sm" color="gray.300">
                      {user.email}
                    </Text>
                  </Box>
                </Td>
                <Td title={formatDistanceToNow(new Date(user.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(user.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {user.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={"/users/edit/" + user.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(user.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
