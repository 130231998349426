import { ResponseApi } from "../../models/Api/ResponseApi";
import { CompanyModel } from "../../models/CompanyModel";
import { api } from "../api";

export async function createCompany(productData: CompanyModel): Promise<ResponseApi> {

    let response = {} as ResponseApi
    await api.post('companies', productData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}