import {
  Flex,
  Heading,
  IconButton,
  Icon,
  Button,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { RiSearchLine, RiAddLine, RiRefreshLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useFilter } from "../../contexts/FilterContext";

interface ContainerHeaderProps {
  title: string;
  isLoading?: boolean;
  search?: boolean;
  refetch?: () => void;
  children?: ReactNode;
  createNew?: {
    to: string;
    label: string;
  };
}

export function ContainerHeader({
  title,
  createNew,
  refetch,
  children,
  isLoading = false,
  search = false,
}: ContainerHeaderProps) {
  const { disclosure } = useFilter();
  return (
    <>
      <Flex mb={"8"} justify={"space-between"} align="center" direction={["column", "row"]}>
        <Heading size={"lg"} fontWeight={"normal"} flex={"1"}>
          {title}
          {isLoading && <Spinner ml={"4"} />}
        </Heading>
        <Stack direction={"row"} mt={["6", "0"]} spacing={"4"}>
          {children}
          {refetch && (
            <IconButton
              aria-label={"Atualizar"}
              size={"sm"}
              fontSize={"sm"}
              colorScheme={"blue"}
              variant={"outline"}
              icon={<Icon as={RiRefreshLine} fontSize={"20"} />}
              onClick={refetch}
            />
          )}
          {search && (
            <IconButton
              aria-label={"Buscar"}
              size={"sm"}
              fontSize={"sm"}
              colorScheme={"orange"}
              variant={"outline"}
              icon={<Icon as={RiSearchLine} fontSize={"20"} />}
              onClick={disclosure.onToggle}
            />
          )}
          {createNew && (
            <Button
              as={Link}
              to={createNew.to}
              size={"sm"}
              fontSize={"sm"}
              colorScheme={"teal"}
              leftIcon={<Icon as={RiAddLine} fontSize={"20"} />}
            >
              {createNew.label}
            </Button>
          )}
        </Stack>
      </Flex>
    </>
  );
}
