import { Flex, Avatar, Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { useAuth } from "../../contexts/AuthContext";
import { FilePrefix } from "../../services/api";

export function Profile() {
  const { userData } = useAuth();
  const userNameSplit = userData?.name.split(" ");
  const userName = userData?.name ? userNameSplit?.[0] + " " + userNameSplit?.[userNameSplit?.length - 1] : "";
  const photoUrl = userData?.photo ? FilePrefix + userData?.photo : "";

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex align={"center"}>
      {isWideVersion && (
        <Box mr={"4"} textAlign={"right"}>
          <Text fontSize={"lg"} textTransform={"capitalize"}>{userName}</Text>
          <Text fontSize={"sm"} color={"gray.300"} textTransform={"lowercase"}>
            {userData?.email}
          </Text>
        </Box>
      )}
      <Avatar
        size={"md"}
        name={userName}
        src={photoUrl}
        bg={"gray.500"}
      />
    </Flex>
  );
}
