import { Skeleton, Stack, Text, theme } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { DefaultBox } from "../DefaultBox/DefaultBox";

interface DataProps {
  name: string;
  data: number[];
  categories: string[];
}

interface AreaChartProps {
  title: string;
  data: DataProps;
}

const delay = 1;
export function AreaChart({ title, data }: AreaChartProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: theme.colors.gray[500],
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      enabledOnSeries: [0],
      y: {
        title: {
          formatter: (v) => "",
        },
      },
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        color: theme.colors.gray[600],
      },
      axisTicks: {
        color: theme.colors.gray[600],
      },
      categories: data.categories,
    },
    colors: [theme.colors.teal[500]],
    fill: {
      opacity: 0.3,
      type: "gradient",
      gradient: {
        shade: "dark",
        opacityFrom: 0.7,
        opacityTo: 0.3,
      },
    },
  } as ApexCharts.ApexOptions;

  const series = [
    {
      name: data.name,
      data: data.data,
    },
  ];

  return (
    <DefaultBox pb={"4"}>
      <Text fontSize={"lg"} mb={"4"}>
        {title}
      </Text>
      {!show && (
        <Stack>
          <Skeleton height={"22px"} />
          <Skeleton height={"22px"} />
          <Skeleton height={"22px"} />
          <Skeleton height={"22px"} />
          <Skeleton height={"22px"} />
          <Skeleton height={"22px"} />
        </Stack>
      )}

      {show && (
        <Chart
          options={options}
          series={series}
          type={"area"}
          height={"160px"}
        />
      )}
    </DefaultBox>
  );
}
