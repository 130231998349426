import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";
import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { AddressModel } from "../../models/AddressModel";
import { api } from "../api";

type GetAddresssResponse = {
    addresses: AddressModel[];
}

export type IndexAddressProps = {
    companyUUID?: string,
    partnerUUID?: string,
    driverUUID?: string,
}

export async function indexAddress({ partnerUUID, companyUUID, driverUUID }: IndexAddressProps): Promise<GetAddresssResponse> {
    let urlApi = "addresses/"
    if (companyUUID != undefined) {
        urlApi = urlApi + "company/" + companyUUID
    } else if (partnerUUID != undefined) {
        urlApi = urlApi + "partner/" + partnerUUID
    } else if (driverUUID != undefined) {
        urlApi = urlApi + "driver/" + driverUUID
    }
    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const addressesData = response.data as AddressModel[]

    return {
        addresses: addressesData
    };
}