import { Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react";
import { RiAlertFill } from "react-icons/ri";

export default function Error404() {
  return (
    <Flex w={"100vw"} h={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={4} justify={"center"} align={"center"}>
        <Icon as={RiAlertFill} boxSize={"50px"} color={"orange.300"} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Error 404
        </Heading>
        <Text color={"gray.500"}>Ooops, parece que você está perdendo.</Text>
      </Stack>
    </Flex>
  );
}
