import { ResponseApi } from "../../models/Api/ResponseApi";
import { BankAccountModel } from "../../models/BankAccountModel";
import { api } from "../api";

type GetBankAccountsResponse = {
    bankAccounts: BankAccountModel[];
}

export type IndexBankAccountProps = {
    partnerUUID?: string,
    driverUUID?: string,
    companyUUID?: string,
}

export async function indexBankAccount({ partnerUUID, driverUUID, companyUUID }: IndexBankAccountProps): Promise<GetBankAccountsResponse> {
    let urlApi = "bank_accounts/"
    if (partnerUUID != undefined) {
        urlApi = urlApi + "partner/" + partnerUUID
    } else if (driverUUID != undefined) {
        urlApi = urlApi + "driver/" + driverUUID
    } else if (companyUUID != undefined) {
        urlApi = urlApi + "company/" + companyUUID
    }
    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const bankAccountsData = response.data as BankAccountModel[]

    return {
        bankAccounts: bankAccountsData
    };
}