import { ResponseApi } from "../../models/Api/ResponseApi";
import { AddressModel } from "../../models/AddressModel";
import { api } from "../api";

export async function createAddress(addressData: AddressModel): Promise<ResponseApi> {

    let urlApi = "addresses/"
    if (addressData.company_uuid != undefined) {
        urlApi = urlApi + "company/" + addressData.company_uuid
    } else if (addressData.partner_uuid != undefined) {
        urlApi = urlApi + "partner/" + addressData.partner_uuid
    } else if (addressData.driver_uuid != undefined) {
        urlApi = urlApi + "driver/" + addressData.driver_uuid
    }

    let response = {} as ResponseApi
    await api.post(urlApi, addressData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}