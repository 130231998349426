import { SimpleGrid, Button, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface DriversNavigatorProps {
    uuid?: string;
    step: number;
}

export function DriversNavigator({ uuid, step }: DriversNavigatorProps) {
    return (
        <Stack mb={"4"}>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={"4"}>
                <Button as={Link} to={"/drivers/edit/" + uuid} colorScheme={step == 1 ? "teal" : "gray"} variant={step == 1 ? "solid" : "outline"}>1. Informações base</Button>
                <Button as={Link} to={"/addresses/drivers/" + uuid} colorScheme={step == 2 ? "teal" : "gray"} variant={step == 2 ? "solid" : "outline"}>2. Endereços</Button>
                <Button as={Link} to={"/phones/drivers/" + uuid} colorScheme={step == 3 ? "teal" : "gray"} variant={step == 3 ? "solid" : "outline"}>3. Telefones</Button>
                <Button as={Link} to={"/bank_accounts/drivers/" + uuid} colorScheme={step == 4 ? "teal" : "gray"} variant={step == 4 ? "solid" : "outline"}>4. Contas</Button>
            </SimpleGrid>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={"4"}>
                <Button as={Link} to={"/vehicles/drivers/" + uuid} colorScheme={step == 5 ? "teal" : "gray"} variant={step == 5 ? "solid" : "outline"}>5. Veículos</Button>
                <Button as={Link} to={"/transactions/drivers/" + uuid} colorScheme={step == 6 ? "teal" : "gray"} variant={step == 6 ? "solid" : "outline"}>6. Financeiro</Button>
                <Button as={Link} to={"/transports/drivers/" + uuid} colorScheme={step == 7 ? "teal" : "gray"} variant={step == 7 ? "solid" : "outline"}>7. Carregamentos</Button>
                <Button as={Link} to={"/files/drivers/" + uuid} colorScheme={step == 8 ? "teal" : "gray"} variant={step == 8 ? "solid" : "outline"}>8. Arquivos</Button>
            </SimpleGrid>
        </Stack>
    )
}