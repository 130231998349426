import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TransportModel } from '../../models/TransportModel';
import { FormateDate, FormateMoneyFloat, FormateQuilo } from '../../utils/utils';

export function TransportReport(transports: TransportModel[], start: string, end: string) {
    let content = [];
    let transportIndex = 0;
    for (const transport of transports) {
        transport.driver_kind = (transport.driver_kind == "TRUCKER" ? "TERCEIRO" : (transport.driver_kind == "AGGREGATE" ? "AGREGADO" : (transport.driver_kind == "EMPLOYEER" ? "FROTA" : "ASSISTENTE")));

        let spliter = transport.driver_name.split(" ");
        transport.driver_name = spliter[0] + " " + spliter[spliter.length - 1];

        let saldoReal = transport?.freight_cost - (transport?.payment ?? 0) - (transport?.lost_cost ?? 0);
        let saldo = (Math.round(saldoReal * 100) / 100).toFixed(2);

        transportIndex++;
        let body = [[
            { text: [{ text: 'PLACA: ', bold: true }, transport.vehicle_plate], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", },
            { text: [{ text: 'TIPO: ', bold: true }, transport.driver_kind], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", },
            { text: [{ text: 'MOTORISTA: ', bold: true }, transport.driver_name], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
            { text: [{ text: 'PRODUTO: ', bold: true }, transport.product_name], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
            // { text: [{ text: 'ORIGEN: ', bold: true }, transport.origin + " - " + transport.origin_uf], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 3 }, '', '',
            // { text: [{ text: 'DESTINO: ', bold: true }, transport.destiny + " - " + transport.destiny_uf], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 3 }, '', '',
            { text: [{ text: 'DATA: ', bold: true }, (transport?.transport_date != undefined) ? FormateDate(transport.transport_date!, "DD/MM/YYYY") : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", },
        ],
        [
            { text: [{ text: 'EMPRESA: ', bold: true }, transport.company_name], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
            { text: [{ text: 'PESO TOTAL: ', bold: true }, (transport?.total_quantity != undefined) ? FormateQuilo(transport.total_quantity!.toString()) + "KG" : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
            { text: [{ text: 'QUEBRA: ', bold: true }, (transport?.lost_quantity != undefined) ? FormateQuilo(transport.lost_quantity!.toString()) + "KG" : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
            { text: [{ text: 'QUEBRA DESC.: ', bold: true }, (transport?.lost_cost != undefined) ? "R$ " + FormateMoneyFloat(transport.lost_cost!.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
            { text: [{ text: 'VALOR FRETE: ', bold: true }, (transport?.freight_cost != undefined) ? "R$ " + FormateMoneyFloat(transport.freight_cost.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
            { text: [{ text: 'ADIANTAMENTO: ', bold: true }, (transport?.payment != undefined) ? "R$ " + FormateMoneyFloat(transport.payment.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
            { text: [{ text: 'SALDO: ', bold: true }, (saldo != undefined) ? "R$ " + FormateMoneyFloat(saldo.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", },
        ]] as any;

        if (transport.transport_contracts != undefined) {
            let contractIndex = 0;
            for (const contract of transport.transport_contracts) {
                contractIndex++;
                body.push([
                    { text: [{ text: `CONTRATO ${contract.contract_kind ?? "-"} #${contractIndex}: `, bold: true }, contract.contract_number ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 3 }, '', '',
                    { text: [{ text: `${contract.contract_kind == "COMPRA" ? "FORNECEDOR" : "CLIENTE"}:`, bold: true }, contract.partner_name ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 4 }, '', '', '',
                    // { text: [{ text: 'UMIDADE: ', bold: true }, contract.moisture ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
                    // { text: [{ text: 'UMID. DESC.: ', bold: true }, (contract.moisture_discont != undefined) ? FormateQuilo(contract.moisture_discont!.toString()) + "KG" : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
                    // { text: [{ text: 'IMPUREZA: ', bold: true }, contract.impurity ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
                    // { text: [{ text: 'IMP. DESC.: ', bold: true }, (contract.impurity_discount != undefined) ? FormateQuilo(contract.impurity_discount!.toString()) + "KG" : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
                    { text: [{ text: 'PESO LIQ: ', bold: true }, (contract.total_weight != undefined) ? FormateQuilo(contract.total_weight!.toString()) + "KG" : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 1 },
                ])
            }
        }

        if (transport.transport_invoices != undefined) {
            let invoiceIndex = 0;
            for (const invoice of transport.transport_invoices) {
                invoiceIndex++;
                body.push([
                    { text: [{ text: `FIRMA EMISSORA NF #${invoiceIndex}: `, bold: true }, invoice.company ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 4 }, '', '', '',
                    { text: [{ text: 'Nº DA NOTA FISCAL:', bold: true }, invoice.identifier ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
                    { text: [{ text: 'QTDE. KG:', bold: true }, (invoice.total_weight != undefined) ? FormateQuilo(invoice.total_weight!.toString()) + "KG" : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
                    { text: [{ text: 'VALOR NF:', bold: true }, (invoice?.total_amount != undefined) ? "R$ " + FormateMoneyFloat(invoice.total_amount.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
                    { text: [{ text: 'VALOR IMPOSTO:', bold: true }, (invoice?.total_tax != undefined) ? "R$ " + FormateMoneyFloat(invoice.total_tax.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
                ])
            }
        }

        if (transport.costs != undefined) {
            let costIndex = 0;
            for (const cost of transport.costs) {
                costIndex++;
                body.push([
                    { text: [{ text: `CUSTO ADICIONAL #${costIndex}: `, bold: true }, cost.description ?? "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 10 }, '', '', '', '', '', '', '', '', '',
                    { text: [{ text: 'VALOR:', bold: true }, (cost?.amount != undefined) ? "R$ " + FormateMoneyFloat(cost.amount.toString()) : "-"], borderColor: ['#3d3d3d', '#3d3d3d', '#3d3d3d', '#3d3d3d'], fillColor: (transportIndex % 2 == 0) ? '#ffffff' : "#cccccc", colSpan: 2 }, '',
                ])
            }
        }


        content.push({
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                body: body,
            }
        })
    }

    pdfMake.createPdf({
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: content,
        pageMargins: [10, 30, 10, 20],
        styles: {
            tableExample: {
                fontSize: 6,
                margin: [0, 20, 0, 0]
            },
        },
        footer: function (currentPage, pageCount) {
            return [
                {
                    text: currentPage.toString() + ' of ' + pageCount,
                    fontSize: 10,
                    bold: false,
                    alignment: 'right',
                    margin: [0, 0, 15, 0] //left, top, right, bottom
                },
            ]
        },
        header: function (currentPage, pageCount, pageSize) {
            return [
                {
                    text: 'RELATORIO DE CARREGAMENTO: ' + start + ' - ' + end,
                    fontSize: 15,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 10, 0, 0] //left, top, right, bottom
                },
            ]
        },
    }, undefined, undefined, pdfFonts.pdfMake.vfs).open();
}