import { Stack, Box, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { FilterContext } from "../../contexts/FilterContext";
import { PaginationItem } from "./PaginationItem";

interface PaginationProps {
  limit?: number;
  page?: number;
  results?: number;
}

export function Pagination({ limit = 20, page = 1, results = 0 }: PaginationProps) {
  const { updateFilterData } = useContext(FilterContext);

  return (
    <Stack
      direction={["column", "row"]}
      spacing="6"
      mt="8"
      justify="space-between"
      align="center"
    >
      <Box>
        <strong>{(page - 1) * limit + 1}</strong> - <strong>{(page * limit) - limit + results}</strong>
      </Box>
      <Stack direction="row" spacing="2">
        {page > 1 && (
          <PaginationItem
            onPageChange={(newPage) => updateFilterData({ page: newPage })}
            number={page - 1}
            title={"Anterior"}
          />
        )}


        {results >= limit && (
          <PaginationItem
            onPageChange={() => { }}
            number={page}
            isCurrent
          />
        )}

        {results >= limit && (
          <PaginationItem
            onPageChange={(newPage) => updateFilterData({ page: newPage })}
            number={page + 1}
            title={"Próxima"}
          />
        )}
      </Stack>
    </Stack>
  );
}
