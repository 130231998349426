import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { showFile } from "../../services/files/showFile";
import { updateFile } from "../../services/files/updateFile";
import { FileModel } from "../../models/FileModel";
import { Form } from "../files/components/Form";

const formShema = yup.object().shape({
  title: yup.string().required("title"),
});

export function Edit() {
  const { uuid, parent, parent_uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [fileData, setFileData] = useState<FileModel>();
  const getFileData = async () => {
    const response = await showFile(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate(parent != undefined ? "/files/" + parent + "/" + parent_uuid : "/files-general");
      return;
    }
    if (response?.data) {
      setFileData(response.data)
    }
  }
  useEffect(() => {
    getFileData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | FileModel> = async (values) => {
    const proof = values.proof_file[0] as File;
    const data = JSON.parse(FormToJson(values)) as FileModel
    data.uuid = uuid!
    data.driver_uuid = (parent == "drivers" ? parent_uuid : undefined)
    data.contract_uuid = (parent == "contracts-general" ? parent_uuid : undefined)

    const response = await updateFile(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/files/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={8} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={3} uuid={parent_uuid} /> :
          <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Arquivo"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} fileData={fileData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
