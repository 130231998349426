import { useState, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Button, SimpleGrid, Flex, Icon, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { RiCloseCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { NavigateFunction } from "react-router-dom";
import { CompanyModel } from "../../../models/CompanyModel";
import InputMask from "react-input-mask";
import { FormActions } from "../../../components/Form/FormActions";

interface FormProps {
  navigate: NavigateFunction
  useFormReturn: UseFormReturn<FieldValues, any>
  companyData?: CompanyModel
}

export function Form({ navigate, useFormReturn, companyData }: FormProps) {
  const [maskPhone, setMaskPhone] = useState("(99) 9999-99999")
  const [maskBeneficiaryDocument, setMaskBeneficiaryDocument] = useState("999.999.999-99")

  const { register, formState, setValue } = useFormReturn
  const { errors } = formState;

  useEffect(() => {
    if (companyData) {
      setValue("social_name", companyData?.social_name);
      setValue("legal_name", companyData?.legal_name);
      setValue("document", companyData?.document);
      setValue("state_registration", companyData?.state_registration);
      setValue("phone.number", companyData?.phone?.number);
      setValue("address.address", companyData?.address?.address);
      setValue("address.city", companyData?.address?.city);
      setValue("address.district", companyData?.address?.district);
      setValue("address.uf", companyData?.address?.uf);
      setValue("address.postal_code", companyData?.address?.postal_code);
      setValue("bank_account.account", companyData?.bank_account?.account);
      setValue("bank_account.banch", companyData?.bank_account?.banch);
      setValue("bank_account.bank", companyData?.bank_account?.bank);
      setValue("bank_account.beneficiary_document", companyData?.bank_account?.beneficiary_document);
      setValue("bank_account.beneficiary_name", companyData?.bank_account?.beneficiary_name);
      setValue("bank_account.pix_key", companyData?.bank_account?.pix_key);
    }
  }, [companyData])

  return (
    <>
      <Stack direction={"column"} spacing={"4"}>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Empresa (Sigla)"
            error={errors.social_name}
            {...register("social_name")}
          />
          <Input
            as={InputMask}
            label="Telefone"
            mask={maskPhone}
            maskChar={''}
            defaultValue={companyData?.phone?.number}
            error={errors.phone?.number}
            {...register("phone.number", {
              onChange: (e) => {
                const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                if (clearValue.length <= 10) {
                  setMaskPhone("(99) 9999-99999")
                } else {
                  setMaskPhone("(99) 9 9999-9999")
                }
              },
            })}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Razão social"
            error={errors.legal_name}
            {...register("legal_name")}
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            as={InputMask}
            label="CNPJ"
            mask={"99.999.999/9999-99"}
            maskChar={''}
            defaultValue={companyData?.document}
            error={errors.document}
            {...register("document")}
          />
          <Input
            label="Inscrição estadual"
            error={errors.state_registration}
            {...register("state_registration")}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Endereço completo"
            error={errors.address?.address}
            {...register("address.address")}
          />
          <Input
            label="Bairro"
            error={errors.address?.district}
            {...register("address.district")}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Cidade"
            error={errors.address?.city}
            {...register("address.city")}
          />
          <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
            <Input
              as={InputMask}
              label="UF"
              defaultValue={companyData?.address?.uf}
              error={errors.address?.uf}
              maskChar={''}
              mask={"aa"}
              {...register("address.uf")}
            />
            <Input
              as={InputMask}
              label="CEP"
              maskChar={''}
              mask={"99999-999"}
              defaultValue={companyData?.address?.postal_code}
              error={errors.address?.postal_code}
              {...register("address.postal_code")}
            />
          </SimpleGrid>
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Banco"
            error={errors.bank_account?.bank}
            {...register("bank_account.bank")}
          />
          <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
            <Input
              label="Agência"
              error={errors.bank_account?.banch}
              {...register("bank_account.banch")}
            />
            <Input
              label="Conta"
              error={errors.bank_account?.account}
              {...register("bank_account.account")}
            />
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
          <Input
            label="Chave PIX"
            error={errors.bank_account?.pix_key}
            {...register("bank_account.pix_key")}
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
          <Input
            label="Beneficiário Nome"
            error={errors.bank_account?.beneficiary_name}
            {...register("bank_account.beneficiary_name")}
          />
          <Input
            as={InputMask}
            label="Beneficiário CPF ou CNPJ"
            mask={maskBeneficiaryDocument}
            maskChar={''}
            defaultValue={companyData?.bank_account?.beneficiary_document}
            error={errors.bank_account?.beneficiary_document}
            {...register("bank_account.beneficiary_document", {
              onChange: (e) => {
                const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                if (clearValue.length <= 11) {
                  setMaskBeneficiaryDocument("999.999.999-999")
                } else {
                  setMaskBeneficiaryDocument("99.999.999/9999-99")
                }
              },
            })}
          />
        </SimpleGrid>
      </Stack>

      <FormActions navigate={navigate} useFormReturn={useFormReturn} />
    </>
  )
}