import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { TransactionModel } from "../../models/TransactionModel";
import { ClearMoney, FormToJson, Toast } from "../../utils/utils";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { TransportsNavigator } from "../../components/Form/Navigator/TransportsNavigator";
import { Form } from "./components/Form";
import { updateTransaction } from "../../services/transactions/updateTransaction";
import { createTransaction } from "../../services/transactions/createTransaction";

const formShema = yup.object().shape({
  amount: yup.string().required("Obrigatório"),
  description: yup.string().required("Obrigatório"),
});

export function New() {
  const { parent_uuid, parent } = useParams();

  const navigate = useNavigate();

  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { handleSubmit, setError } = useFormReturn;

  const handleFormSubmit: SubmitHandler<FieldValues | TransactionModel> = async (values) => {
    const proof = values.proof_file[0] as File;
    const data = JSON.parse(FormToJson(values)) as TransactionModel
    data.contract_uuid = (parent == "contracts-general" ? parent_uuid : values.contract_uuid)
    data.transport_uuid = (parent == "transports-general" ? parent_uuid : values.transport_uuid)
    data.amount = parseFloat(ClearMoney(data.amount.toString()))

    const response = await createTransaction(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate(parent != undefined ? "/transactions/" + parent + "/" + parent_uuid : "/transactions-general");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      {parent == "drivers" ?
        <DriversNavigator step={6} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={2} uuid={parent_uuid} /> :
          parent == "partners" ?
            <PartnersNavigator step={5} uuid={parent_uuid} /> :
            parent == "transports-general" ?
              <TransportsNavigator step={4} uuid={parent_uuid} /> :
              <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar Transação Financeira"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
