import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraIput,
  InputProps as ChakraInputProps,
  useColorMode,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: any;
  mask?: string;
  maskChar?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, error = null, ...rest }, ref
) => {
  const { colorMode } = useColorMode();

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <ChakraIput
        autoComplete={"off"}
        name={name}
        id={name}
        textTransform={"uppercase"}
        focusBorderColor={"teal.500"}
        bg={colorMode === "dark" ? "gray.900" : "gray.50"}
        variant={"filled"}
        size={"lg"}
        ref={ref}
        {...rest}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
