import { Flex, useColorMode } from "@chakra-ui/react";
import Header from "../components/Header/Header";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../components/Sidebar/Sidebar";

export default function DashboardLayout() {
  const { colorMode } = useColorMode();

  return (
    <Flex overflow={"auto"} direction={"column"} h={"100vh"} bg={colorMode === "dark" ? "gray.900" : "gray.100"}>
      <Header />

      <Flex w={"100%"} my={"6"} maxWidth={1480} mx={"auto"} px={"6"}>
        <Sidebar />
        <Outlet />
      </Flex>
    </Flex>
  );
}
