import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { showReportDataToGenerateContract } from '../../services/reports/showReportDataToGenerateContract';
import { FormateMoneyFloat, FormateQuilo } from '../../utils/utils';
import { endOfDay, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export async function GenerateContract(contractUUID: string) {

    const response = await showReportDataToGenerateContract(contractUUID);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
        return;
    }

    const contractData = response.data.contract
    const productData = response.data.product
    const companyData = response.data.company
    const companyAddressData = response.data.company_address
    const partnerData = response.data.partner
    const partnerAddressData = response.data.partner_address
    const bankAccountData = response.data.bank_account

    if (contractData.kind !== "VENDA" && contractData.kind !== "COMPRA") {
        return
    }

    let documentData = {} as any

    documentData.headerCompanyName = companyData.legal_name
    documentData.headerFullAddress = `${companyAddressData.address}, ${companyAddressData.district}, ${companyAddressData.city}/${companyAddressData.uf} - ${companyAddressData.postal_code}`
    documentData.headerCompanyDocument = companyData.document
    documentData.headerCompanyaStateRegistration = companyData.state_registration
    documentData.companyCity = `${companyAddressData.city}/${companyAddressData.uf}`

    
    if (contractData.contract_date != undefined) {
        let dateWithOutTimeZone = contractData.contract_date.split('T')[0]
        let dateSplited = dateWithOutTimeZone.split('-')
        var date = endOfDay(new Date(dateSplited[0], dateSplited[1] - 1, dateSplited[2]))
        documentData.date = `${dateSplited[2]} DE ${format(date, 'MMMM', { locale: ptBR }).toUpperCase()} DE ${dateSplited[0]} `
    }else{
        var date = endOfDay(new Date())
    } 

    documentData.contractType = `${contractData.kind}`
    documentData.contractNumber = `${contractData.number}`

    documentData.product = `${productData.name}`
    documentData.productQuality = `${productData.quality}`

    if (contractData.kind === "COMPRA") {
        documentData.buyerName = companyData.legal_name
        documentData.buyerAddress = `${companyAddressData.address}, ${companyAddressData.district}, ${companyAddressData.city}/${companyAddressData.uf} - ${companyAddressData.postal_code}`
        documentData.buyerDocument = companyData.document
        documentData.buyerStateRegistration = companyData.state_registration ? companyData.state_registration : "-"

        documentData.sellerName = partnerData.legal_name
        documentData.sellerAddress = `${partnerAddressData.address}, ${partnerAddressData.district}, ${partnerAddressData.city}/${partnerAddressData.uf} - ${partnerAddressData.postal_code}`
        documentData.sellerDocument = partnerData.document
        documentData.sellerStateRegistration = partnerData.state_registration ? companyData.state_registration : "-"
    } else if (contractData.kind === "VENDA") {
        documentData.buyerName = partnerData.legal_name
        documentData.buyerAddress = `${partnerAddressData.address}, ${partnerAddressData.district}, ${partnerAddressData.city}/${partnerAddressData.uf} - ${partnerAddressData.postal_code}`
        documentData.buyerDocument = partnerData.document
        documentData.buyerStateRegistration = partnerData.state_registration ? companyData.state_registration : "-"

        documentData.sellerName = companyData.legal_name
        documentData.sellerAddress = `${companyAddressData.address}, ${companyAddressData.district}, ${companyAddressData.city}/${companyAddressData.uf} - ${companyAddressData.postal_code}`
        documentData.sellerDocument = companyData.document
        documentData.sellerStateRegistration = companyData.state_registration ? companyData.state_registration : "-"
    }

    documentData.paymentBeneficiaryName = bankAccountData.beneficiary_name ? bankAccountData.beneficiary_name : "-"
    documentData.paymentBeneficiaryDocument = bankAccountData.beneficiary_document ? bankAccountData.beneficiary_document : "-"
    documentData.paymentBank = bankAccountData.bank ? bankAccountData.bank : "-"
    documentData.paymentBranch = bankAccountData.banch ? bankAccountData.banch : "-"
    documentData.paymentAccount = bankAccountData.account ? bankAccountData.account : "-"
    documentData.paymentPix = bankAccountData.pix_key ? bankAccountData.pix_key : "-"

    documentData.quantity = "-"
    if (contractData.quantity_max > 0 && contractData.quantity_min > 0) {
        const min = FormateQuilo(contractData?.quantity_min?.toString() ?? "")
        const max = FormateQuilo(contractData?.quantity_max?.toString() ?? "")
        documentData.quantity = `MIN. ${min} KG | MAX. ${max} KG`
    } else if (contractData.quantity_max > 0) {
        const max = FormateQuilo(contractData?.quantity_max?.toString() ?? "")
        documentData.quantity = `MAX. ${max} KG`
    } else if (contractData.quantity_min > 0) {
        const min = FormateQuilo(contractData?.quantity_min?.toString() ?? "")
        documentData.quantity = `MIN. ${min} KG`
    }

    documentData.amount = "-"
    if (contractData?.amount > 0) {
        const amount = FormateMoneyFloat(contractData?.amount?.toString() ?? "")
        documentData.amount = `R$ ${amount} POR SACA DE 60KG`
    }

    documentData.totalAmount = "-"
    if (contractData?.amount > 0) {
        if (contractData.quantity_max > 0 && contractData.quantity_min > 0) {
            const min = FormateMoneyFloat((contractData.amount * (contractData.quantity_min / 60)).toFixed(2).toString())
            const max = FormateMoneyFloat((contractData.amount * (contractData.quantity_max / 60)).toFixed(2).toString())
            documentData.totalAmount = `MIN. R$ ${min} | MAX. R$ ${max}`
        } else if (contractData.quantity_max > 0) {
            const max = FormateMoneyFloat((contractData.amount * (contractData.quantity_max / 60)).toFixed(2).toString())
            documentData.totalAmount = `MAX. R$ ${max}`
        } else if (contractData.quantity_min > 0) {
            const min = FormateMoneyFloat((contractData.amount * (contractData.quantity_min/ 60)).toFixed(2).toString())
            documentData.totalAmount = `MIN. R$ ${min}`
        }
    }

    documentData.paymentForm = contractData.payment_form ? contractData.payment_form : "-"
    documentData.paymentTerm = contractData.payment_term ? contractData.payment_term : "-"
    documentData.observation = contractData.observation ? contractData.observation : "-"
    documentData.delivery = contractData.delivery ? contractData.delivery : "-"
    documentData.pickupLocation = contractData.pickup_location ? contractData.pickup_location : "-"

    var contract = {
        content: [
            {
                stack: [
                    documentData.headerCompanyName,
                    {
                        text: documentData.headerFullAddress,
                        fontSize: 10,
                        decoration: 'underline'
                    },
                ],
                style: 'header',
                alignment: 'center'
            },
            {
                columns: [
                    {
                        width: '*',
                        text: `CNPJ: ${documentData.headerCompanyDocument}`,
                    },
                    {
                        width: 'auto',
                        text: `INSC. EST.: ${documentData.headerCompanyaStateRegistration}`,
                        alignment: 'rigth',
                    },
                ],
                columnGap: 10,
                fontSize: 8.8,
                margin: [0, 5],

            },
            {
                table: {
                    widths: '*',
                    body: [[{ text: `CONTRATO ${documentData.contractType} - Nº ${documentData.contractNumber}`, bold: true, alignment: 'center', fontSize: 14 }]]
                }
            },
            {
                text: [
                    { text: `COMPRADOR: ${documentData.buyerName} \n`, bold: true, fontSize: 11 },
                    `ENDEREÇO: ${documentData.buyerAddress} \n`,
                    `CNPJ: ${documentData.buyerDocument}, INSC. EST.: ${documentData.sellerStateRegistration}\n`,
                ],
                style: 'content'
            },
            {
                text: [
                    { text: `VENDEDOR: ${documentData.sellerName} \n`, bold: true, fontSize: 11 },
                    `ENDEREÇO: ${documentData.sellerAddress} \n`,
                    `CNPJ: ${documentData.sellerDocument}, INSC. EST.: ${documentData.sellerStateRegistration} \n`,
                ],
                style: 'content'
            },
            {
                text: [
                    { text: `PRODUTO: ${documentData.product} `, bold: true, },
                    'EM GRÃOS. \n',
                    { text: 'QUALIDADE:', bold: true, },
                    ` ${documentData.productQuality} \n`,
                    { text: '(AUSÊNCIA DE INSETOS VIVOS, SEMENTES TRATADAS E OU VENENOSAS, CAPIM CARRAPICHO, MAMONAS)', decoration: 'underline', bold: true },
                ],
                style: 'content'
            },
            {
                table: {
                    widths: '*',
                    body: [
                        [
                            { text: 'QUANTIDADE', bold: true, },
                            `${documentData.quantity}`
                        ],
                        [
                            { text: 'VALOR POR SACA', bold: true, },
                            `${documentData.amount}`
                        ],
                        [
                            { text: 'VALOR TOTAL', bold: true, },
                            `${documentData.totalAmount}`
                        ],
                        [
                            { text: 'PRAZO', bold: true, },
                            `${documentData.paymentTerm}`
                        ],
                        [
                            { text: 'OBSERVAÇÃO', bold: true, },
                            `${documentData.observation}`
                        ]
                    ]
                },
                style: 'table'
            },
            {
                table: {
                    widths: '*',
                    body: [
                        [
                            { text: 'FORMA DE PAGAMENTO', bold: true, },
                            `${documentData.paymentForm}`
                        ],
                        [
                            { text: 'BENEFICIARIO', bold: true, },
                            `${documentData.paymentBeneficiaryName}`
                        ],
                        [
                            { text: 'CPF/CNPJ', bold: true, },
                            `${documentData.paymentBeneficiaryDocument}`
                        ],
                        [
                            { text: 'BANCO', bold: true, },
                            `${documentData.paymentBank}`
                        ],
                        [
                            { text: 'AGENCIA', bold: true, },
                            `${documentData.paymentBranch}`
                        ],
                        [
                            { text: 'CONTA', bold: true, },
                            `${documentData.paymentAccount}`
                        ],
                        [
                            { text: 'CHAVE PIX', bold: true, },
                            `${documentData.paymentPix}`
                        ]
                    ]
                },
                style: 'table'
            },
            {
                text: [
                    { text: 'DATA DE ENTREGA: ', bold: true, },
                    `${documentData.delivery}.\n`,
                    { text: 'LOCAL (ENTREGA/RETIRADA):', bold: true, },
                    `${documentData.pickupLocation} \n`,
                ],
                style: 'content'
            },
            {
                text: [
                    { text: 'PESO/QUALIDADE: ', bold: true, },
                    `CONFERIDO ${contractData.kind === "VENDA" ? "NO DESTINO" : "NA ORIGEM"}, `,
                    `SOBRE RODAS ${contractData.kind === "VENDA" ? "NA DESCARGA" : "NO EMBARQUE"} DO PRODUTO. `,
                    'CASO IDENTIFICADO PRODUTO FORA DO PADRÃO OU INSETOS VIVOS, O',
                    ` ${contractData.kind === "VENDA" ? " DESEMBARQUE" : " EMBARQUE"} SERÁ INTERRIMPIDO E FICARÁ A CARGO DO`,
                    { text: '(VENDEDOR) ', bold: true, decoration: 'underline' },
                    ' SANAR AS DIVERGÊNCIAS.',
                ],
                style: 'content'
            },
            {
                text: [
                    'AS PARTES ACIMA IDENTIFICADAS TÊM, ENTRE SI, JUSTO E ACERTADO O PRESENTE CONTRATO DE COMPRA E VIDA, QUE SE REGERÁ A PARTIR DAS CONDIÇÕES DE PREÇO,',
                    'FORMA DE PAGAMENTO DESCRITAS NO PRESENTE, BEM COMO NOS TERMOS SEGUINTES:'
                ],
                italics: true,
                bold: true,
                fontSize: 8,
                margin: [0, 5, 0, 0],
            },
            {
                text: [
                    'OUTRAS CONDIÇÕES:',
                ],
                bold: true,
                style: 'content'
            },
            {
                ol: [
                    `TODAS AS CONTROVÉRSIAS ORIUNDAS DO PRESENTE CONTRATO SERÃO RESOLVIDAS PERANTE O FÓRUM DA COMARCA DE ${documentData.companyCity}`,
                    {
                        text: [
                            'PARA QUE SURTAM TODOS OS EFEITOS LEGAIS DESTE CONTRATO, O PRODUTO ORA NEGOCIADO DEVERA ESTAR LIVRE E DESEMBARÇADO',
                            'DE QUALQUER ONUS, PENHORA OU FINANCIAMENTO COM QUALQUER INSTITUIÇÃO PUBLICA OU PRIVADA SEJA ESTA FINANCEIRA OU NÃO.',
                        ]
                    },
                    {
                        text: [
                            'ESTE CONTRATO CONSTITUI O ACORDO INTEGRAL ENTRE AS PARTES, SUBSTITUINDO QUAISQUER ENTENDIMENTOS ENTRE ',
                            'ELAS, SEJAM ORAIS OU ESCRITOS, ANTERIOMENTE OU POSTERIOR A ELES.',
                        ]
                    },
                    {
                        text: [
                            'AS PARTES CONTRATENTES, DESDE JÁ, ACEITAM TODAS AS CONDIÇÕES ESTIPULADAS PELO PRESENTE, FICANDO ',
                            'OBRIGADO O ', { text: 'VENDEDOR', bold: true, }, ' A FATURAR A MERCADORIA AO PRÓPRIO ',
                            { text: 'COMPRADOR', bold: true, }, ' OU A QUEM FOR POR ELE INDICADO.'

                        ]
                    },
                ],
                margin: [15, 5, 0, 0],
                fontSize: 8,
            },
            {
                text: [
                    `${documentData.companyCity}, ${documentData.date}`,
                ],
                style: 'content'
            },
            {
                columns: [
                    {
                        width: '*',
                        text: '_______________________________________________',
                        alignment: 'left',
                    },
                    {
                        width: '*',
                        text: '_______________________________________________',
                        alignment: 'rigth',
                    },
                ],
                margin: [0, 10, 0, 0],
            },
            {
                columns: [
                    {
                        width: '*',
                        text: documentData.buyerName,
                        alignment: 'center',
                        margin: [0, 0, 15, 0],
                    },
                    {
                        width: '*',
                        text: documentData.sellerName,
                        alignment: 'center',
                        margin: [15, 0, 0, 0],
                    },
                ],
                fontSize: 8,
                margin: [0, 5, 0, 0],
            },
            {
                columns: [
                    {
                        width: '*',
                        text: 'COMPRADOR',
                        alignment: 'center',
                        margin: [0, 0, 15, 0],
                    },
                    {
                        width: '*',
                        text: 'VENDEDOR',
                        alignment: 'center',
                        margin: [15, 0, 0, 0],
                    },
                ],
                fontSize: 8,
                margin: [0, 0, 0, 0],
            },
            {
                text: [
                    `TESTEMUNHAS: `,
                ],
                fontSize: 8,
                style: 'content',
                margin: [0, 10, 0, 0],
            },
            {
                columns: [
                    {
                        width: '*',
                        text: '.................................................................................',
                        alignment: 'left',
                    },
                    {
                        width: '*',
                        text: '.................................................................................',
                        alignment: 'rigth',
                    },
                ],
                margin: [0, 10, 0, 0],
            },
        ],
        footer: {
            columns: [
                {
                    text: 'FAVOR ASSINAR E DEVOLVER O PRESENTE INSTRUMENTO SÓ SERÁ VÁLIDO APÓS ACORDADO E ASSINADO.',
                    alignment: 'center',
                    fontSize: 8,
                    bold: true
                }
            ]
        },
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                alignment: 'justify'
            },
            content: {
                margin: [0, 10, 0, 0],
                fontSize: 9.5,
            },
            table: {
                margin: [0, 15, 0, 0],
                fontSize: 9.5,
            }
        },
        pageMargins: [30, 20, 30, 20],
    } as TDocumentDefinitions
    pdfMake.createPdf(contract, undefined, undefined, pdfFonts.pdfMake.vfs).open();
}