import { Button, Flex, Icon, SimpleGrid, Stack } from "@chakra-ui/react";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputFile } from "../../components/Form/InputFile";
import { useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import InputMask from "react-input-mask";
import { createUser } from "../../services/users/createUser";
import { UserModel } from "../../models/UserModel";
import { Toast } from "../../utils/utils";

const formShema = yup.object().shape({
  name: yup.string().required("Obrigatório"),
  cpf: yup.string().required("Obrigatório"),
  email: yup.string().email("Formato inválido").required("Obrigatório"),
  password: yup.string().required("Obrigatório"),
});

export function New() {
  const navigate = useNavigate();
  const [imageSelected, setImageSelected] = useState("");
  const { register, handleSubmit, setError, formState } = useForm({
    resolver: yupResolver(formShema),
  });
  const { errors } = formState;

  const handleFormSubmit: SubmitHandler<FieldValues | UserModel> = async (values) => {
    const file = values.photo_file[0] as File;
    const userData = {
      name: values.name.toUpperCase(),
      email: values.email.toUpperCase(),
      cpf: values.cpf.toUpperCase(),
      password: values.password.toUpperCase(),
    } as UserModel;
    const response = await createUser(userData, file);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/users");
    }
  };

  return (
    <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
      <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
        <ContainerHeader title={"Cadastrar Usuário"} />
        <Stack direction={"column"} spacing={"4"}>
          <Input type="file" display={"none"}
            {...register("photo_file", {
              onChange: (e) => {
                if (e.target.files[0] !== undefined) {
                  setImageSelected(e.target.files[0].name);
                } else {
                  setImageSelected("");
                }
              },
            })}
          />
          <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
            <Input
              label="Nome completo"
              error={errors.name}
              {...register("name")}
            />
            <Input
              as={InputMask}
              label="CPF"
              error={errors.cpf}
              mask={"999.999.999-99"}
              {...register("cpf")}
            />
          </SimpleGrid>
          <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
            <InputFile
              label="Foto"
              placeholder={"Selecione uma imagem"}
              error={errors.photo}
              value={imageSelected}
              onClick={() => {
                document.getElementById("photo_file")?.click();
              }}
              {...register("photo")}
            />
          </SimpleGrid>
          <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
            <Input
              label="Email"
              type="email"
              error={errors.email}
              {...register("email")}
            />
            <Input
              label="Senha"
              type="password"
              error={errors.password}
              {...register("password")}
            />
          </SimpleGrid>
        </Stack>

        <Flex mt={"8"} justify={"flex-end"}>
          <Stack direction={"row"} spacing={"4"}>
            <Button
              onClick={() => { navigate(-1) }}
              colorScheme={"orange"}
              size={"lg"}
              leftIcon={<Icon as={RiCloseCircleLine} fontSize={"20"} />}
            >
              Cancelar
            </Button>
            <Button
              type={"submit"}
              colorScheme={"teal"}
              size={"lg"}
              leftIcon={<Icon as={RiCheckboxCircleLine} fontSize={"20"} />}
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </Stack>
        </Flex>
      </DefaultBox>
    </Flex>
  );
}
