import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { ProductModel } from "../../models/ProductModel";
import { FormToJson, Toast } from "../../utils/utils";
import { Form } from "../products/components/Form";
import { createProduct } from "../../services/products/createProduct";

const formShema = yup.object().shape({
  name: yup.string().required("Obrigatório"),
});

export function New() {
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | ProductModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as ProductModel

    const response = await createProduct(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/products");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar produto"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}