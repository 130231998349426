import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { TransportContractModel } from "../../../models/TransportContractModel";
import { FormActions } from "../../../components/Form/FormActions";
import { FormateQuilo, HandleMaskQuilo } from "../../../utils/utils";

interface FormProps {
  navigate: NavigateFunction
  useFormReturn: UseFormReturn<FieldValues, any>
  transportContractData?: TransportContractModel
}

export function Form({ navigate, useFormReturn, transportContractData }: FormProps) {
  const { register, formState, setValue } = useFormReturn
  const { errors } = formState;

  useEffect(() => {
    if (transportContractData) {
      setValue("total_weight", FormateQuilo(transportContractData?.total_weight.toString()));
    }
  }, [transportContractData])

  return (
    <>
      <Stack direction={"column"} spacing={"4"}>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Quantidade (Sacas)"
            error={errors.total_weight}
            onKeyUp={HandleMaskQuilo}
            {...register("total_weight")}
          />
        </SimpleGrid>
      </Stack>
      <FormActions navigate={navigate} useFormReturn={useFormReturn} />
    </>
  )
}