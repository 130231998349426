import { Table, Box, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateDate, Toast } from "../../../utils/utils";
import { formatDistanceToNow } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { AddressModel } from "../../../models/AddressModel";
import { deleteAddress } from "../../../services/addresses/deleteAddress";

interface ListTableProps {
  addresses?: AddressModel[];
  parent: string;
  parent_uuid: string;
  refetch?: () => void;
}

export function ListTable({ addresses, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteAddress(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"}>Endereço</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {addresses?.map((address) => {
            return (
              <Tr key={address.uuid}>
                <Td pl={"0"}>
                  <Box>
                    <Text fontWeight="bold">{address.address}</Text>
                    <Text fontSize="sm" color="gray.300">
                      {address.district}, {address.city} - {address.uf}
                    </Text>
                  </Box>
                </Td>
                <Td title={formatDistanceToNow(new Date(address.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(address.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {address.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : address.is_primary ? (
                    <IconButton
                      as={Link}
                      to={"/addresses/" + parent + "/" + parent_uuid + "/edit/" + address.uuid}
                      size={"xs"}
                      fontSize={"xs"}
                      colorScheme={"orange"}
                      icon={<Icon as={RiPencilLine} />}
                      aria-label={"Editar"}
                    />
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={"/addresses/" + parent + "/" + parent_uuid + "/edit/" + address.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(address.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
