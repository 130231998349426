import { Stack, Text } from "@chakra-ui/react";
import { NavLink } from "./NavLink";
import { NavSection } from "./NavSection";
import {
  RiAccountBoxLine,
  RiCalendarCheckLine,
  RiContactsLine,
  RiDashboardLine,
  RiFolderOpenLine,
  RiHomeGearLine,
  RiLeafLine,
  RiMoneyDollarCircleLine,
  RiParentLine,
  RiPlantLine,
  RiToolsLine,
  RiTruckLine,
} from "react-icons/ri";

export function SidebarNav() {
  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="GERAL">
        <NavLink icon={RiDashboardLine} to={"/dashboard"} shouldMatchExactHref={true}>
          Dashboard
        </NavLink>
        <NavLink icon={RiHomeGearLine} to={"/companies"}>
          Empresas
        </NavLink>
        <NavLink icon={RiContactsLine} to={"/users"}>
          Usuários
        </NavLink>        
      </NavSection>
      <NavSection title="GERENCIAMENTO">
      <NavLink icon={RiLeafLine} to={"/storages-general"}>
          Armazenamento
        </NavLink>
        <NavLink icon={RiTruckLine} to={"/transports-general"}>
          Carregamentos
        </NavLink>
        <NavLink icon={RiFolderOpenLine} to={"/contracts-general"}>
          Contratos
        </NavLink>
        <NavLink icon={RiCalendarCheckLine} to={"/vehicles/fleet"}>
          Frota
        </NavLink>        
        <NavLink icon={RiAccountBoxLine} to={"/drivers"}>
          Motoristas
        </NavLink>
        <NavLink icon={RiParentLine} to={"/partners"}>
          Parceiros
        </NavLink>
        <NavLink icon={RiPlantLine} to={"/products"}>
          Produtos
        </NavLink>        
      </NavSection>
      <NavSection title="RELATÓRIOS">
        <NavLink icon={RiMoneyDollarCircleLine} to={"/transactions-general"}>
          Financeiro
        </NavLink>
        <NavLink icon={RiToolsLine} to={"/maintenances-general"}>
          Manutenções
        </NavLink>
        <Text size={"sm"} color={"gray.300"} mt={"4"}>0.0.15</Text>
      </NavSection>
    </Stack>
  );
}
