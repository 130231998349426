import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { ProductModel } from "../../../models/ProductModel";
import { FormActions } from "../../../components/Form/FormActions";

interface FormProps {
  navigate: NavigateFunction
  useFormReturn: UseFormReturn<FieldValues, any>
  productData?: ProductModel
}

export function Form({ navigate, useFormReturn, productData }: FormProps) {
  const { register, formState, setValue } = useFormReturn
  const { errors } = formState;

  useEffect(() => {
    if (productData) {
      setValue("name", productData?.name);
      setValue("quality", productData?.quality);
    }
  }, [productData])

  return (
    <>
      <Stack direction={"column"} spacing={"4"}>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Nome do produto"
            error={errors.name}
            {...register("name")}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>

          <Input
            label="Qualidade para classificação"
            error={errors.quality}
            {...register("quality")}
          />
        </SimpleGrid>
      </Stack>

      <FormActions navigate={navigate} useFormReturn={useFormReturn} />
    </>
  )
}