import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { MaintenanceModel } from "../../../models/MaintenanceModel";
import { FormActions } from "../../../components/Form/FormActions";
import { InputFile } from "../../../components/Form/InputFile";
import { FormateMoneyFloat, HandleMaskMoney } from "../../../utils/utils";
import { OptionsProps, Select2 } from "../../../components/Form/Select";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    maintenanceData?: MaintenanceModel
}

export function Form({ navigate, useFormReturn, maintenanceData }: FormProps) {
    const [proofSelected, setProofSelected] = useState("");

    const { register, formState, setValue } = useFormReturn
    const { errors } = formState;

    useEffect(() => {
        if (maintenanceData) {
            setValue("description", maintenanceData?.description);
            setValue("amount", FormateMoneyFloat(maintenanceData?.amount.toString()));
            setValue("description", maintenanceData?.description);
            setValue("kind", maintenanceData?.kind);
        }
    }, [maintenanceData])

    const OptionsKind: OptionsProps[] = [
        { label: "ABASTECIMENTO", value: "ABASTECIMENTO" },
        { label: "MECANICA", value: "MECANICA" },
        { label: "BORRACHARIA", value: "BORRACHARIA" },
        { label: "PNEUS", value: "PNEUS" },
        { label: "ELETRICA", value: "ELETRICA" },
        { label: "OUTROS", value: "OUTROS" },
    ]

    return (
        <>
            <Input type="file" display={"none"}
                {...register("proof_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setProofSelected(e.target.files[0].name);
                        } else {
                            setProofSelected("");
                        }
                    },
                })}
            />

            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Select2
                        label="Tipo de Manutenção"
                        options={OptionsKind}
                        error={errors.kind}
                        name={"kind"}
                        useFormReturn={useFormReturn}
                        selectedValue={maintenanceData?.kind} />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Valor"
                        error={errors.amount}
                        onKeyUp={HandleMaskMoney}
                        {...register("amount")} />

                    <InputFile
                        label="Arquivo Comprovante (Imagem ou PDF)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.proof}
                        value={proofSelected}
                        imageURL={maintenanceData?.proof}
                        onClick={() => {
                            document.getElementById("proof_file")?.click();
                        }}
                        {...register("proof")} />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Descrição"
                        error={errors.description}
                        {...register("description")} />
                </SimpleGrid>
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}