import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction, useParams } from "react-router-dom";
import { TransactionDataToCreateModel, TransactionModel } from "../../../models/TransactionModel";
import { FormActions } from "../../../components/Form/FormActions";
import { FormateMoneyFloat, HandleMaskMoney } from "../../../utils/utils";
import { showTransactionDataToCreate, ShowTransactionDataToCreateProps } from "../../../services/transactions/showTransactionDataToCreate";
import { OptionsProps, Select2 } from "../../../components/Form/Select";
import { InputFile } from "../../../components/Form/InputFile";

interface FormProps {
  navigate: NavigateFunction
  useFormReturn: UseFormReturn<FieldValues, any>
  transactionData?: TransactionModel
}

export function Form({ navigate, useFormReturn, transactionData }: FormProps) {
  const { parent_uuid, parent } = useParams();

  const { reset, register, formState, setValue } = useFormReturn
  const { errors } = formState;

  const [transactionDataToCreate, setTransactionDataToCreate] = useState<TransactionDataToCreateModel>();
  const getTransactionDataToCreate = async ({ driverUUID, partnerUUID }: ShowTransactionDataToCreateProps) => {
    const dataToCreate = await showTransactionDataToCreate({ driverUUID, partnerUUID });
    setTransactionDataToCreate(dataToCreate)
  }

  const [OptionsContracts, setOptionsContracts] = useState<OptionsProps[]>([{ label: "Nenhum registro localizado", value: "" }]);
  const [OptionsTransports, setOptionsTransports] = useState<OptionsProps[]>([{ label: "Nenhum registro localizado", value: "" }]);

  useEffect(() => {
    if (parent == "drivers") {
      getTransactionDataToCreate({ driverUUID: parent_uuid })
    } else if (parent == "partners") {
      getTransactionDataToCreate({ partnerUUID: parent_uuid })
    }
  }, [])

  useEffect(() => {
    if (transactionDataToCreate?.contracts != undefined) {
      let contractsData: OptionsProps[] = []
      if (transactionDataToCreate?.contracts != undefined) {
        for (let index = 0; index < transactionDataToCreate?.contracts.length; index++) {
          const element = transactionDataToCreate?.contracts[index];
          contractsData.push({ label: element.name.toUpperCase(), value: element.uuid })
        }
      }
      setOptionsContracts(contractsData);
      reset(OptionsContracts);
    } else if (transactionDataToCreate?.transports != undefined) {
      let transportsData: OptionsProps[] = []
      if (transactionDataToCreate?.transports != undefined) {
        for (let index = 0; index < transactionDataToCreate?.transports.length; index++) {
          const element = transactionDataToCreate?.transports[index];
          transportsData.push({ label: element.name.toUpperCase(), value: element.uuid })
        }
      }
      setOptionsTransports(transportsData);
      reset(OptionsTransports);
    }
  }, [transactionDataToCreate, transactionData])

  useEffect(() => {
    if (transactionData) {
      setValue("contract_uuid", transactionData?.contract_uuid);
      setValue("transport_uuid", transactionData?.transport_uuid);
      setValue("amount", FormateMoneyFloat(transactionData?.amount.toString()));
      setValue("kind", transactionData?.kind);
      setValue("description", transactionData?.description);
    }
  }, [OptionsTransports, OptionsContracts, transactionData])

  const OptionsKind: OptionsProps[] = [
    {
      label: "Recebimento",
      value: "RECEBIMENTO",
    },
    {
      label: "Pagamento",
      value: "PAGAMENTO",
    },
  ]

  const [proofSelected, setProofSelected] = useState("");
  return (
    <>
      <Input type="file" display={"none"}
        {...register("proof_file", {
          onChange: (e) => {
            if (e.target.files[0] !== undefined) {
              setProofSelected(e.target.files[0].name);
            } else {
              setProofSelected("");
            }
          },
        })}
      />

      <Stack direction={"column"} spacing={"4"}>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          {parent == "partners" ?
            <Select2
              label="Contrato"
              options={OptionsContracts}
              error={errors.contract_uuid}
              useFormReturn={useFormReturn}
              name={"contract_uuid"}
              selectedValue={transactionData?.contract_uuid}
            /> : <></>}

          {parent == "drivers" ?
            <Select2
              label="Transport"
              options={OptionsTransports}
              error={errors.transport_uuid}
              useFormReturn={useFormReturn}
              selectedValue={transactionData?.transport_uuid}
              name={"transport_uuid"}
            /> : <></>}

          <Select2
            label="Tipo de operação"
            options={OptionsKind}
            error={errors.kind}
            useFormReturn={useFormReturn}
            selectedValue={transactionData?.kind}
            name={"kind"}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            label="Valor Total"
            error={errors.amount}
            onKeyUp={HandleMaskMoney}
            {...register("amount")}
          />

          <Input
            label="Descrição"
            error={errors.description}
            {...register("description")}
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <InputFile
            label="Comprovante de transferência (Imagem ou PDF)"
            placeholder={"Selecione uma imagem"}
            error={errors.proof}
            imageURL={transactionData?.proof}
            value={proofSelected}
            onClick={() => {
              document.getElementById("proof_file")?.click();
            }}
            {...register("proof")}
          />
        </SimpleGrid>
      </Stack>

      <FormActions navigate={navigate} useFormReturn={useFormReturn} />
    </>
  )
}