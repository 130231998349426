import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { StorageModel } from "../../models/StorageModel";
import { ClearQuilo, FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { showStorage } from "../../services/storages/showStorage";
import { updateStorage } from "../../services/storages/updateStorage";
import { Form } from "../storages/components/Form";

const formShema = yup.object().shape({
  amount: yup.string().required("Obrigatório"),
  lost_amount: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { uuid, parent_uuid, parent } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [storageData, setStorageData] = useState<StorageModel>();
  const getStorageData = async () => {
    const response = await showStorage(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate(parent != undefined ? "/storages/" + parent + "/" + parent_uuid : "/storages-general");
      return;
    }
    if (response?.data) {
      setStorageData(response.data)
    }
  }
  useEffect(() => {
    getStorageData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | StorageModel> = async (values) => {
    const proof = values.proof_file[0] as File;
    const data = JSON.parse(FormToJson(values)) as StorageModel
    data.uuid = uuid!
    if (data.lost_amount != undefined)
      data.lost_amount = parseFloat(ClearQuilo(data.lost_amount.toString()))
    data.amount = parseFloat(ClearQuilo(data.amount.toString()))

    const response = await updateStorage(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate(parent != undefined ? "/storages/" + parent + "/" + parent_uuid : "/storages-general");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      {parent == "drivers" ?
        <DriversNavigator step={7} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={5} uuid={parent_uuid} /> :
          <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar armazenamento"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} storageData={storageData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}