import { ResponseApi } from "../../models/Api/ResponseApi";
import { ProductModel } from "../../models/ProductModel";
import { api } from "../api";

export async function updateProduct(productData: ProductModel): Promise<ResponseApi> {
    let response = {} as ResponseApi

    await api.patch('product/' + productData.uuid, productData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}