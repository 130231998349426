import { useState, useEffect } from "react";
import { ContractDataToCreateModel, ContractModel } from "../../../models/ContractModel";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box, SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { FormateDate, FormateMoneyFloat, FormateQuilo, HandleMaskMoney, HandleMaskQuilo } from "../../../utils/utils";
import { showContractDataToCreate } from "../../../services/contracts/showContractDataToCreate";
import { OptionsProps, Select2 } from "../../../components/Form/Select";
import { InputFile } from "../../../components/Form/InputFile";
import { NavigateFunction } from "react-router-dom";
import { ContractsCosts } from "./ContractsCosts";
import { indexBankAccount } from "../../../services/bankAccounts/indexBankAccount";
import { FormActions } from "../../../components/Form/FormActions";
import InputMask from "react-input-mask";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    contractData?: ContractModel
}

export function Form({ navigate, useFormReturn, contractData }: FormProps) {

    const { register, formState, reset, setValue } = useFormReturn
    const { errors } = formState;

    const [contractDataToCreate, setContractDataToCreate] = useState<ContractDataToCreateModel>();
    const getContractDataToCreate = async () => {
        const contractDataToCreate = await showContractDataToCreate();
        setContractDataToCreate(contractDataToCreate)
    }

    const [OptionsProducts, setOptionsProducts] = useState<OptionsProps[]>([]);
    const [OptionsCompanies, setOptionsCompanies] = useState<OptionsProps[]>([]);
    const [OptionsPartners, setOptionsPartners] = useState<OptionsProps[]>([]);

    useEffect(() => {
        if (contractDataToCreate?.products != undefined) {
            let productsData: OptionsProps[] = []
            let companiesData: OptionsProps[] = []
            let partnersData: OptionsProps[] = []

            for (let index = 0; index < contractDataToCreate?.products.length; index++) {
                const element = contractDataToCreate?.products[index];
                productsData.push({ label: element.name, value: element.uuid })
            }

            if (contractDataToCreate?.companies != undefined) {
                for (let index = 0; index < contractDataToCreate?.companies.length; index++) {
                    const element = contractDataToCreate?.companies[index];
                    companiesData.push({ label: element.name, value: element.uuid })
                }
            }

            if (contractDataToCreate?.partners != undefined) {
                for (let index = 0; index < contractDataToCreate?.partners.length; index++) {
                    const element = contractDataToCreate?.partners[index];
                    partnersData.push({ label: element.name, value: element.uuid })
                }
            }
            setOptionsProducts(productsData);
            setOptionsPartners(partnersData);
            setOptionsCompanies(companiesData);
            reset(OptionsProducts);
            reset(OptionsCompanies);
            reset(OptionsPartners);
        }
    }, [contractDataToCreate])

    useEffect(() => {
        getContractDataToCreate();
    }, [])

    const [proofSelected, setProofSelected] = useState("");
    const OptionsKind: OptionsProps[] = [
        { label: "COMPRA", value: "COMPRA" },
        { label: "VENDA", value: "VENDA" },
        { label: "TRANSPORTE", value: "TRANSPORTE" },
        { label: "ARMAZENAMENTO", value: "ARMAZENAMENTO" },
    ]

    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedPartner, setSelectedPartner] = useState("");
    const [selectedKind, setSelectedKind] = useState("");

    useEffect(() => {
        if (contractData != undefined) {
            if (contractData.company_uuid != "")
                setSelectedCompany(contractData.company_uuid)
            if (contractData.partner_uuid != "")
                setSelectedPartner(contractData.partner_uuid)
            if (contractData.kind != "")
                setSelectedKind(contractData.kind)
        }
        if (contractData?.contract_date != undefined) {
            contractData.contract_date = FormateDate(contractData.contract_date!, "DD/MM/YYYY")
        }
    }, [contractData])

    const [OptionsBankAccounts, setOptionsBankAccounts] = useState<OptionsProps[]>([{ label: "Aguardando informações", value: "" }]);
    const getBankAccounts = async (partnerUUID?: string, companyUUID?: string) => {
        const bankAccountResponse = await indexBankAccount({ partnerUUID: partnerUUID, companyUUID: companyUUID });
        let bankAccountsToPush: OptionsProps[] = []
        if (bankAccountResponse.bankAccounts.length > 0) {
            for (let index = 0; index < bankAccountResponse.bankAccounts.length; index++) {
                let label = bankAccountResponse.bankAccounts[index].bank
                label = label + (bankAccountResponse.bankAccounts[index].banch ? " AG:" + bankAccountResponse.bankAccounts[index].banch : "")
                label = label + (bankAccountResponse.bankAccounts[index].account ? " CC:" + bankAccountResponse.bankAccounts[index].account : "")
                label = label + (bankAccountResponse.bankAccounts[index].pix_key ? " PIX:" + bankAccountResponse.bankAccounts[index].pix_key : "")
                bankAccountsToPush.push({ label: label, value: bankAccountResponse.bankAccounts[index].uuid })
            }
        }

        if (bankAccountsToPush.length > 0) {
            setOptionsBankAccounts(bankAccountsToPush);
        } else {
            setOptionsBankAccounts([{ label: "Nenhuma conta encontrada", value: "" }])
        }
    }

    useEffect(() => {
        if (selectedKind == "COMPRA" && selectedPartner != "") {
            getBankAccounts(selectedPartner, undefined)
        } else if (selectedKind != "COMPRA" && selectedCompany != "") {
            getBankAccounts(undefined, selectedCompany)
        }        
    }, [selectedCompany, selectedPartner, selectedKind])

    useEffect(() => {
        if (contractData != undefined) {
            if (contractData?.contract_date != undefined) {
                setValue("contract_date", contractData?.contract_date);
            }
            setValue("amount", FormateMoneyFloat(contractData?.amount.toString() ?? ""));
            setValue("quantity_max", FormateQuilo(contractData?.quantity_max?.toString() ?? ""));
            setValue("quantity_min", FormateQuilo(contractData?.quantity_min?.toString() ?? ""));
            setValue("funrural", contractData?.funrural);
            setValue("delivery", contractData?.delivery);
            setValue("payment_term", contractData?.payment_term);
            setValue("payment_form", contractData?.payment_form);
            setValue("pickup_location", contractData?.pickup_location);
            setValue("number", contractData?.number);
            setValue("observation", contractData?.observation);
            setValue("bank_account_uuid", contractData?.bank_account_uuid);
            setValue("company_uuid", selectedCompany != "" ? selectedCompany : contractData?.company_uuid);
            setValue("product_uuid", contractData?.product_uuid);
            setValue("partner_uuid", selectedPartner != "" ? selectedPartner :  contractData?.partner_uuid);
            setValue("kind", selectedKind != "" ? selectedKind :  contractData?.kind)
        }
    }, [OptionsProducts, OptionsCompanies, OptionsPartners, OptionsBankAccounts, contractDataToCreate])

    return (
        <>

            <Input type="file" display={"none"}
                {...register("proof_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setProofSelected(e.target.files[0].name);
                        } else {
                            setProofSelected("");
                        }
                    },
                })}
            />
            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Nº Contrato"
                        error={errors.number}
                        {...register("number")}
                    />
                    <Select2
                        label="Tipo de Contrato"
                        options={OptionsKind}
                        error={errors.kind}
                        name={"kind"}
                        onChangeSelect={(value) => {
                            setSelectedKind(value);
                        }}
                        useFormReturn={useFormReturn}
                        selectedValue={selectedKind}
                    />
                    <Input
                        as={InputMask}
                        mask={"99/99/9999"}
                        maskChar={''}
                        label="Data do contrato"
                        defaultValue={contractData?.contract_date}
                        error={errors.contract_date}
                        {...register("contract_date")}
                    />                   
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Select2
                        label="Empresa"
                        options={OptionsCompanies}
                        error={errors.company_uuid}
                        name={"company_uuid"}
                        onChangeSelect={(value) => {
                            setSelectedCompany(value);
                        }}
                        useFormReturn={useFormReturn}
                        selectedValue={selectedCompany}
                    />
                    <Select2
                        label="Parceiro"
                        options={OptionsPartners}
                        error={errors.partner_uuid}
                        name={"partner_uuid"}
                        onChangeSelect={(value) => {
                            setSelectedPartner(value);
                        }}
                        useFormReturn={useFormReturn}
                        selectedValue={selectedPartner}
                    />

                    <Select2
                        label="Produto"
                        options={OptionsProducts}
                        error={errors.product_uuid}
                        name={"product_uuid"}
                        useFormReturn={useFormReturn}
                        selectedValue={contractData?.product_uuid}
                    />
                </SimpleGrid>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>

                    

                    <Input
                        label="Quantidade Kg."
                        error={errors.quantity_min}
                        onKeyUp={HandleMaskQuilo}
                        {...register("quantity_min")}
                    />

                    <Input
                        label="Qde. Max. Kg."
                        error={errors.quantity_max}
                        onKeyUp={HandleMaskQuilo}
                        {...register("quantity_max")}
                    />

                    <Input
                        label="Valor / Saca 60Kg."
                        error={errors.amount}
                        onKeyUp={HandleMaskMoney}
                        {...register("amount")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Forma de pagamento"
                        error={errors.payment_form}
                        {...register("payment_form")}
                    />

                    <Select2
                        label="Conta para pagamento"
                        options={OptionsBankAccounts}
                        error={errors.bank_account_uuid}
                        name={"bank_account_uuid"}
                        reloaded={true}
                        useFormReturn={useFormReturn}
                        selectedValue={contractData?.bank_account_uuid}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Data de entrega do produto"
                        error={errors.delivery}
                        {...register("delivery")}
                    />
                    <Input
                        label="Prazo para pagamento"
                        error={errors.payment_term}
                        {...register("payment_term")}
                    />

                    <Input
                        label="Impostos (Funrural, Fundeinfra, etc.)"
                        error={errors.funrural}
                        {...register("funrural")}
                    />
                </SimpleGrid>

                
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Local (retirada ou entrega)"
                        error={errors.pickup_location}
                        {...register("pickup_location")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>

                    <Input
                        label="Observação"
                        error={errors.observation}
                        {...register("observation")}
                    />
                </SimpleGrid>

                <ContractsCosts useFormReturn={useFormReturn} contractCosts={contractData?.costs} />

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <InputFile
                        label="Contrato Assinado (Imagem ou PDF)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.proof}
                        value={proofSelected}
                        imageURL={contractData?.proof}
                        onClick={() => {
                            document.getElementById("proof_file")?.click();
                        }}
                        {...register("proof")}
                    />
                </SimpleGrid>
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}