import { Route, Routes } from "react-router-dom";
import DashboardLayout from "./layouts/Dashboard";
import Dashboard from "./pages/Dashboard";
import Error404 from "./pages/Error404";
import SignIn from "./pages/SignIn";
import { Edit as EditUser } from "./pages/users/Edit";
import { List as ListUser } from "./pages/users/List";
import { New as NewUser } from "./pages/users/New";
import { Edit as EditProduct } from "./pages/products/Edit";
import { List as ListProduct } from "./pages/products/List";
import { New as NewProduct } from "./pages/products/New";
import { Edit as EditCompany } from "./pages/companies/Edit";
import { List as ListCompany } from "./pages/companies/List";
import { New as NewCompany } from "./pages/companies/New";
import { Edit as EditPartner } from "./pages/partners/Edit";
import { List as ListPartner } from "./pages/partners/List";
import { New as NewPartner } from "./pages/partners/New";
import { Edit as EditDriver } from "./pages/drivers/Edit";
import { List as ListDriver } from "./pages/drivers/List";
import { New as NewDriver } from "./pages/drivers/New";
import { Edit as EditAddress } from "./pages/addresses/Edit";
import { List as ListAddress } from "./pages/addresses/List";
import { New as NewAddress } from "./pages/addresses/New";
import { Edit as EditPhone } from "./pages/phones/Edit";
import { List as ListPhone } from "./pages/phones/List";
import { New as NewPhone } from "./pages/phones/New";
import { Edit as EditBankAccount } from "./pages/bankAccounts/Edit";
import { List as ListBankAccount } from "./pages/bankAccounts/List";
import { New as NewBankAccount } from "./pages/bankAccounts/New";
import { Edit as EditVehicle } from "./pages/vehicles/Edit";
import { List as ListVehicle } from "./pages/vehicles/List";
import { New as NewVehicle } from "./pages/vehicles/New";
import { Edit as EditMaintenance } from "./pages/maintenances/Edit";
import { List as ListMaintenance } from "./pages/maintenances/List";
import { New as NewMaintenance } from "./pages/maintenances/New";
import { Edit as EditContract } from "./pages/contracts/Edit";
import { List as ListContract } from "./pages/contracts/List";
import { New as NewContract } from "./pages/contracts/New";
import { Edit as EditTransport } from "./pages/transports/Edit";
import { List as ListTransport } from "./pages/transports/List";
import { New as NewTransport } from "./pages/transports/New";
import { Edit as EditStorage } from "./pages/storages/Edit";
import { List as ListStorage } from "./pages/storages/List";
import { New as NewStorage } from "./pages/storages/New";
import { Edit as EditFile } from "./pages/files/Edit";
import { List as ListFile } from "./pages/files/List";
import { New as NewFile } from "./pages/files/New";
import { Edit as EditTransaction } from "./pages/transactions/Edit";
import { List as ListTransaction } from "./pages/transactions/List";
import { New as NewTransaction } from "./pages/transactions/New";
import { Edit as EditTransportInvoice } from "./pages/transportInvoices/Edit";
import { List as ListTransportInvoice } from "./pages/transportInvoices/List";
import { New as NewTransportInvoice } from "./pages/transportInvoices/New";
import { Edit as EditTransportContract } from "./pages/transportContracts/Edit";
import { List as ListTransportContracs } from "./pages/transportContracts/List";

export default function Router() {
  return (
    <Routes>
      <Route path="login" element={<SignIn />} />
      <Route path="" element={<DashboardLayout />}>
        <Route path="" element={<SignIn />} />
        <Route path="dashboard" element={<Dashboard />} />

        <Route path="users" element={<ListUser />} />
        <Route path="users/new" element={<NewUser />} />
        <Route path="users/edit/:uuid" element={<EditUser />} />

        <Route path="products" element={<ListProduct />} />
        <Route path="products/new" element={<NewProduct />} />
        <Route path="products/edit/:uuid" element={<EditProduct />} />

        <Route path="companies" element={<ListCompany />} />
        <Route path="companies/new" element={<NewCompany />} />
        <Route path="companies/edit/:uuid" element={<EditCompany />} />

        <Route path="partners" element={<ListPartner />} />
        <Route path="partners/new" element={<NewPartner />} />
        <Route path="partners/edit/:uuid" element={<EditPartner />} />

        <Route path="drivers" element={<ListDriver />} />
        <Route path="drivers/new" element={<NewDriver />} />
        <Route path="drivers/edit/:uuid" element={<EditDriver />} />

        <Route path="addresses/:parent/:parent_uuid" element={<ListAddress />} />
        <Route path="addresses/:parent/:parent_uuid/new" element={<NewAddress />} />
        <Route path="addresses/:parent/:parent_uuid/edit/:uuid" element={<EditAddress />} />

        <Route path="phones/:parent/:parent_uuid" element={<ListPhone />} />
        <Route path="phones/:parent/:parent_uuid/new" element={<NewPhone />} />
        <Route path="phones/:parent/:parent_uuid/edit/:uuid" element={<EditPhone />} />

        <Route path="bank_accounts/:parent/:parent_uuid" element={<ListBankAccount />} />
        <Route path="bank_accounts/:parent/:parent_uuid/new" element={<NewBankAccount />} />
        <Route path="bank_accounts/:parent/:parent_uuid/edit/:uuid" element={<EditBankAccount />} />

        <Route path="vehicles/:parent" element={<ListVehicle />} />
        <Route path="vehicles/:parent/new" element={<NewVehicle />} />
        <Route path="vehicles/:parent/:parent_uuid/new" element={<NewVehicle />} />
        <Route path="vehicles/:parent/:parent_uuid/:vehicle_uuid/new" element={<NewVehicle />} />
        <Route path="vehicles/:parent/edit/:uuid" element={<EditVehicle />} />
        <Route path="vehicles/:parent/:parent_uuid" element={<ListVehicle />} />
        <Route path="vehicles/:parent/:parent_uuid/edit/:uuid" element={<EditVehicle />} />

        <Route path="maintenances-general" element={<ListMaintenance />} />
        <Route path="maintenances/:parent/:vehicle_uuid" element={<ListMaintenance />} />
        <Route path="maintenances/:parent/:vehicle_uuid/new" element={<NewMaintenance />} />
        <Route path="maintenances/:parent/:vehicle_uuid/edit/:uuid" element={<EditMaintenance />} />

        <Route path="contracts-general" element={<ListContract />} />
        <Route path="contracts-general/new" element={<NewContract />} />
        <Route path="contracts-general/edit/:uuid" element={<EditContract />} />
        <Route path="contracts/:parent/:parent_uuid" element={<ListContract />} />
        <Route path="contracts/:parent/:parent_uuid/new" element={<NewContract />} />
        <Route path="contracts/:parent/:parent_uuid/edit/:uuid" element={<EditContract />} />

        <Route path="transports-general" element={<ListTransport />} />
        <Route path="transports-general/new" element={<NewTransport />} />
        <Route path="transports-general/edit/:uuid" element={<EditTransport />} />
        <Route path="transports/:parent/:parent_uuid" element={<ListTransport />} />
        <Route path="transports/:parent/:parent_uuid/new" element={<NewTransport />} />
        <Route path="transports/:parent/:parent_uuid/edit/:uuid" element={<EditTransport />} />

        <Route path="transports-general/invoices/:transport_uuid" element={<ListTransportInvoice />} />
        <Route path="transports-general/invoices/:transport_uuid/new" element={<NewTransportInvoice />} />
        <Route path="transports-general/invoices/:transport_uuid/edit/:uuid" element={<EditTransportInvoice />} />

        <Route path="storages-general" element={<ListStorage />} />
        <Route path="storages-general/new" element={<NewStorage />} />
        <Route path="storages-general/edit/:uuid" element={<EditStorage />} />
        <Route path="storages/:parent/:parent_uuid" element={<ListStorage />} />
        <Route path="storages/:parent/:parent_uuid/new" element={<NewStorage />} />
        <Route path="storages/:parent/:parent_uuid/edit/:uuid" element={<EditStorage />} />

        <Route path="files/:parent/:parent_uuid" element={<ListFile />} />
        <Route path="files/:parent/:parent_uuid/new" element={<NewFile />} />
        <Route path="files/:parent/:parent_uuid/edit/:uuid" element={<EditFile />} />

        <Route path="transactions-general" element={<ListTransaction />} />
        <Route path="transactions-general/new" element={<NewTransaction />} />
        <Route path="transactions-general/edit/:uuid" element={<EditTransaction />} />
        <Route path="transactions/:parent/:parent_uuid" element={<ListTransaction />} />
        <Route path="transactions/:parent/:parent_uuid/new" element={<NewTransaction />} />
        <Route path="transactions/:parent/:parent_uuid/edit/:uuid" element={<EditTransaction />} />

        <Route path="transport_contracts/:parent/:parent_uuid" element={<ListTransportContracs />} />
        <Route path="transport_contracts/:parent/:parent_uuid/edit/:uuid" element={<EditTransportContract />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
