import axios, { AxiosError } from 'axios'
import { parseCookies } from 'nookies'
import { SetupCookies, SingOut } from '../contexts/AuthContext';
import { ResponseApi } from '../models/Api/ResponseApi';
import { updateSession } from './sessions/updateSession';

let isRefreshingToken = false
let failedRequestQueue = [] as any[]

const { "Multigrãos.token": token } = parseCookies();

export const FilePrefix = "https://backpixel-bucket.s3.sa-east-1.amazonaws.com/";
export const api = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:8080/v1/' : 'https://go.multigraos.com.br/v1/',
})
api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

api.interceptors.response.use(onSuccess => {
  return onSuccess
}, (onError: AxiosError) => {
  if (onError.response?.status === 401) {
    const responseData = onError.response?.data as ResponseApi
    if (responseData?.code === 'MTG0009') {
      const originalRequestConfig = onError.config
      const { "Multigrãos.refresh_token": refresh_token } = parseCookies();

      if (!isRefreshingToken && refresh_token) {
        isRefreshingToken = true

        updateSession(refresh_token).then((response) => {
          const { token, refresh_token } = response.data;
          SetupCookies(token, refresh_token);
          
          //Executando as requisições que falharam
          failedRequestQueue.forEach(request => request.onSucces(token))
          failedRequestQueue = []
        }).catch(err => {
          failedRequestQueue.forEach(request => request.onError(err))
          failedRequestQueue = []
          SingOut()
        }).finally(() => {
          isRefreshingToken = false
        })
      }

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSucces: (token: string) => {
            originalRequestConfig.headers!['Authorization'] = `Bearer ${token}`;
            resolve(api(originalRequestConfig))
          },
          onError: (err: AxiosError) => {
            reject(err)
          },
        })
      })
    } else {
      SingOut()
    }
  }
  return Promise.reject(onError)
})