import { Flex, Heading, Icon, SimpleGrid, Stack, useBreakpointValue } from "@chakra-ui/react";
import { RiSearchEyeLine } from "react-icons/ri";
import { AreaChart } from "../components/Chart/AreaChart";
import { ContainerHeader } from "../components/ContainerHeader/ContainerHeader";
import { SearchList } from "../components/ContainerSearch/ContainerSearch";
import { DefaultBox } from "../components/DefaultBox/DefaultBox";
import { Pagination } from "../components/Pagination/Pagination";
import { ConfirmDeleteContextProvider } from "../contexts/ConfirmDeleteContext";
import { useFilter } from "../contexts/FilterContext";
import { useIndexTransport } from "../services/hooks/useIndexTransport";
import { makeFilters } from "../services/transports/indexTransport";
import { ListTable } from "./transports/components/ListTable";

export default function Dashboard() {
  const dataChart = {
    name: "series1",
    data: [85, 68, 45, 50, 25, 69, 77],
    categories: [
      "2021-03-18T00:00:00.000Z",
      "2021-03-19T00:00:00.000Z",
      "2021-03-20T00:00:00.000Z",
      "2021-03-21T00:00:00.000Z",
      "2021-03-22T00:00:00.000Z",
      "2021-03-23T00:00:00.000Z",
      "2021-03-24T00:00:00.000Z",
    ],
  };

  const show = useBreakpointValue({
    base: true,
  });

  const { filterData } = useFilter();
  const { data, isFetching, refetch } = useIndexTransport({ paginator: makeFilters(filterData) });
  return (
    <Stack w={"100%"} spacing={"6"} overflow={"auto"}>
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} >
        <Stack flex={"1"} gap={4} alignSelf={"flex-start"} overflow={"auto"}>
          <SimpleGrid flex={"1"} gap={4} minChildWidth={"320px"} alignSelf={"flex-start"} hidden={!show} w={"100%"} overflow={"auto"}>
            <AreaChart title={"Recebimentos de grãos"} data={dataChart} />
            <AreaChart title={"Carregamentos realizados"} data={dataChart} />
          </SimpleGrid>

          <Stack w={"100%"} spacing={"0"} overflow={"auto"}>
            <SearchList />

            <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
              <DefaultBox overflow={"hidden"}>
                <ContainerHeader
                  title={"Carregamentos"}
                  createNew={{
                    to: "/transports-general/new",
                    label: "Cadastrar novo",
                  }}
                  isLoading={isFetching}
                  refetch={refetch}
                  search
                />

                {data?.transports.length || 0 > 0 ? (
                  <Stack overflow={"auto"}>
                    <ConfirmDeleteContextProvider>
                      <ListTable transports={data?.transports} refetch={refetch} />
                    </ConfirmDeleteContextProvider>
                  </Stack>
                ) : (
                  <Stack spacing={4} justify={"center"} align={"center"}>
                    <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
                    <Heading as="h3" size="sm" mt={6} mb={2}>
                      Ooops, nenhum registro localizado.
                    </Heading>
                  </Stack>
                )}

                <Pagination
                  limit={data?.paginator?.details.limit}
                  page={data?.paginator?.details.page}
                  results={data?.transports?.length}
                />
              </DefaultBox>
            </Flex>
          </Stack>

        </Stack>
      </Flex>
    </Stack >
  );
}