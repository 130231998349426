import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { createMaintenance } from "../../services/maintenances/createMaintenance";
import { MaintenanceModel } from "../../models/MaintenanceModel";
import { ClearMoney, FormToJson, Toast } from "../../utils/utils";
import { FleetsNavigator } from "../../components/Form/Navigator/FleetsNavigator";
import { Form } from "../maintenances/components/Form";

const formShema = yup.object().shape({
  amount: yup.string().required("Obrigatório"),
});

export function New() {
  const { parent, vehicle_uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | MaintenanceModel> = async (values) => {
    const proof = values.proof_file[0] as File;
    const data = JSON.parse(FormToJson(values)) as MaintenanceModel
    data.vehicle_uuid = vehicle_uuid
    data.amount = parseFloat(ClearMoney(data.amount.toString()))

    const response = await createMaintenance(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/maintenances/" + parent + "/" + vehicle_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "fleet" ?
        <FleetsNavigator step={2} uuid={vehicle_uuid} /> :
        <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar Manutenção"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
