import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { AddressModel } from "../../models/AddressModel";
import { FormToJson, Toast } from "../../utils/utils";
import { CompaniesNavigator } from "../../components/Form/Navigator/CompaniesNavigator";
import { showAddress } from "../../services/addresses/showAddress";
import { updateAddress } from "../../services/addresses/updateAddress";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { Form } from "../addresses/components/Form";

const formShema = yup.object().shape({
  address: yup.string().required("Obrigatório"),
  district: yup.string().required("Obrigatório"),
  city: yup.string().required("Obrigatório"),
  uf: yup.string().required("Obrigatório"),
  postal_code: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { uuid, parent, parent_uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [addressData, setAddressData] = useState<AddressModel>();
  const getAddressData = async () => {
    const response = await showAddress(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/addresses/" + parent + "/" + parent_uuid);
      return;
    }
    if (response?.data) {
      setAddressData(response.data)
    }
  }
  useEffect(() => {
    getAddressData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | AddressModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as AddressModel
    data.uuid = uuid!

    const response = await updateAddress(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/addresses/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "companies" ?
        <CompaniesNavigator step={2} uuid={parent_uuid} /> :
        parent == "partners" ?
          <PartnersNavigator step={2} uuid={parent_uuid} /> :
          <DriversNavigator step={2} uuid={parent_uuid} />
      }
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Endereço"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} addressData={addressData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
