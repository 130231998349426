import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react"
import React from "react"
import { useConfirmLogoff } from "../../contexts/ConfirmLogoffContext";

interface LogoffconfirmAlertProps {
  onLogoff: () => void;
}

export function ConfirmLogoffAlert({ onLogoff }: LogoffconfirmAlertProps) {
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>
  const { isOpen, onClose } = useConfirmLogoff()

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Deseja sair do sistema?
          </AlertDialogHeader>

          <AlertDialogBody>
            Sua sessão no sistema será encerrada.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme='green' onClick={onLogoff} ml={3}>
              Sair
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}