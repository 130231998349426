import { useState, useEffect } from "react";
import { CostModel } from "../../../models/ContractModel";
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Divider, Heading, Button, SimpleGrid, HStack, FormControl, FormLabel } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { ClearMoney, FormateMoney, FormateMoneyFloat } from "../../../utils/utils";

interface ContractCostsProps {
    useFormReturn: UseFormReturn<FieldValues, any>
    contractCosts?: CostModel[]
}

export function ContractsCosts({ useFormReturn, contractCosts }: ContractCostsProps) {

    const { formState, setValue, reset, getValues, register } = useFormReturn
    const { errors } = formState;

    const [costFields, setCostFields] = useState<CostModel[]>([])

    const handleChangeCost = <T extends keyof CostModel>(uuid: string, field: T, value: CostModel[T]) => {
        const newCostFields = costFields.map(cost => {
            if (cost.uuid === uuid) {
                cost[field] = value;
            }
            return cost;
        });
        setCostFields(newCostFields);
    };

    const handleAddCost = () => {
        setCostFields([...costFields, { uuid: uuidv4() } as CostModel]);
    }

    let handleRemoveCost = (i: number) => {
        let newCostFields = [...costFields];
        newCostFields.splice(i, 1);
        setCostFields(newCostFields)
        setupCostFields(newCostFields)
    }

    useEffect(() => {
        if (costFields.length == 0 && contractCosts != undefined) {
            setCostFields(contractCosts)
            setupCostFields(contractCosts)
        }
    }, [contractCosts])

    function setupCostFields(contractCosts: CostModel[]) {
        reset({ ...getValues(), costs: [] })
        contractCosts.map((field, index) => {
            if (field.description != undefined)
                setValue(`costs.${index}.description`, field.description);
            if (field.amount != undefined)
                setValue(`costs.${index}.amount`, FormateMoneyFloat(field.amount.toString() ?? ""));
        })
    }

    return (
        <>
            <Divider />
            <Heading size={"md"} fontWeight={"normal"} flex={"1"}>
                Custos adicionais do contrato
                <Button
                    onClick={handleAddCost}
                    colorScheme={"green"} size={"sm"} ml={4}>
                    Adicionar
                </Button>
            </Heading>

            {
                costFields.map((field, index) => (
                    <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} key={index}>
                        <Input
                            label={`Descrição do custo #${index}`}
                            error={errors.costs?.[index]?.description}
                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                handleChangeCost(field.uuid, "description", e.currentTarget.value)
                            }}
                            defaultValue={field.description}
                            {...register(`costs.${index}.description`)}
                        />
                        <HStack spacing={8}>
                            <Input
                                label={`Valor do custo #${index}`}
                                error={errors.costs?.[index]?.amount}
                                defaultValue={field?.amount != undefined ? FormateMoneyFloat(field.amount.toString()) : ""}
                                onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                    const value = FormateMoney(e.currentTarget.value)
                                    e.currentTarget.value = value
                                    handleChangeCost(field.uuid, "amount", parseFloat(ClearMoney(value)))
                                }}
                                {...register(`costs.${index}.amount`)}
                            />
                            <FormControl>
                                <FormLabel>Remover custo?</FormLabel>
                                <Button
                                    onClick={() => { handleRemoveCost(index) }}
                                    colorScheme={"red"} size={"lg"}
                                    w={"100%"}>
                                    Remover
                                </Button>
                            </FormControl>
                        </HStack>
                    </SimpleGrid>
                ))
            }
            <Divider />
        </>
    )
}