import { ResponseApi } from "../../models/Api/ResponseApi";
import { api } from "../api";

export async function deleteVehicle(uuid: string): Promise<ResponseApi> {
    let response = {} as ResponseApi
    await api.delete('vehicle/' + uuid).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}