import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexVehicle } from "../../services/hooks/useIndexVehicle";
import { IndexVehicleProps } from "../../services/vehicles/indexVehicle";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";

export function List() {
  const { parent_uuid, parent } = useParams();

  const indexProps: IndexVehicleProps = {
    driverUUID: parent == "drivers" ? parent_uuid : undefined,
    isFleet: parent == "fleet"
  }

  const newVehicleUrl = (parent_uuid != undefined ? "/vehicles/" + parent + "/" + parent_uuid + "/new" : "/vehicles/" + parent + "/new")

  const { data, isFetching, refetch } = useIndexVehicle(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={5} uuid={parent_uuid} /> :
        <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Veículos " + ((parent == "fleet") ? "Frota" : "")}
            createNew={{
              to: newVehicleUrl,
              label: "Cadastrar novo",
            }}
            isLoading={isFetching}
            refetch={refetch}
          />

          {data?.vehicles.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable vehicles={data?.vehicles} refetch={refetch} parent={parent!} parent_uuid={parent_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
