import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Box } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateDate, FormateMoney, FormateMoneyFloat, FormateQuilo, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { TransportInvoiceModel } from "../../../models/TransportInvoiceModel";
import { deleteTransportInvoice } from "../../../services/transportInvoices/deleteTransportInvoice";

interface ListTableProps {
  transportInvoices?: TransportInvoiceModel[];
  transport_uuid?: string;
  refetch?: () => void;
}

export function ListTable({ transportInvoices, transport_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteTransportInvoice(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} pr={"0"}># Nº</Th>
            <Th>Firma</Th>
            <Th>Quantidade (Quilos)</Th>
            <Th>Valor imposto</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {transportInvoices?.map((transaction) => {
            return (
              <Tr key={transaction.uuid}>
                <Td pl={"0"} pr={"0"}>
                  <Text fontWeight="bold"># {transaction.identifier}</Text>
                </Td>
                <Td> {transaction.company.toUpperCase()}</Td>
                <Td>{(FormateQuilo(transaction.total_weight.toString()))}</Td>
                <Td>R$ {(FormateMoneyFloat(transaction.total_tax.toString()))}</Td>
                <Td title={formatDistanceToNow(new Date(transaction.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(transaction.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {transaction.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton mr={"4"} as={Link} to={"/transports-general/invoices/" + transport_uuid + "/edit/" + transaction.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(transaction.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
