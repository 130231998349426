import { useContext } from "react";
import { createContext, ReactNode, useState } from "react";

interface ConfirmDeleteContextData {
    onOpen: (uuidToDelete: string) => void
    onClose: () => void
    isOpen: boolean
    uuidToDelete: string
}

export const ConfirmDeleteContext = createContext({} as ConfirmDeleteContextData);

interface ConfirmDeleteContextProviderProps {
    children: ReactNode;
}

export function ConfirmDeleteContextProvider({ children, }: ConfirmDeleteContextProviderProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [uuidToDelete, setUuidToDelete] = useState("");
    const onClose = () => { setIsOpen(false) }
    const onOpen = (uuidToDelete: string) => {
        setIsOpen(true)
        setUuidToDelete(uuidToDelete)
    }

    return (
        <ConfirmDeleteContext.Provider value={{ onOpen, onClose, isOpen, uuidToDelete }}>
            {children}
        </ConfirmDeleteContext.Provider>
    );
}

export const useConfirmDelete = () => useContext(ConfirmDeleteContext);
