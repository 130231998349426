import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { createVehicle } from "../../services/vehicles/createVehicle";
import { VehicleModel } from "../../models/VehicleModel";
import { FormToJson, Toast } from "../../utils/utils";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { Form } from "../vehicles/components/Form";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";

const formShema = yup.object().shape({
  plate: yup.string().required("Obrigatório"),
  renavam: yup.string().required("Obrigatório"),
  rntrc: yup.string().required("Obrigatório"),
});

export function New() {
  const { uuid, parent_uuid, parent, vehicle_uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  let kind = "TRACTOR"
  if ((parent == "fleet" && parent_uuid != undefined) || vehicle_uuid != undefined) kind = "TRAILER";

  const handleFormSubmit: SubmitHandler<FieldValues | VehicleModel> = async (values) => {
    const photoCrvl = values.photo_crlv_file[0] as File;
    const photoVehicle = values.photo_vehicle_file[0] as File;

    const data = JSON.parse(FormToJson(values)) as VehicleModel
    data.uuid = uuid!
    data.is_fleet = (parent == "fleet")
    data.driver_uuid = (parent == "drivers" ? parent_uuid : undefined)
    data.tractor_uuid = (parent == "fleet" ? parent_uuid : (vehicle_uuid != undefined ? vehicle_uuid : undefined))
    data.kind = kind

    const response = await createVehicle(data, photoCrvl, photoVehicle);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/vehicles/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={5} uuid={parent_uuid} /> :
        <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar Veículo " + (kind == "TRACTOR" ? "(Trator)" : "(Reboque)")} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
