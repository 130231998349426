import { Icon, IconButton, Stack, useColorMode } from "@chakra-ui/react";
import { RiLightbulbLine, RiShieldKeyholeLine } from "react-icons/ri";
import { useConfirmLogoff } from "../../contexts/ConfirmLogoffContext";
import { ConfirmLogoffAlert } from "../ConfirmLogoffAlert/ConfirmLogoffAlert";
import { useNavigate } from "react-router-dom";

export function ShortNav() {
  const { toggleColorMode } = useColorMode();
  const { onOpen, onClose} = useConfirmLogoff()
  const navigate = useNavigate();

  return (
    <>
      <ConfirmLogoffAlert onLogoff={() =>{
      navigate("/login");
      onClose();
      }}/>
      <Stack
        direction={"row"}
        spacing={"4"}
        mx={"8"}
        pr={"8"}
        py={"1"}
        color={"gray.300"}
        borderRightWidth={1}
        borderColor={"gray.700"}
      >
        <IconButton
          aria-label={"Alterar cor"}
          size={"sm"}
          fontSize={"sm"}
          colorScheme={"orange"}
          variant={"outline"}
          icon={<Icon as={RiLightbulbLine} fontSize={"20"} />}
          onClick={toggleColorMode}
        />

        <IconButton
          onClick={onOpen}
          aria-label={"Sair"}
          size={"sm"}
          fontSize={"sm"}
          colorScheme={"teal"}
          mr={"4"}
          variant={"outline"}
          icon={<Icon as={RiShieldKeyholeLine} fontSize={"20"} />}
        />
      </Stack>
    </>
  );
}
