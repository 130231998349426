import { createStandaloneToast } from "@chakra-ui/react";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { ResponseApi } from "../models/Api/ResponseApi";
import { communicationMap } from "../services/communicationMap";
import moment from "moment-timezone"

interface ToastProps {
    response: ResponseApi
    description?: string
    setError?: UseFormSetError<FieldValues>
}
export async function Toast({ response, setError, description }: ToastProps) {
    const { toast } = createStandaloneToast();

    if (setError) {
        if (response.httpStatus != 200 && response.httpStatus != 201) {
            if (response.fields?.length || 0 > 0) {
                response.fields?.reverse().forEach((field) => {
                    const resolvedFieldName = field.field.replaceAll("[", ".").replaceAll("]", "")
                    setError!(resolvedFieldName, {
                        type: "custom",
                        message: communicationMap[field.code],
                    });
                });
            }
        }
    }

    toast({
        title: communicationMap[response.code],
        status: (response.httpStatus != 200 && response.httpStatus != 201) ? 'error' : 'success',
        duration: 5000,
        description: description,
        isClosable: true,
        position: 'top',
    })
}

export function ClearMoney(value: string) {
    const clearMoney = value.replace(".", "").replace(",", "")
    if (clearMoney.length > 1) {
        value = clearMoney.substr(0, clearMoney.length - 2) + "." + clearMoney.substr(clearMoney.length - 2, 2);
    } else {
        value = "0.0" + clearMoney
    }
    return value
}

export function ClearQuilo(value: string) {
    return value.replace(".", "").replace(",", "")
}

export function FormateMoney(value: string) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
}

export function FormatePercentage(value: string) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");    
    return value;
}

export function FormateQuilo(value: string) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{3})$/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
}

export function FormateDate(value: string, format: string) {
    return moment(new Date(value)).tz("UTC").format(format)
}

export function FormateDateToBack(dateString: string, format: string) {
    var newData = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1');
    return moment(new Date(newData)).tz("UTC").format(format)
}

export function FormateMoneyFloat(value: string) {
    const decimal = value.split(".");
    if (decimal[1] != undefined) {
        if (decimal[1].length == 2) {
            return FormateMoney(value)
        }
        return FormateMoney(value + "0")
    }
    return FormateMoney(value + "00")
}

export function HandleMaskMoney(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = FormateMoney(value)
    e.currentTarget.value = value;
}


export function HandleMaskQuilo(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = FormateQuilo(value)
    e.currentTarget.value = value;
}

export function FormToJson(values: FieldValues): string {
    return JSON.stringify(values, function (key, value) {
        if (typeof value === "string") {
            return value.toUpperCase();
        }
        return value;
    })
}