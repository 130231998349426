import { ResponseApi } from "../../models/Api/ResponseApi";
import { ContractDataToCreateModel } from "../../models/ContractModel";
import { api } from "../api";

export async function showContractDataToCreate(): Promise<ContractDataToCreateModel> {
    let urlApi = "contracts/data-to-create"

    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const contractsData = response.data as ContractDataToCreateModel

    return contractsData;
}