import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Button, Collapse, Icon, SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { Link, NavigateFunction, useParams } from "react-router-dom";
import { VehicleModel } from "../../../models/VehicleModel";
import { FormActions } from "../../../components/Form/FormActions";
import { InputFile } from "../../../components/Form/InputFile";
import { OptionsProps, Select2 } from "../../../components/Form/Select";
import InputMask from "react-input-mask";
import { RiTruckLine } from "react-icons/ri";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    vehicleData?: VehicleModel
}

export function Form({ navigate, useFormReturn, vehicleData }: FormProps) {
    const { parent_uuid, parent } = useParams();
    const [maskPhone, setMaskPhone] = useState("(99) 9999-99999")
    const [maskDocument, setMaskDocument] = useState("999.999.999-99")
    const [maskPlate, setMaskPlate] = useState("aaa-9*99")
    const [photoVehicleSelected, setPhotoVehicleSelected] = useState("");
    const [photoCrlvSelected, setPhotoCrlvSelected] = useState("");
    const [ownerIsDriver, setOwnerIsDriver] = useState(true)

    const { register, formState, setValue } = useFormReturn
    const { errors } = formState;

    useEffect(() => {
        if (vehicleData) {
            setValue("plate", vehicleData?.plate);
            setValue("renavam", vehicleData?.renavam);
            setValue("rntrc", vehicleData?.rntrc);
            setValue("owner_is_driver", vehicleData?.owner_is_driver);
            if (vehicleData?.owner?.document) { setValue("owner[document]", vehicleData?.owner?.document) }
            if (vehicleData?.owner?.name) { setValue("owner[name]", vehicleData?.owner?.name) }
            if (vehicleData?.owner?.phone) { setValue("owner[phone]", vehicleData?.owner?.phone) }
            setOwnerIsDriver(vehicleData?.owner_is_driver)
        }
    }, [vehicleData])

    const OptionsOwnerIsDriver: OptionsProps[] = [
        { label: "SIM", value: true },
        { label: "NÃO", value: false },
    ]

    const urlAddTrailer = (parent_uuid != undefined) ? "/vehicles/" + parent + "/" + parent_uuid + "/" + vehicleData?.uuid + "/new" : "/vehicles/" + parent + "/" + vehicleData?.uuid + "/new";
    const ownerPhase = (parent_uuid != undefined) ? "Mototista é proprietário?" : "Propriedade da Multigrãos?";

    return (
        <>
            <Input type="file" display={"none"}
                {...register("photo_vehicle_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setPhotoVehicleSelected(e.target.files[0].name);
                        } else {
                            setPhotoVehicleSelected("");
                        }
                    },
                })}
            />

            <Input type="file" display={"none"}
                {...register("photo_crlv_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setPhotoCrlvSelected(e.target.files[0].name);
                        } else {
                            setPhotoCrlvSelected("");
                        }
                    },
                })}
            />

            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        as={InputMask}
                        label="Placa"
                        mask={maskPlate}
                        maskChar={''}
                        defaultValue={vehicleData?.plate}
                        error={errors.plate}
                        {...register("plate", {
                            onChange: (e) => {
                                const clearValue = e.target.value.replace(/[^a-zA-Z0-9 ]+/g, '');
                                if (clearValue[4] != undefined) {
                                    if (isNaN(clearValue[4])) {
                                        setMaskPlate("aaa9*99")
                                    } else {
                                        setMaskPlate("aaa-9*99")
                                    }
                                }
                            },
                        })} />

                    <Input
                        label="Renavam"
                        error={errors.renavam}
                        {...register("renavam")} />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <InputFile
                        label="Arquivo CRLV (Imagem ou PDF)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.photo_crlv}
                        value={photoCrlvSelected}
                        imageURL={vehicleData?.photo_crlv}
                        onClick={() => {
                            document.getElementById("photo_crlv_file")?.click();
                        }}
                        {...register("photo_crlv")} />

                    <InputFile
                        label="Foto veículo (Opcional)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.photo_vehicle}
                        value={photoVehicleSelected}
                        imageURL={vehicleData?.photo_vehicle}
                        onClick={() => {
                            document.getElementById("photo_vehicle_file")?.click();
                        }}
                        {...register("photo_vehicle")} />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="RNTRC"
                        error={errors.rntrc}
                        {...register("rntrc")} />

                    <Select2
                        label={ownerPhase}
                        options={OptionsOwnerIsDriver}
                        error={errors.owner_is_driver}
                        useFormReturn={useFormReturn}
                        name={"owner_is_driver"}
                        selectedValue={vehicleData?.owner_is_driver}
                        onChangeSelect={(e) => {
                            setOwnerIsDriver(e)
                        }} />
                </SimpleGrid>
                <Collapse in={!ownerIsDriver} animateOpacity>
                    <Stack direction={"column"} spacing={"4"}>
                        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                            <Input
                                label="Nome completo ou razão social (Proprietário)"
                                error={errors.owner?.name}
                                {...register("owner.name")} />
                        </SimpleGrid>

                        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                            <Input
                                as={InputMask}
                                label="CPF ou CNPJ"
                                mask={maskDocument}
                                maskChar={''}
                                error={errors.owner?.document}
                                {...register("owner.document", {
                                    onChange: (e) => {
                                        const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                                        if (clearValue.length <= 11) {
                                            setMaskDocument("999.999.999-999")
                                        } else {
                                            setMaskDocument("99.999.999/9999-99")
                                        }
                                    },
                                })} />

                            <Input
                                as={InputMask}
                                label="Telefone"
                                mask={maskPhone}
                                maskChar={''}
                                error={errors.owner?.phone}
                                {...register("owner.phone", {
                                    onChange: (e) => {
                                        const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                                        if (clearValue.length <= 10) {
                                            setMaskPhone("(99) 9999-99999")
                                        } else {
                                            setMaskPhone("(99) 9 9999-9999")
                                        }
                                    },
                                })} />
                        </SimpleGrid>
                    </Stack>
                </Collapse>
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn}>
                {vehicleData?.kind == "TRACTOR" ? (
                    <Button as={Link} to={urlAddTrailer} colorScheme={"green"} size={"lg"} leftIcon={<Icon as={RiTruckLine} fontSize={"20"} />}>
                        Cadastrar Reboque
                    </Button>
                ) : (<></>)}
            </FormActions>
        </>
    )
}