import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { createPartner } from "../../services/partners/createPartner";
import { PartnerModel } from "../../models/PartnerModel";
import { FormToJson, Toast } from "../../utils/utils";
import { Form } from "../partners/components/Form";

const formShema = yup.object().shape({
  legal_name: yup.string().required("Obrigatório"),
  social_name: yup.string().required("Obrigatório"),
  document: yup.string().required("Obrigatório"),
  address: yup.object().shape({
    address: yup.string().required("Obrigatório"),
    city: yup.string().required("Obrigatório"),
    district: yup.string().required("Obrigatório"),
    postal_code: yup.string().required("Obrigatório"),
    uf: yup.string().required("Obrigatório"),
  }),
  phone: yup.object().shape({
    number: yup.string().required("Obrigatório"),
  }),
  bank_account: yup.object().shape({
    bank: yup.string().required("Obrigatório"),
    beneficiary_name: yup.string().required("Obrigatório"),
    // beneficiary_document: yup.string().required("Obrigatório"),
  })
});

export function New() {
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | PartnerModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as PartnerModel
    const response = await createPartner(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/partners");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar parceiro"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
