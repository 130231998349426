import { SimpleGrid, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface PartnersNavigatorProps {
    uuid?: string;
    step: number;
}

export function PartnersNavigator({ uuid, step }: PartnersNavigatorProps) {
    return (
        <SimpleGrid columns={[0, 1, 2, 3]} spacing={"4"} w={"100%"} mb={"4"}>
            <Button as={Link} to={"/partners/edit/" + uuid} colorScheme={step == 1 ? "teal" : "gray"} variant={step == 1 ? "solid" : "outline"}>1. Informações base</Button>
            <Button as={Link} to={"/addresses/partners/" + uuid} colorScheme={step == 2 ? "teal" : "gray"} variant={step == 2 ? "solid" : "outline"}>2. Endereços</Button>
            <Button as={Link} to={"/phones/partners/" + uuid} colorScheme={step == 3 ? "teal" : "gray"} variant={step == 3 ? "solid" : "outline"}>3. Telefones</Button>
            <Button as={Link} to={"/bank_accounts/partners/" + uuid} colorScheme={step == 4 ? "teal" : "gray"} variant={step == 4 ? "solid" : "outline"}>4. Contas</Button>
            <Button as={Link} to={"/transactions/partners/" + uuid} colorScheme={step == 5 ? "teal" : "gray"} variant={step == 5 ? "solid" : "outline"}>5. Financeiro</Button>
            <Button as={Link} to={"/contracts/partners/" + uuid} colorScheme={step == 6 ? "teal" : "gray"} variant={step == 6 ? "solid" : "outline"}>6. Contratos</Button>
        </SimpleGrid>
    )
}