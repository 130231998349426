import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Box } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateDate, FormateMoney, FormateMoneyFloat, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { TransactionModel } from "../../../models/TransactionModel";
import { deleteTransaction } from "../../../services/transactions/deleteTransaction";

interface ListTableProps {
  transactions?: TransactionModel[];
  parent?: string;
  parent_uuid?: string;
  refetch?: () => void;
}

export function ListTable({ transactions, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteTransaction(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} pr={"0"}># Nº</Th>
            <Th>Operação</Th>
            <Th>Valor</Th>
            <Th>Serviço</Th>
            <Th>Motorista / Cliente</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions?.map((transaction) => {
            return (
              <Tr key={transaction.uuid}>
                <Td pl={"0"} pr={"0"}>
                  <Text fontWeight="bold"># {transaction.number.toUpperCase()}</Text>
                </Td>
                <Td> {transaction.kind.toUpperCase()}</Td>
                <Td>R$ {(FormateMoneyFloat(transaction.amount.toString()))}</Td>
                <Td> {transaction.transport_uuid != undefined ? "TRANSPORTE" : "CONTRATO"}</Td>
                <Td>
                  <Box>
                    <Text fontWeight="bold">
                      {transaction.transport_uuid != undefined ? transaction.driver_name :
                        transaction.contract_uuid != undefined ? transaction.partner_name : "-"}
                    </Text>
                    <Text fontSize="sm" color="gray.300">
                      {transaction.transport_uuid != undefined ? transaction.driver_document :
                        transaction.contract_uuid != undefined ? transaction.partner_document : "-"}
                    </Text>
                  </Box>
                </Td>
                <Td title={formatDistanceToNow(new Date(transaction.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(transaction.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {transaction.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={
                          transaction.contract_uuid != undefined ? "/transactions/contracts-general/" + transaction.contract_uuid + "/edit/" + transaction.uuid :
                            transaction.transport_uuid != undefined ? "/transactions/transports-general/" + transaction.transport_uuid + "/edit/" + transaction.uuid :
                              "/transactions-general" + "/edit/" + transaction.uuid
                        }
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(transaction.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
