import { Flex, Icon, IconButton, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { ContractModel } from "../../models/ContractModel";
import { ClearMoney, ClearQuilo, FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { CompaniesNavigator } from "../../components/Form/Navigator/CompaniesNavigator";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { showContract } from "../../services/contracts/showContract";
import { updateContract } from "../../services/contracts/updateContract";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { Form } from "./components/Form";
import { RiFileLine } from "react-icons/ri";
import { GenerateContract } from "../../Reports/Contracts/GenerateContract";

const formShema = yup.object().shape({
  number: yup.string().required("Obrigatório"),
  payment_term: yup.string().required("Obrigatório"),
  delivery: yup.string().required("Obrigatório"),
  pickup_location: yup.string().required("Obrigatório"),
  amount: yup.string().required("Obrigatório"),
  quantity_min: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { parent_uuid, parent, uuid } = useParams();

  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | ContractModel> = async (values) => {
    const proof = values.proof_file[0] as File;
    
    const data = JSON.parse(FormToJson(values)) as ContractModel
    data.amount = parseFloat(ClearMoney(data.amount.toString()))
    data.quantity_min = parseFloat(ClearQuilo(data.quantity_min.toString()))
    if (data.quantity_max !== undefined) data.quantity_max = parseFloat(ClearQuilo(data.quantity_max.toString()))
    data.uuid = uuid!
    data.costs = data.costs?.map(item => {
      item.amount = parseFloat(ClearMoney(item.amount.toString()))
      return item
    })

    const response = await updateContract(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate(parent != undefined ? "/contracts/" + parent + "/" + parent_uuid : "/contracts-general");
    }
  };

  const [contractData, setContractData] = useState<ContractModel>();
  const getContractData = async () => {
    const response = await showContract(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate(parent != undefined ? "/contracts/" + parent + "/" + parent_uuid : "/contracts-general");
      return;
    }
    if (response?.data) {
      setContractData(response.data)
    }
  }

  useEffect(() => {
    getContractData();
  }, [])


  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "companies" ?
        <CompaniesNavigator step={4} uuid={parent_uuid} /> :
        parent == "partners" ?
          <PartnersNavigator step={6} uuid={parent_uuid} /> :
          <ContractsNavigator step={1} uuid={uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Contrato"} >
            <IconButton
              aria-label={"Relatório"}
              size={"sm"}
              fontSize={"sm"}
              colorScheme={"teal"}
              variant={"outline"}
              icon={<Icon as={RiFileLine} fontSize={"20"} />}
              onClick={(e) => { GenerateContract(uuid!) }}
            />
          </ContainerHeader>
          <Form navigate={navigate} useFormReturn={useFormReturn} contractData={contractData} />
        </DefaultBox>
      </Flex>

    </Stack>
  );
}
