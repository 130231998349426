import { ResponseApi } from "../../models/Api/ResponseApi";
import { VehicleModel } from "../../models/VehicleModel";
import { api } from "../api";
import { serialize } from 'object-to-formdata';

export async function createVehicle(vehicleData: VehicleModel, photoCrlv: File, photoVehicle: File): Promise<ResponseApi> {
    let response = {} as ResponseApi
    const formData = serialize(vehicleData, { indices: true, nullsAsUndefineds: true })
    if (photoCrlv != undefined) formData.append("photo_crlv", photoCrlv || "")
    if (photoVehicle != undefined) formData.append("photo_vehicle", photoVehicle || "")

    await api.post("vehicles", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}