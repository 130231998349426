import { Button, FormControl, FormErrorMessage, FormLabel, Icon, Input as ChakraIput, InputGroup, InputLeftElement, InputProps as ChakraInputProps, Link, Stack, useColorMode } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { RiFile2Line } from "react-icons/ri";
import { FilePrefix } from "../../services/api";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: any;
  imageURL?: string;
}

const InputFileBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, imageURL, error = null, ...rest }, ref
) => {
  const { colorMode } = useColorMode();

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Stack direction={"row"}>
        <InputGroup>
          <InputLeftElement
            mt={1}
            zIndex={1}
            pointerEvents="none"
            children={<Icon as={RiFile2Line} fontSize={"20"} />}
          />
          <ChakraIput
            autoComplete={"off"}
            focusBorderColor={"teal.500"}
            bg={colorMode === "dark" ? "gray.900" : "gray.50"}
            variant={"filled"}
            size={"lg"}
            {...rest}
          />
        </InputGroup>
        {!!imageURL && <Button as={Link} href={FilePrefix + imageURL} isExternal colorScheme={"orange"} size={"lg"}>Baixar</Button>}
      </Stack>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputFile = forwardRef(InputFileBase);
