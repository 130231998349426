import { useContext } from "react";
import { createContext, ReactNode, useState } from "react";

interface ConfirmLogoffContextData {
    onOpen: () => void
    onClose: () => void
    isOpen: boolean
}

export const ConfirmLogoffContext = createContext({} as ConfirmLogoffContextData);

interface ConfirmLogoffContextProviderProps {
    children: ReactNode;
}

export function ConfirmLogoffContextProvider({ children, }: ConfirmLogoffContextProviderProps) {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => { setIsOpen(false) }
    const onOpen = () => {
        setIsOpen(true)
    }

    return (
        <ConfirmLogoffContext.Provider value={{ onOpen, onClose, isOpen}}>
            {children}
        </ConfirmLogoffContext.Provider>
    );
}

export const useConfirmLogoff = () => useContext(ConfirmLogoffContext);
