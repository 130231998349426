import { useState, useEffect } from "react";
import { TransportContractModel } from "../../../models/TransportModel";
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Divider, Heading, Button, SimpleGrid, HStack, FormControl, FormLabel, Grid, GridItem } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { ClearQuilo, FormateQuilo } from "../../../utils/utils";
import { OptionsProps, Select2 } from "../../../components/Form/Select";

interface TransportContractsProps {
    useFormReturn: UseFormReturn<FieldValues, any>
    optionsContracts: OptionsProps[]
    transportContracts?: TransportContractModel[]
}

export function TransportsContracts({ useFormReturn, optionsContracts, transportContracts }: TransportContractsProps) {

    if (optionsContracts.length == 0) return (<></>)

    const { register, getValues, formState, reset, setValue } = useFormReturn
    const { errors } = formState;

    const [contractFields, setContractFields] = useState<TransportContractModel[]>([])

    const handleChangeContract = <T extends keyof TransportContractModel>(uuid: string, field: T, value: TransportContractModel[T]) => {
        const newContractFields = contractFields.map(contract => {
            if (contract.uuid === uuid) {
                contract[field] = value;
            }
            return contract;
        });
        setContractFields(newContractFields);
    };

    const handleAddContract = () => {
        setContractFields([...contractFields, { uuid: uuidv4() } as TransportContractModel])
    }

    let handleRemoveContract = (i: number) => {
        let newContractFields = [...contractFields];
        newContractFields.splice(i, 1);
        setContractFields(newContractFields)
        setupContractFields(newContractFields)
    }

    useEffect(() => {
        if (contractFields.length == 0 && transportContracts != undefined) {
            setContractFields(transportContracts)
            setupContractFields(transportContracts)
        }
    }, [transportContracts])

    function setupContractFields(contractFields: TransportContractModel[]) {
        reset({ ...getValues(), transport_contracts: [] })
        contractFields.map((field, index) => {
            if (field.total_weight != undefined) setValue(`transport_contracts.${index}.total_weight`, FormateQuilo(field.total_weight.toString() ?? ""));
            if (field.contract_uuid != undefined) setValue(`transport_contracts.${index}.contract_uuid`, field.contract_uuid);
        })
    }

    return (
        <>
            <Divider />
            <Heading size={"md"} fontWeight={"normal"} flex={"1"}>
                Contratos vinculados ao carregamento
                <Button
                    onClick={handleAddContract}
                    colorScheme={"green"} size={"sm"} ml={4}>
                    Adicionar
                </Button>
            </Heading>

            {
                contractFields.map((field, index) => (
                    <>
                        <Divider />
                        <Grid w={"100%"} templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={{ base: 0, md: 4 }}  key={index}>
                            <GridItem colSpan={8}>
                                <Select2 gridColumn={5}
                                    label={`Contrato`}
                                    options={(optionsContracts.length > 0) ? optionsContracts : [{ label: "Carregando...", value: "-" }]}
                                    error={errors.transport_contracts?.[index]?.contract_uuid}
                                    useFormReturn={useFormReturn}
                                    name={`transport_contracts.${index}.contract_uuid`}
                                    selectedValue={field.contract_uuid}
                                    onChangeSelect={(contractUUID) => { handleChangeContract(field.uuid, "contract_uuid", contractUUID) }}
                                />
                            </GridItem>

                            <GridItem colSpan={2} >
                                <Input
                                label={`Peso liquido`}
                                error={errors.transport_contracts?.[index]?.total_weight}
                                onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                    const value = FormateQuilo(e.currentTarget.value)
                                    e.currentTarget.value = value;
                                    handleChangeContract(field.uuid, "total_weight", parseFloat(ClearQuilo(value)))
                                }}
                                defaultValue={FormateQuilo(field.total_weight?.toString() ?? "")}
                                {...register(`transport_contracts.${index}.total_weight`)} />
                            </GridItem>

                            <GridItem colSpan={2} >
                                <HStack spacing={8}>
                                    <FormControl>
                                        <FormLabel> - </FormLabel>
                                        <Button
                                            onClick={() => { handleRemoveContract(index) }}
                                            colorScheme={"red"} size={"lg"}
                                            w={"100%"}>
                                            Remover
                                        </Button>
                                    </FormControl>
                                </HStack>
                            </GridItem>
                        </Grid>                        
                    </>
                ))
            }
        </>
    )
}