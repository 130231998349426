import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportContractModel } from "../../models/TransportContractModel";
import { api } from "../api";

type GetTransportContractsResponse = {
    transportContracts: TransportContractModel[];
}

export type IndexTransportsContractsProps = {
    contractUUID?: string,
    transportUUID?: string,
}

export async function indexTransportContract({ transportUUID, contractUUID }: IndexTransportsContractsProps): Promise<GetTransportContractsResponse> {
    let urlApi = "transport_contracts/"
    if (transportUUID != undefined) {
        urlApi = urlApi + "transport/" + transportUUID
    } else if (contractUUID != undefined) {
        urlApi = urlApi + "contract/" + contractUUID
    }

    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const transportContractsData = response.data as TransportContractModel[]

    return { transportContracts: transportContractsData };
}