import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { PhoneModel } from "../../models/PhoneModel";
import { FormToJson, Toast } from "../../utils/utils";
import { CompaniesNavigator } from "../../components/Form/Navigator/CompaniesNavigator";
import { showPhone } from "../../services/phones/showPhone";
import { updatePhone } from "../../services/phones/updatePhone";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { Form } from "../phones/components/Form";

const formShema = yup.object().shape({
  number: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { uuid, parent, parent_uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [phoneData, setPhoneData] = useState<PhoneModel>();
  const getPhoneData = async () => {
    const response = await showPhone(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/phones/" + parent + "/" + parent_uuid);
      return;
    }
    if (response?.data) {
      setPhoneData(response.data)
    }
  }
  useEffect(() => {
    getPhoneData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | PhoneModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as PhoneModel
    data.uuid = uuid!
    data.company_uuid = parent == "companies" ? parent_uuid : undefined
    data.driver_uuid = parent == "drivers" ? parent_uuid : undefined
    data.partner_uuid = parent == "partners" ? parent_uuid : undefined

    const response = await updatePhone(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/phones/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "companies" ?
        <CompaniesNavigator step={3} uuid={parent_uuid} /> :
        parent == "phones" ?
          <PartnersNavigator step={3} uuid={parent_uuid} /> :
          <DriversNavigator step={3} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Telefone"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} phoneData={phoneData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
