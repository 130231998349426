import { Table, Box, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { deleteProduct } from "../../../services/products/deleteProduct";
import { FormateDate, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { PhoneModel } from "../../../models/PhoneModel";
import { deletePhone } from "../../../services/phones/deletePhone";

interface ListTableProps {
  phones?: PhoneModel[];
  parent: string;
  parent_uuid: string;
  refetch?: () => void;
}

export function ListTable({ phones, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deletePhone(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"}>Telefone</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {phones?.map((phone) => {
            return (
              <Tr key={phone.uuid}>
                <Td pl={"0"}>
                  <Text fontWeight="bold">{phone.number}</Text>
                </Td>
                <Td title={formatDistanceToNow(new Date(phone.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(phone.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {phone.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : phone.is_primary ? (
                    <IconButton
                      as={Link}
                      to={"/phones/" + parent + "/" + parent_uuid + "/edit/" + phone.uuid}
                      size={"xs"}
                      fontSize={"xs"}
                      colorScheme={"orange"}
                      icon={<Icon as={RiPencilLine} />}
                      aria-label={"Editar"} />
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={"/phones/" + parent + "/" + parent_uuid + "/edit/" + phone.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"} />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(phone.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
