import { useDisclosure, UseDisclosureReturn } from "@chakra-ui/react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface FilterData {
    page?: number;
    search?: string;
}

interface FilterContextData {
    disclosure: UseDisclosureReturn;
    filterData?: FilterData;
    updateFilterData(newFilterData: FilterData): void
}

export const FilterContext = createContext({} as FilterContextData);

interface FilterContextProviderProps {
    children: ReactNode;
}

export function FilterContextProvider({ children, }: FilterContextProviderProps) {
    //init state of filters and paginators
    const [filterData, setFilterData] = useState<FilterData>({});

    const updateFilterData = (newFilterData: FilterData) => {
        setFilterData((state) => ({ ...state, ...newFilterData }));
    }

    const disclosure = useDisclosure();
    const router = useLocation();

    useEffect(() => {
        disclosure.onClose();
        updateFilterData({ page: 1, search: undefined });
    }, [router.pathname]);

    return (
        <FilterContext.Provider value={{ disclosure, filterData, updateFilterData }}>
            {children}
        </FilterContext.Provider>
    );
}

export const useFilter = () => useContext(FilterContext);
