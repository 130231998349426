import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/react";

interface PaginationItemProps {
  number: number;
  title?: string;
  isCurrent?: boolean;
  onPageChange: (page: number) => void;
}

export function PaginationItem({
  isCurrent = false,
  onPageChange,
  number,
  title = "",
}: PaginationItemProps) {
  const { colorMode } = useColorMode();

  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        width="4"
        colorScheme="teal"
        disabled
        _disabled={{
          bg: "teal.500",
          cursor: "default",
        }}
      >
        {number}
      </Button>
    );
  }

  return (
    <Button
      size="sm"
      fontSize="xs"
      bg={colorMode === "dark" ? "gray.700" : "gray.50"}
      _hover={{
        bg: "gray.500",
      }}
      onClick={() => onPageChange(number)}
    >
      {title}
    </Button>
  );
}
