import { useState, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { PhoneModel } from "../../../models/PhoneModel";
import InputMask from "react-input-mask";
import { FormActions } from "../../../components/Form/FormActions";

interface FormProps {
  navigate: NavigateFunction
  useFormReturn: UseFormReturn<FieldValues, any>
  phoneData?: PhoneModel
}

export function Form({ navigate, useFormReturn, phoneData }: FormProps) {
  const [maskPhone, setMaskPhone] = useState("(99) 9999-99999")

  const { register, formState, setValue } = useFormReturn
  const { errors } = formState;

  useEffect(() => {
    if (phoneData) {
      setValue("number", phoneData?.number);
    }
  }, [phoneData])

  return (
    <>
      <Stack direction={"column"} spacing={"4"}>
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
          <Input
            as={InputMask}
            label="Telefone"
            mask={maskPhone}
            maskChar={''}
            defaultValue={phoneData?.number}
            error={errors.number}
            {...register("number", {
              onChange: (e) => {
                const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                if (clearValue.length <= 10) {
                  setMaskPhone("(99) 9999-99999")
                } else {
                  setMaskPhone("(99) 9 9999-9999")
                }
              },
            })}
          />
        </SimpleGrid>
      </Stack>
      <FormActions navigate={navigate} useFormReturn={useFormReturn} />
    </>
  )
}