import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { StorageModel } from "../../models/StorageModel";
import { FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { Form } from "../storages/components/Form";
import { createStorage } from "../../services/storages/createStorage";

const formShema = yup.object().shape({
  amount: yup.string().required("Obrigatório"),
  lost_amount: yup.string().required("Obrigatório"),
});

export function New() {
  const { parent_uuid, parent } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | StorageModel> = async (values) => {
    const proof = values.proof_file[0] as File;

    const data = JSON.parse(FormToJson(values)) as StorageModel

    const response = await createStorage(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate(parent != undefined ? "/storages/" + parent + "/" + parent_uuid : "/storages-general");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      {parent == "drivers" ?
        <DriversNavigator step={7} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={5} uuid={parent_uuid} /> :
          <></>
      }
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar armazenamento"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}