import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { PhoneModel } from "../../models/PhoneModel";
import { FormToJson, Toast } from "../../utils/utils";
import { CompaniesNavigator } from "../../components/Form/Navigator/CompaniesNavigator";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { Form } from "../phones/components/Form";
import { createPhone } from "../../services/phones/createPhone";

const formShema = yup.object().shape({
  number: yup.string().required("Obrigatório"),
});

export function New() {
  const { parent, parent_uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | PhoneModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as PhoneModel
    data.company_uuid = parent == "companies" ? parent_uuid : undefined
    data.driver_uuid = parent == "drivers" ? parent_uuid : undefined
    data.partner_uuid = parent == "partners" ? parent_uuid : undefined

    const response = await createPhone(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/phones/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "companies" ?
        <CompaniesNavigator step={3} uuid={parent_uuid} /> :
        parent == "phones" ?
          <PartnersNavigator step={3} uuid={parent_uuid} /> :
          <DriversNavigator step={3} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Telefone"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}