import { SimpleGrid, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface CompaniesNavigatorProps {
    uuid?: string;
    step: number;
}

export function CompaniesNavigator({ uuid, step }: CompaniesNavigatorProps) {
    return (
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={"4"}>
            <Button as={Link} to={"/companies/edit/" + uuid} colorScheme={step == 1 ? "teal" : "gray"} variant={step == 1 ? "solid" : "outline"}>1. Informações base</Button>
            <Button as={Link} to={"/addresses/companies/" + uuid} colorScheme={step == 2 ? "teal" : "gray"} variant={step == 2 ? "solid" : "outline"}>2. Endereços</Button>
            <Button as={Link} to={"/phones/companies/" + uuid} colorScheme={step == 3 ? "teal" : "gray"} variant={step == 3 ? "solid" : "outline"}>3. Telefones</Button>
            <Button as={Link} to={"/contracts/companies/" + uuid} colorScheme={step == 4 ? "teal" : "gray"} variant={step == 4 ? "solid" : "outline"}>4. Contratos</Button>
        </SimpleGrid>
    )
}