import { SimpleGrid, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface TransportsNavigatorProps {
    uuid?: string;
    step: number;
}

export function TransportsNavigator({ uuid, step }: TransportsNavigatorProps) {
    return (
        <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={"4"}>
            <Button as={Link} to={"/transports-general/edit/" + uuid} colorScheme={step == 1 ? "teal" : "gray"} variant={step == 1 ? "solid" : "outline"}>1. Informações base</Button>
            {/* <Button as={Link} to={"/transports-general/invoices/" + uuid} colorScheme={step == 2 ? "teal" : "gray"} variant={step == 2 ? "solid" : "outline"}>2. Notas Fiscais</Button> */}
            {/* <Button as={Link} to={"/transport_contracts/transports-general/" + uuid} colorScheme={step == 3 ? "teal" : "gray"} variant={step == 3 ? "solid" : "outline"}>3. Contratos</Button> */}
            <Button as={Link} to={"/transactions/transports-general/" + uuid} colorScheme={step == 4 ? "teal" : "gray"} variant={step == 4 ? "solid" : "outline"}>4. Financeiro</Button>
        </SimpleGrid>
    )
}