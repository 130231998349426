import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { ContractModel } from "../../models/ContractModel";
import { api } from "../api";
import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";

type GetContractsResponse = {
    contracts: ContractModel[];
    paginator?: FilterPaginatorApi;
}

export type IndexContractProps = {
    companyUUID?: string,
    partnerUUID?: string,
    paginator?: FilterPaginatorApi
}

export async function indexContract({ partnerUUID, companyUUID, paginator }: IndexContractProps): Promise<GetContractsResponse> {
    let urlApi = "contracts"
    if (companyUUID != undefined) {
        urlApi = urlApi + "/company/" + companyUUID
    } else if (partnerUUID != undefined) {
        urlApi = urlApi + "/partner/" + partnerUUID
    }

    const { data } = await api.get(urlApi, {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
    let contractsData: ContractModel[] = []
    let paginatorData = undefined

    const response = data as ResponseApi
    if (response.data.results != undefined) {
        contractsData = response.data.results as ContractModel[]
        paginatorData = response.data.paginator as FilterPaginatorApi
    } else {
        contractsData = response.data as ContractModel[]
    }

    return {
        contracts: contractsData,
        paginator: paginatorData
    };
}

export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = [
            {
                field: "products.name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "contracts.number",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "partners.social_name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "companies.social_name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
        ]
    }

    return filter;
}