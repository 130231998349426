type communicationTyoe = {
    [key: string]: string
}
export const communicationMap: communicationTyoe = {
    "MTG0001": "Sucesso",
    "MTG0002": "Registro criado com sucesso",
    "MTG0003": "Registro atualizado com sucesso",
    "MTG0004": "Registro excluído com sucesso",
    "MTG0005": "Não foi possível criar registro",
    "MTG0006": "Não foi possível atualizar o registro",
    "MTG0007": "Não foi possível excluir registro",
    "MTG0008": "Não foi possível listar registro",
    "MTG0009": "Falha na autenticação",
    "MTG0010": "Autenticado com sucesso",
    "MTG0011": "Falha na validação",
    "MTG0012": "Ponto de extremidade não encontrado",
    "MTG0013": "Inesperado",
    "MTG0014": "Registro não encontrado",
    "MTG0015": "Falha na solicitação",
    "CHK0001": "Já existe",
    "CHK0002": "Obrigatório",
    "CHK0003": "Inválido",
    "CHK0004": "E-mail inválido",
    "CHK0005": "Cpf inválido",
    "CHK0006": "CNPJ inválido",
    "CHK0007": "Documento inválido",
    "CHK0008": "Data inválida",
    "CHK0009": "A senha deve ter entre 6 e 40 caracteres",
    "MTG0016": "Acesso negado",
}