import {
  Text,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import { ElementType } from "react";
import { Link } from "react-router-dom";
import { ActiveLink } from "./ActiveLink";

interface NavLinkProps extends ChakraLinkProps {
  to: string;
  icon: ElementType;
  children: string;
  shouldMatchExactHref?: boolean;
}

export function NavLink({
  icon,
  to,
  children,
  shouldMatchExactHref = false,
  ...rest
}: NavLinkProps) {
  return (
    <ActiveLink to={to} shouldMatchExactHref={shouldMatchExactHref}>
      <ChakraLink
        as={"div"}
        display={"flex"}
        align="center"
        _hover={{
          color: "teal.500",
        }}
        {...rest}
      >
        <Icon as={icon} fontSize={"20"} />
        <Text ml={"4"} fontSize={"md"}>
          {children}
        </Text>
      </ChakraLink>
    </ActiveLink>
  );
}
