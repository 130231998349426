import React from "react";
import ReactDOM from "react-dom/client";
import {
  ChakraProvider,
  ColorModeProvider,
} from "@chakra-ui/react";
import { theme } from "./styles/theme";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { SidebarDrawerProvider } from "./contexts/SidebarDrawerContext";
import { QueryClient, QueryClientProvider } from 'react-query'
import { FilterContextProvider } from "./contexts/FilterContext";
import { ConfirmLogoffContextProvider } from "./contexts/ConfirmLogoffContext";
import { AuthProvider } from "./contexts/AuthContext";
import { TransportReportContextProvider } from "./contexts/TransportReportContext";

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ColorModeProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <SidebarDrawerProvider>
              <FilterContextProvider>
                <TransportReportContextProvider>
                  <ConfirmLogoffContextProvider>
                    <Router />
                  </ConfirmLogoffContextProvider>
                </TransportReportContextProvider>
              </FilterContextProvider>
            </SidebarDrawerProvider>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </ColorModeProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);
