import { Button, Flex, Icon, SimpleGrid, Stack } from "@chakra-ui/react";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { createFile } from "../../services/files/createFile";
import { FileModel } from "../../models/FileModel";
import { FormToJson, Toast } from "../../utils/utils";
import { useState } from "react";
import { InputFile } from "../../components/Form/InputFile";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { Form } from "../files/components/Form";

const formShema = yup.object().shape({
  title: yup.string().required("Obrigatório"),
});

export function New() {
  const { parent_uuid, parent } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const handleFormSubmit: SubmitHandler<FieldValues | FileModel> = async (values) => {
    const proof = values.proof_file[0] as File;

    const data = JSON.parse(FormToJson(values)) as FileModel
    data.driver_uuid = (parent == "drivers" ? parent_uuid : undefined)
    data.contract_uuid = (parent == "contracts-general" ? parent_uuid : undefined)

    const response = await createFile(data, proof);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate(parent != undefined ? "/files/" + parent + "/" + parent_uuid : "/files-general");
    }
  };
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={8} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={3} uuid={parent_uuid} /> :
          <></>
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Cadastrar Arquivo"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
