import { ResponseApi } from "../../models/Api/ResponseApi";
import { StorageDataToCreateModel } from "../../models/StorageModel";
import { api } from "../api";

export async function showStorageDataToCreate(): Promise<StorageDataToCreateModel> {
    let urlApi = "storages/data-to-create"

    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const storagesData = response.data as StorageDataToCreateModel

    return storagesData;
}