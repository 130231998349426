import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { Pagination } from "../../components/Pagination/Pagination";
import { SearchList } from "../../components/ContainerSearch/ContainerSearch";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { useIndexCompany } from "../../services/hooks/useIndexCompany";
import { ListTable } from "./components/ListTable";
import { useFilter } from "../../contexts/FilterContext";
import { RiSearchEyeLine } from "react-icons/ri";
import { makeFilters } from "../../services/companies/indexCompany";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";

export function List() {
  const { filterData } = useFilter();
  const { data, isFetching, refetch } = useIndexCompany(makeFilters(filterData));

  return (
    <Stack w={"100%"} spacing={"0"}>
      <SearchList />
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Empresas"}
            createNew={{
              to: "/companies/new",
              label: "Cadastrar novo",
            }}
            isLoading={isFetching}
            refetch={refetch}
            search
          />

          {data?.companies.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable companies={data?.companies} refetch={refetch} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}

          <Pagination
            limit={data?.paginator.details.limit}
            page={data?.paginator.details.page}
            results={data?.companies.length}
          />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
