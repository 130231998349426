import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Box } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { CompanyModel } from "../../../models/CompanyModel";
import { deleteCompany } from "../../../services/companies/deleteCompany";
import { FormateDate, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

interface ListTableProps {
  companies?: CompanyModel[];
  refetch?: () => void;
}

export function ListTable({ companies, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()

  const handleDelete = async () => {
    onClose();
    const response = await deleteCompany(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} minW={"350"}>Nome da empresa</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {companies?.map((company) => {
            return (
              <Tr key={company.uuid}>
                <Td pl={"0"}>
                  <Box>
                    <Text fontWeight="bold">{company.social_name}</Text>
                    <Text fontSize="sm" color="gray.300">
                      {company.legal_name}
                    </Text>
                  </Box>
                </Td>
                <Td title={formatDistanceToNow(new Date(company.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(company.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {company.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={"/companies/edit/" + company.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(company.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
