import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportModel } from "../../models/TransportModel";
import { api } from "../api";
import { serialize } from 'object-to-formdata';

export async function updateTransport(transportData: TransportModel): Promise<ResponseApi> {
    let response = {} as ResponseApi
    const formData = serialize(transportData, { indices: true, nullsAsUndefineds: true })

    await api.patch('transport/' + transportData.uuid, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}