import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexMaintenance } from "../../services/hooks/useIndexMaintenance";
import { IndexMaintenanceProps, makeFilters } from "../../services/maintenances/indexMaintenance";
import { FleetsNavigator } from "../../components/Form/Navigator/FleetsNavigator";
import { useFilter } from "../../contexts/FilterContext";
import { SearchList } from "../../components/ContainerSearch/ContainerSearch";
import { Pagination } from "../../components/Pagination/Pagination";

export function List() {
  const { vehicle_uuid, parent } = useParams();

  const { filterData } = useFilter();
  const hasPaginator = vehicle_uuid != undefined

  const indexProps: IndexMaintenanceProps = {
    vehicleUUID: vehicle_uuid,
    paginator: !hasPaginator ? makeFilters(filterData) : undefined,
  }

  const { data, isFetching, refetch } = useIndexMaintenance(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "fleet" ?
        <FleetsNavigator step={2} uuid={vehicle_uuid} /> :
        <></>
      }

      <SearchList />

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Manutenções"}
            createNew={parent != undefined ? {
              to: "/maintenances/" + parent + "/" + vehicle_uuid + "/new",
              label: "Cadastrar novo",
            } : undefined}
            isLoading={isFetching}
            refetch={refetch}
            search={!hasPaginator}
          />

          {data?.maintenances.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable maintenances={data?.maintenances} refetch={refetch} parent={parent!} parent_uuid={vehicle_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}

          <Pagination
            limit={data?.paginator?.details.limit}
            page={data?.paginator?.details.page}
            results={data?.maintenances?.length}
          />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
