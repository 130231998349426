import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { CompanyModel } from "../../models/CompanyModel";
import { updateCompany } from "../../services/companies/updateCompany";
import { FormToJson, Toast } from "../../utils/utils";
import { showCompany } from "../../services/companies/showCompany";
import { CompaniesNavigator } from "../../components/Form/Navigator/CompaniesNavigator";
import { Form } from "../companies/components/Form";

const formShema = yup.object().shape({
  legal_name: yup.string().required("Obrigatório"),
  social_name: yup.string().required("Obrigatório"),
  document: yup.string().required("Obrigatório"),
  address: yup.object().shape({
    address: yup.string().required("Obrigatório"),
    city: yup.string().required("Obrigatório"),
    district: yup.string().required("Obrigatório"),
    postal_code: yup.string().required("Obrigatório"),
    uf: yup.string().required("Obrigatório"),
  }),
  phone: yup.object().shape({
    number: yup.string().required("Obrigatório"),
  }),
  bank_account: yup.object().shape({
    bank: yup.string().required("Obrigatório"),
    beneficiary_name: yup.string().required("Obrigatório"),
    beneficiary_document: yup.string().required("Obrigatório"),
  })
});

export function Edit() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [companyData, setCompanyData] = useState<CompanyModel>();
  const getCompanyData = async () => {
    const response = await showCompany(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/companies");
      return;
    }
    if (response?.data) {
      setCompanyData(response.data)
    }
  }
  useEffect(() => {
    getCompanyData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | CompanyModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as CompanyModel
    data.uuid = uuid!

    const response = await updateCompany(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/companies");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      <CompaniesNavigator step={1} uuid={uuid} />

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar dados da empresa"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} companyData={companyData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
