import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { ClearQuilo, FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { TransportsNavigator } from "../../components/Form/Navigator/TransportsNavigator";
import { TransportContractModel } from "../../models/TransportContractModel";
import { showTransportContract } from "../../services/transportContracts/showTransportContract";
import { updateTransportContract } from "../../services/transportContracts/updateTransportContract";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { Form } from "./components/Form";

const formShema = yup.object().shape({
  total_weight: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { parent, parent_uuid, uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [transportContractData, setTransportContractData] = useState<TransportContractModel>();
  const getTransportContractData = async () => {
    const response = await showTransportContract(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/transport_contracts/" + parent + "/" + parent_uuid);
      return;
    }
    if (response?.data) {
      setTransportContractData(response.data)
    }
  }

  useEffect(() => {
    getTransportContractData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | TransportContractModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as TransportContractModel
    data.uuid = uuid!
    data.transport_uuid = transportContractData!.transport_uuid
    data.contract_uuid = transportContractData!.contract_uuid
    data.total_weight = parseFloat(ClearQuilo(data.total_weight.toString()))
    const response = await updateTransportContract(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/transport_contracts/" + parent + "/" + parent_uuid);
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "transports-general" ?
        <TransportsNavigator step={3} uuid={parent_uuid} /> :
        <ContractsNavigator step={4} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>

          <ContainerHeader title={"Editar Quantidade Carregada"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} transportContractData={transportContractData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
