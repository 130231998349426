import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexBankAccount } from "../../services/hooks/useIndexBankAccount";
import { IndexBankAccountProps } from "../../services/bankAccounts/indexBankAccount";
import { PartnersNavigator } from "../../components/Form/Navigator/PartnersNavigator";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";

export function List() {
  const { parent_uuid, parent } = useParams();

  const indexProps: IndexBankAccountProps = {
    driverUUID: parent == "drivers" ? parent_uuid : undefined,
    partnerUUID: parent == "partners" ? parent_uuid : undefined
  }

  const { data, isFetching, refetch } = useIndexBankAccount(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "partners" ?
        <PartnersNavigator step={4} uuid={parent_uuid} /> :
        <DriversNavigator step={4} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Contas Bancarias"}
            createNew={{
              to: "/bank_accounts/" + parent + "/" + parent_uuid + "/new",
              label: "Cadastrar novo",
            }}
            isLoading={isFetching}
            refetch={refetch}
          />

          {data?.bankAccounts.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable bankAccounts={data?.bankAccounts} refetch={refetch} parent={parent!} parent_uuid={parent_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
