import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransactionModel } from "../../models/TransactionModel";
import { api } from "../api";
import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";

type GetTransactionsResponse = {
    transactions: TransactionModel[];
    paginator?: FilterPaginatorApi;
}

export type IndexTransactionProps = {
    driverUUID?: string,
    transportUUID?: string,
    contractUUID?: string,
    partnerUUID?: string,
    paginator?: FilterPaginatorApi
}

export async function indexTransaction({ driverUUID, transportUUID, partnerUUID, contractUUID, paginator }: IndexTransactionProps): Promise<GetTransactionsResponse> {
    let urlApi = "transactions"
    if (driverUUID != undefined) {
        urlApi = urlApi + "/driver/" + driverUUID
    } else if (transportUUID != undefined) {
        urlApi = urlApi + "/transport/" + transportUUID
    } else if (contractUUID != undefined) {
        urlApi = urlApi + "/contract/" + contractUUID
    } else if (partnerUUID != undefined) {
        urlApi = urlApi + "/partner/" + partnerUUID
    }

    const { data } = await api.get(urlApi, {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
    let transactionsData: TransactionModel[] = []
    let paginatorData = undefined

    const response = data as ResponseApi
    if (response.data.results != undefined) {
        transactionsData = response.data.results as TransactionModel[]
        paginatorData = response.data.paginator as FilterPaginatorApi
    } else {
        transactionsData = response.data as TransactionModel[]
    }

    return {
        transactions: transactionsData,
        paginator: paginatorData
    };
}

export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = [
            {
                field: "partners.social_name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "drivers.name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
        ]
    }

    return filter;
}