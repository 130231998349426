import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportInvoiceModel } from "../../models/TransportInvoiceModel";
import { api } from "../api";
import { serialize } from 'object-to-formdata';

export async function updateTransportInvoice(transportInvoiceData: TransportInvoiceModel, file_url: File): Promise<ResponseApi> {
    let response = {} as ResponseApi
    const formData = serialize(transportInvoiceData, { indices: true, nullsAsUndefineds: true })
    if (file_url != undefined) formData.append("file_url", file_url || "")

    await api.patch('transport_invoice/' + transportInvoiceData.uuid, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}