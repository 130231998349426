import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { FileModel } from "../../../models/FileModel";
import { FormActions } from "../../../components/Form/FormActions";
import { InputFile } from "../../../components/Form/InputFile";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    fileData?: FileModel
}

export function Form({ navigate, useFormReturn, fileData }: FormProps) {
    const [proofSelected, setProofSelected] = useState("");

    const { register, formState, setValue } = useFormReturn
    const { errors } = formState;

    useEffect(() => {
        if (fileData) {
            setValue("title", fileData?.title);
        }
    }, [fileData])

    return (
        <>
            <Input type="file" display={"none"}
                {...register("proof_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setProofSelected(e.target.files[0].name);
                        } else {
                            setProofSelected("");
                        }
                    },
                })}
            />

            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Titulo do arquivo"
                        error={errors.title}
                        {...register("title")}
                    />
                    <InputFile
                        label="Arquivo (Imagem ou PDF)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.proof}
                        value={proofSelected}
                        imageURL={fileData?.proof}
                        onClick={() => {
                            document.getElementById("proof_file")?.click();
                        }}
                        {...register("proof")}
                    />
                </SimpleGrid>
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}