import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexTransportContract } from "../../services/hooks/useIndexTransportContract";
import { TransportsNavigator } from "../../components/Form/Navigator/TransportsNavigator";
import { IndexTransportsContractsProps } from "../../services/transportContracts/indexTransportContract";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";

export function List() {
  const { parent, parent_uuid } = useParams();
  const indexProps: IndexTransportsContractsProps = {
    transportUUID: parent == "transports-general" ? parent_uuid : undefined,
    contractUUID: parent == "contracts-general" ? parent_uuid : undefined,
  }

  const { data, isFetching, refetch } = useIndexTransportContract(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"} overflow={"auto"}>

      {parent == "transports-general" ?
        <TransportsNavigator step={3} uuid={parent_uuid} /> :
        <ContractsNavigator step={4} uuid={parent_uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Carregamentos relacionados ao contrato"}
            createNew={
              parent == "transports-general" ? {
                to: "/transport_contracts/" + parent + "/" + parent_uuid + "/new",
                label: "Cadastrar novo",
              } : undefined
            }
            isLoading={isFetching}
            refetch={refetch}
          />

          {data?.transportContracts?.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable transportContracts={data?.transportContracts} refetch={refetch} parent={parent} parent_uuid={parent_uuid} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
