import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Button, Link as ChakraLink } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateDate, FormateQuilo, Toast } from "../../../utils/utils";
import { formatDistanceToNow, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { FileModel } from "../../../models/FileModel";
import { deleteFile } from "../../../services/files/deleteFile";
import { FilePrefix } from "../../../services/api";

interface ListTableProps {
  files?: FileModel[];
  parent: string;
  parent_uuid: string;
  refetch?: () => void;
}

export function ListTable({ files, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteFile(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
            <Th pl={"0"} pr={"0"}>Titulo</Th>
            <Th>Arquivo</Th>
            <Th>Data de cadastro</Th>
            <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {files?.map((file) => {
            return (
              <Tr key={file.uuid}>
                <Td pl={"0"} pr={"0"}>
                  <Text fontWeight="bold">{file.title.toUpperCase()}</Text>
                </Td>
                <Td>
                  <Button as={ChakraLink} href={FilePrefix + file.proof} isExternal colorScheme={"orange"} size={"sm"}>Baixar arquivo</Button>
                </Td>
                <Td title={formatDistanceToNow(new Date(file.created_at!), { addSuffix: true, locale: ptBR })}>
                  {FormateDate(file.created_at!, "DD/MM/YYYY")}
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {file.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={parent != undefined ? "/files/" + parent + "/" + parent_uuid + "/edit/" + file.uuid : "/files-general" + "/edit/" + file.uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(file.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table >
    </>
  );
}
