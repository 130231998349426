import { ResponseApi } from "../../models/Api/ResponseApi";
import { VehicleModel } from "../../models/VehicleModel";
import { api } from "../api";

export type GetVehiclesResponse = {
    vehicles: VehicleModel[];
}

export type IndexVehicleProps = {
    isFleet?: boolean,
    tractorUUID?: string,
    driverUUID?: string,
}

export async function indexVehicle({ tractorUUID, isFleet, driverUUID }: IndexVehicleProps): Promise<GetVehiclesResponse> {
    let urlApi = "vehicles/"
    if (isFleet != undefined && isFleet) {
        urlApi = urlApi + "fleet"
    } else if (tractorUUID != undefined) {
        urlApi = urlApi + "tractor/" + tractorUUID
    } else if (driverUUID != undefined) {
        urlApi = urlApi + "driver/" + driverUUID
    }
    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const vehiclesData = response.data as VehicleModel[]

    return {
        vehicles: vehiclesData
    };
}