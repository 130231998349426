import { useDisclosure, UseDisclosureReturn } from "@chakra-ui/react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


type TransportReportContextData = UseDisclosureReturn;

export const TransportReportContext = createContext({} as TransportReportContextData);

interface TransportReportContextProviderProps {
    children: ReactNode;
}

export function TransportReportContextProvider({ children, }: TransportReportContextProviderProps) {
    const disclosure = useDisclosure();
    const router = useLocation();

    useEffect(() => {
        disclosure.onClose();
    }, [router.pathname]);

    return (
        <TransportReportContext.Provider value={disclosure}>
            {children}
        </TransportReportContext.Provider>
    );
}

export const useTransportReport = () => useContext(TransportReportContext);
