import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportInvoiceModel } from "../../models/TransportInvoiceModel";
import { api } from "../api";

type GetTransportInvoicesResponse = {
    transportInvoices: TransportInvoiceModel[];
}

export async function indexTransportInvoice(transportUUID: string): Promise<GetTransportInvoicesResponse> {
    const { data } = await api.get('transport_invoices/transport/' + transportUUID)

    const response = data as ResponseApi
    const transportInvoicesData = response.data as TransportInvoiceModel[]

    return { transportInvoices: transportInvoicesData };
}