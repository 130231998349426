import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { DriverModel } from "../../../models/DriverModel";
import InputMask from "react-input-mask";
import { FormActions } from "../../../components/Form/FormActions";
import { format } from "date-fns";
import { OptionsProps, Select2 } from "../../../components/Form/Select";
import { InputFile } from "../../../components/Form/InputFile";
import { FormateDate } from "../../../utils/utils";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    driverData?: DriverModel
}

export function Form({ navigate, useFormReturn, driverData }: FormProps) {
    const [maskPhone, setMaskPhone] = useState("(99) 9999-99999")
    const [maskBeneficiaryDocument, setMaskBeneficiaryDocument] = useState("999.999.999-99")

    const { register, formState, setValue } = useFormReturn
    const { errors } = formState;

    useEffect(() => {
        if (driverData) {
            if (driverData?.date_birth != undefined) driverData.date_birth = FormateDate(driverData.date_birth!, "DD/MM/YYYY");
            if (driverData?.cnh_maturity != undefined) driverData.cnh_maturity = FormateDate(driverData.cnh_maturity!, "DD/MM/YYYY");

            setValue("name", driverData?.name);
            setValue("kind", driverData?.kind);
            setValue("surname", driverData?.surname);
            setValue("cpf", driverData?.cpf);
            setValue("name_father", driverData?.name_father);
            setValue("name_mother", driverData?.name_mother);
            setValue("register_number", driverData?.register_number);
            setValue("register_uf", driverData?.register_uf);
            setValue("register_emitter", driverData?.register_emitter);
            setValue("date_birth", driverData?.date_birth);
            setValue("cnh_register", driverData?.cnh_register);
            setValue("cnh_code", driverData?.cnh_code);
            setValue("cnh_maturity", driverData?.cnh_maturity);
            setValue("cnh_category", driverData?.cnh_category);
            setValue("cnh_uf", driverData?.cnh_uf);
            setValue("phone.number", driverData?.phone?.number);
            // setValue("address.address", driverData?.address?.address);
            // setValue("address.city", driverData?.address?.city);
            // setValue("address.district", driverData?.address?.district);
            // setValue("address.uf", driverData?.address?.uf);
            // setValue("address.postal_code", driverData?.address?.postal_code);
            // setValue("bank_account.account", driverData?.bank_account?.account);
            // setValue("bank_account.banch", driverData?.bank_account?.banch);
            // setValue("bank_account.bank", driverData?.bank_account?.bank);
            // setValue("bank_account.beneficiary_document", driverData?.bank_account?.beneficiary_document);
            // setValue("bank_account.beneficiary_name", driverData?.bank_account?.beneficiary_name);
            // setValue("bank_account.pix_key", driverData?.bank_account?.pix_key);
        }
    }, [driverData])

    const OptionsKind: OptionsProps[] = [
        { label: "TERCEIRO", value: "TRUCKER" },
        { label: "AGREGADO", value: "AGGREGATE" },
        { label: "FROTA", value: "EMPLOYEER" },
        { label: "ASSISTENTE", value: "ASSISTANT" },
    ]

    const [photoSelected, setPhotoSelected] = useState("");
    const [photoCnhSelected, setPhotoCnhSelected] = useState("");
    const [photoCnhBackSelected, setPhotoCnhBackSelected] = useState("");
    return (
        <>
            <Input type="file" display={"none"}
                {...register("photo_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setPhotoSelected(e.target.files[0].name);
                        } else {
                            setPhotoSelected("");
                        }
                    },
                })}
            />

            <Input type="file" display={"none"}
                {...register("photo_cnh_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setPhotoCnhSelected(e.target.files[0].name);
                        } else {
                            setPhotoCnhSelected("");
                        }
                    },
                })}
            />

            <Input type="file" display={"none"}
                {...register("photo_cnh_back_file", {
                    onChange: (e) => {
                        if (e.target.files[0] !== undefined) {
                            setPhotoCnhBackSelected(e.target.files[0].name);
                        } else {
                            setPhotoCnhBackSelected("");
                        }
                    },
                })}
            />

            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Select2
                        label={"Tipo de Cadastro "}
                        options={OptionsKind}
                        error={errors.kind}
                        name={"kind"}
                        useFormReturn={useFormReturn}
                        selectedValue={driverData?.kind}                        
                    />
                    <Input
                        label="Nome completo"
                        error={errors.name}
                        {...register("name")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Apelido QRA."
                        error={errors.surname}
                        {...register("surname")}
                    />
                    <Input
                        label="Telefone"
                        as={InputMask}
                        mask={maskPhone}
                        maskChar={''}
                        defaultValue={driverData?.phone?.number}
                        error={errors.phone?.number}
                        {...register("phone.number", {
                            onChange: (e) => {
                                const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                                if (clearValue.length <= 10) {
                                    setMaskPhone("(99) 9999-99999")
                                } else {
                                    setMaskPhone("(99) 9 9999-9999")
                                }
                            },
                        })}
                    />
                </SimpleGrid>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        as={InputMask}
                        label="CPF"
                        mask={"999.999.999-99"}
                        maskChar={''}
                        defaultValue={driverData?.cpf}
                        error={errors.cpf}
                        {...register("cpf")}
                    />

                    <InputFile
                        label="Selfie do Motorista"
                        placeholder={"Selecione uma imagem"}
                        error={errors.photo}
                        value={photoSelected}
                        imageURL={driverData?.photo}
                        onClick={() => {
                            document.getElementById("photo_file")?.click();
                        }}
                        {...register("photo")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <InputFile
                        label="Arquivo CNH (Imagem ou PDF)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.photo_cnh}
                        value={photoCnhSelected}
                        imageURL={driverData?.photo_cnh}
                        onClick={() => {
                            document.getElementById("photo_cnh_file")?.click();
                        }}
                        {...register("photo_cnh")}
                    />

                    <InputFile
                        label="CNH verso (Opcional)"
                        placeholder={"Selecione uma imagem"}
                        error={errors.photo_cnh_back}
                        value={photoCnhBackSelected}
                        imageURL={driverData?.photo_cnh_back}
                        onClick={() => {
                            document.getElementById("photo_cnh_back_file")?.click();
                        }}
                        {...register("photo_cnh_back")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Nome completo da Mãe"
                        error={errors.name_mother}
                        {...register("name_mother")}
                    />
                    <Input
                        label="Nome completo do Pai (Opcional)"
                        error={errors.name_father}
                        {...register("name_father")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Nº de Identidade"
                        error={errors.register_number}
                        {...register("register_number")}
                    />
                    <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                        <Input
                            label="Orgão Emissor"
                            error={errors.register_emitter}
                            {...register("register_emitter")}
                        />
                        <Input
                            as={InputMask}
                            mask={"aa"}
                            maskChar={''}
                            defaultValue={driverData?.register_uf}
                            label="RG - UF"
                            error={errors.register_uf}
                            {...register("register_uf")}
                        />
                    </SimpleGrid>

                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Nº de registro CNH"
                        error={errors.cnh_register}
                        {...register("cnh_register")}
                    />
                    <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                        <Input
                            as={InputMask}
                            mask={"aa"}
                            maskChar={''}
                            defaultValue={driverData?.cnh_category}
                            label="Categoria"
                            error={errors.cnh_category}
                            {...register("cnh_category")}
                        />
                        <Input
                            as={InputMask}
                            mask={"aa"}
                            maskChar={''}
                            label="CNH - UF"
                            defaultValue={driverData?.cnh_uf}
                            error={errors.cnh_uf}
                            {...register("cnh_uf")}
                        />
                    </SimpleGrid>
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Cód. Segurança CNH"
                        error={errors.cnh_code}
                        {...register("cnh_code")}
                    />
                    <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                        <Input
                            as={InputMask}
                            mask={"99/99/9999"}
                            maskChar={''}
                            label="Validade CNH"
                            defaultValue={driverData?.cnh_maturity}
                            error={errors.cnh_maturity}
                            {...register("cnh_maturity")}
                        />
                        <Input
                            as={InputMask}
                            mask={"99/99/9999"}
                            maskChar={''}
                            defaultValue={driverData?.date_birth}
                            label="Data de Nascimento"
                            error={errors.date_birth}
                            {...register("date_birth")}
                        />
                    </SimpleGrid>
                </SimpleGrid>


                {/* <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Endereço completo"
                        error={errors.address?.address}
                        {...register("address.address")}
                    />
                    <Input
                        label="Bairro"
                        error={errors.address?.district}
                        {...register("address.district")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Cidade"
                        error={errors.address?.city}
                        {...register("address.city")}
                    />
                    <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                        <Input
                            as={InputMask}
                            label="UF"
                            defaultValue={driverData?.address?.uf}
                            error={errors.address?.uf}
                            maskChar={''}
                            mask={"aa"}
                            {...register("address.uf")}
                        />
                        <Input
                            as={InputMask}
                            label="CEP"
                            maskChar={''}
                            mask={"99999-999"}
                            defaultValue={driverData?.address?.postal_code}
                            error={errors.address?.postal_code}
                            {...register("address.postal_code")}
                        />
                    </SimpleGrid>
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Banco"
                        error={errors.bank_account?.bank}
                        {...register("bank_account.bank")}
                    />
                    <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                        <Input
                            label="Agência"
                            error={errors.bank_account?.banch}
                            {...register("bank_account.banch")}
                        />
                        <Input
                            label="Conta"
                            error={errors.bank_account?.account}
                            {...register("bank_account.account")}
                        />
                    </SimpleGrid>
                </SimpleGrid>
                <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                    <Input
                        label="Chave PIX"
                        error={errors.bank_account?.pix_key}
                        {...register("bank_account.pix_key")}
                    />
                </SimpleGrid>
                <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                    <Input
                        label="Beneficiário Nome"
                        error={errors.bank_account?.beneficiary_name}
                        {...register("bank_account.beneficiary_name")}
                    />
                    <Input
                        as={InputMask}
                        label="Beneficiário CPF ou CNPJ"
                        mask={maskBeneficiaryDocument}
                        maskChar={''}
                        defaultValue={driverData?.bank_account?.beneficiary_document}
                        error={errors.bank_account?.beneficiary_document}
                        {...register("bank_account.beneficiary_document", {
                            onChange: (e) => {
                                const clearValue = e.target.value.replace(/[^0-9]+/g, '');
                                if (clearValue.length <= 11) {
                                    setMaskBeneficiaryDocument("999.999.999-999")
                                } else {
                                    setMaskBeneficiaryDocument("99.999.999/9999-99")
                                }
                            },
                        })}
                    />
                </SimpleGrid> */}
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}