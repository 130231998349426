import { Button, Flex, Icon, SimpleGrid, Stack } from "@chakra-ui/react";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { createTransportInvoice } from "../../services/transportInvoices/createTransportInvoice";
import { ClearMoney, FormateMoney, FormateMoneyFloat, FormateQuilo, Toast } from "../../utils/utils";
import { useCallback, useEffect, useState } from "react";
import { InputFile } from "../../components/Form/InputFile";
import { TransportsNavigator } from "../../components/Form/Navigator/TransportsNavigator";
import { TransportInvoiceModel } from "../../models/TransportInvoiceModel";
import { showTransportInvoice } from "../../services/transportInvoices/showTransportInvoice";
import { updateTransportInvoice } from "../../services/transportInvoices/updateTransportInvoice";

const formShema = yup.object().shape({
  company: yup.string().required("Obrigatório"),
  identifier: yup.string().required("Obrigatório"),
  total_amount: yup.string().required("Obrigatório"),
  total_weight: yup.string().required("Obrigatório"),
  total_tax: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { transport_uuid, uuid } = useParams();
  const navigate = useNavigate();

  const [transportInvoiceData, setTransportInvoiceData] = useState<TransportInvoiceModel>();
  const getTransportInvoiceData = async () => {
    const response = await showTransportInvoice(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/transports-general/invoices/" + transport_uuid);
      return;
    }
    if (response?.data) {
      setTransportInvoiceData(response.data)
    }
  }

  useEffect(() => {
    getTransportInvoiceData();
  }, [])

  useEffect(() => {
    if (transportInvoiceData) {
      setValue("company", transportInvoiceData?.company);
      setValue("identifier", transportInvoiceData?.identifier);
      setValue("total_amount", FormateMoneyFloat(transportInvoiceData?.total_amount?.toString() ?? ""));
      setValue("total_tax", FormateMoneyFloat(transportInvoiceData?.total_tax?.toString() ?? ""));
      setValue("total_weight", FormateQuilo(transportInvoiceData?.total_weight.toString()));
    }
  }, [transportInvoiceData])


  const methods = useForm({ resolver: yupResolver(formShema) });
  const { register, handleSubmit, setError, formState, reset, setValue } = methods;
  const { errors } = formState;

  const handleFormSubmit: SubmitHandler<FieldValues | TransportInvoiceModel> = async (values) => {
    const file_url = values.file_url_file[0] as File;

    const transportInvoiceData = {
      uuid: uuid,
      transport_uuid: transport_uuid,
      company: values.company.toUpperCase(),
      identifier: values.identifier ? parseFloat(values.identifier.replace(".", "")) : 1,
      total_amount: values.total_amount ? parseFloat(ClearMoney(values.total_amount)) : undefined,
      total_weight: values.total_weight ? parseFloat(values.total_weight.replace(".", "")) : undefined,
      total_tax: values.total_tax ? parseFloat(ClearMoney(values.total_tax)) : undefined,
    } as TransportInvoiceModel;

    const response = await updateTransportInvoice(transportInvoiceData, file_url);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/transports-general/invoices/" + transport_uuid);
    }
  };

  const [file_urlSelected, setProofSelected] = useState("");

  const handleMaskMoney = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.currentTarget.value;
      value = FormateMoney(value)
      e.currentTarget.value = value;
    }, [])

  const handleMaskQuilo = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.currentTarget.value;
      value = FormateQuilo(value)
      e.currentTarget.value = value;
    }, [])
  return (
    <Stack w={"100%"} spacing={"0"}>

      <TransportsNavigator step={2} uuid={transport_uuid} /> :

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>

          <Input type="file" display={"none"}
            {...register("file_url_file", {
              onChange: (e) => {
                if (e.target.files[0] !== undefined) {
                  setProofSelected(e.target.files[0].name);
                } else {
                  setProofSelected("");
                }
              },
            })}
          />


          <ContainerHeader title={"Editar dados da Nota Fiscal"} />
          <Stack direction={"column"} spacing={"4"}>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
              <Input
                label="Firma"
                error={errors.company}
                {...register("company")}
              />
            </SimpleGrid>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
              <Input
                label="Nº nota fiscal"
                error={errors.identifier}
                onKeyUp={handleMaskQuilo}
                {...register("identifier")}
              />

              <Input
                label="Quantidade (Quilos)"
                error={errors.total_weight}
                onKeyUp={handleMaskQuilo}
                {...register("total_weight")}
              />
            </SimpleGrid>

            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
              <Input
                label="Valor NF"
                error={errors.total_amount}
                onKeyUp={handleMaskMoney}
                {...register("total_amount")}
              />

              <Input
                label="Valor Imposto"
                error={errors.total_tax}
                onKeyUp={handleMaskMoney}
                {...register("total_tax")}
              />
            </SimpleGrid>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
              <InputFile
                label="Arquivo NF (Imagem ou PDF)"
                placeholder={"Selecione uma imagem"}
                error={errors.file_url}
                value={file_urlSelected}
                onClick={() => {
                  document.getElementById("file_url_file")?.click();
                }}
                {...register("file_url")}
              />
            </SimpleGrid>
          </Stack>

          <Flex mt={"8"} justify={"flex-end"}>
            <Stack direction={"row"} spacing={"4"}>
              <Button onClick={() => { navigate(-1) }} colorScheme={"orange"} size={"lg"} leftIcon={<Icon as={RiCloseCircleLine} fontSize={"20"} />}>
                Cancelar
              </Button>
              <Button type={"submit"} colorScheme={"teal"} size={"lg"} leftIcon={<Icon as={RiCheckboxCircleLine} fontSize={"20"} />} isLoading={formState.isSubmitting}>
                Salvar
              </Button>
            </Stack>
          </Flex>
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
