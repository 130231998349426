import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransactionModel } from "../../models/TransactionModel";
import { api } from "../api";
import { serialize } from 'object-to-formdata';

export async function createTransaction(transactionData: TransactionModel, proof: File): Promise<ResponseApi> {
    let response = {} as ResponseApi
    const formData = serialize(transactionData, { indices: true, nullsAsUndefineds: true })
    if (proof != undefined) formData.append("proof", proof || "")

    await api.post("transactions", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}