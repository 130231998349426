import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { VehicleModel } from "../../models/VehicleModel";
import { FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { showVehicle } from "../../services/vehicles/showVehicle";
import { updateVehicle } from "../../services/vehicles/updateVehicle";
import { FleetsNavigator } from "../../components/Form/Navigator/FleetsNavigator";
import { Form } from "../vehicles/components/Form";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";

const formShema = yup.object().shape({
  plate: yup.string().required("Obrigatório"),
  renavam: yup.string().required("Obrigatório"),
  rntrc: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { uuid, parent_uuid, parent } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [vehicleData, setVehicleData] = useState<VehicleModel>();
  const getVehicleData = async () => {
    const response = await showVehicle(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/vehicles/" + parent + "/" + parent_uuid);
      return;
    }
    if (response?.data) {
      setVehicleData(response.data)
    }
  }
  useEffect(() => {
    getVehicleData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | VehicleModel> = async (values) => {
    const photoCrvl = values.photo_crlv_file[0] as File;
    const photoVehicle = values.photo_vehicle_file[0] as File;

    const data = JSON.parse(FormToJson(values)) as VehicleModel
    data.uuid = uuid!
    data.driver_uuid = (parent == "drivers" ? parent_uuid : undefined)
    data.tractor_uuid = (parent == "fleet" ? parent_uuid : undefined)
    data.kind = vehicleData?.kind ?? ""

    const response = await updateVehicle(data, photoCrvl, photoVehicle);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      (parent == "fleet")

      navigate("/vehicles/" + parent + (parent == "fleet" ? "" : "/" + parent_uuid));
    }
  };
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={5} uuid={parent_uuid} /> :
        <FleetsNavigator step={1} uuid={vehicleData?.uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Veículo " + (vehicleData?.kind == "TRACTOR" ? "(Trator)" : "(Reboque)")} />
          <Form navigate={navigate} useFormReturn={useFormReturn} vehicleData={vehicleData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
