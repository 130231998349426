import { Collapse, Flex, Heading, IconButton, Icon, Stack, SimpleGrid, Button } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import { DefaultBox } from "../DefaultBox/DefaultBox";
import { Input } from "../Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTransportReport } from "../../contexts/TransportReportContext";
import InputMask from "react-input-mask";
import { reportTransportContract } from "../../services/transportContracts/reportTransportContract";
import { FormateDateToBack, Toast } from "../../utils/utils";
import { TransportReport } from "../../Reports/Transports/TransportReport";
import { TransportModel } from "../../models/TransportModel";

const formShema = yup.object().shape({
  start: yup.string().required("Obrigatório"),
  end: yup.string().required("Obrigatório"),
});

export function TransportReportList() {
  const { onClose, isOpen, onToggle } = useTransportReport();

  const { register, handleSubmit, formState, reset, setValue, setError } = useForm({
    resolver: yupResolver(formShema),
  });
  const { errors } = formState;

  const handleFormSubmit: SubmitHandler<FieldValues> = async (values) => {
    const start = FormateDateToBack(values.start!, "YYYY-MM-DD")
    const end = FormateDateToBack(values.end!, "YYYY-MM-DD")
    const response = await reportTransportContract(start, end);
    Toast({ response, setError })

    if (response?.httpStatus == 200) {
      TransportReport(response.data as TransportModel[], values.start, values.end)
    }
  };

  function clearForm() {
    reset()
    setValue("start", "");
    setValue("end", "");
    onClose()
  }

  return (
    <Collapse in={isOpen} animateOpacity>
      <Flex w={"100%"} maxWidth={1480} mb={"6"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)} >
          <Flex mb={"8"} justify={"space-between"} align="center">
            <Heading size={"md"} fontWeight={"normal"}>
              Utilize os campos abaixo para montar o relatório
            </Heading>
            <IconButton aria-label={"Fechar"} size={"sm"} fontSize={"sm"} variant={"outline"} colorScheme={"gray"} icon={<Icon as={RiCloseLine} fontSize={"20"} />} onClick={onToggle} />
          </Flex>
          <Stack direction={"row"} spacing={4}>
            <SimpleGrid minChildWidth={"170px"} spacing={["6", "8"]} w={"100%"}>
              <Input as={InputMask}
                mask={"99/99/9999"}
                maskChar={''}
                {...register("start")}
                label="Data Inicial"
                error={errors.start}
                type="text"
                placeholder="Data Inicial" />
            </SimpleGrid>
            <SimpleGrid minChildWidth={"170px"} spacing={["6", "8"]} w={"100%"}>
              <Input as={InputMask}
                mask={"99/99/9999"}
                maskChar={''}
                {...register("end")}
                label="Data Final"
                error={errors.end}
                type="text"
                placeholder="Data Final" />
            </SimpleGrid>
          </Stack>

          <SimpleGrid minChildWidth={"170px"} spacing={["6", "8"]} w={"100%"}>
            <Button mt={"8"} w={"100%"} colorScheme={"blue"} size={"lg"} variant={"outline"} onClick={clearForm}>
              Limpar parametros
            </Button>
            <Button mt={"8"} w={"100%"} colorScheme={"orange"} size={"lg"} variant={"outline"} type={"submit"} >
              Gerar relatório
            </Button>
          </SimpleGrid>
        </DefaultBox>
      </Flex>
    </Collapse>
  );
}
