import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { ProductModel } from "../../models/ProductModel";
import { updateProduct } from "../../services/products/updateProduct";
import { FormToJson, Toast } from "../../utils/utils";
import { showProduct } from "../../services/products/showProduct";
import { Form } from "./components/Form";

const formShema = yup.object().shape({
  name: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [productData, setProductData] = useState<ProductModel>();
  const getProductData = async () => {
    const response = await showProduct(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate("/products");
      return;
    }
    if (response?.data) {
      setProductData(response.data)
    }
  }
  useEffect(() => {
    getProductData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | ProductModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as ProductModel
    data.uuid = uuid!

    const response = await updateProduct(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/products");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>
      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar produto"} />
          <Form navigate={navigate} useFormReturn={useFormReturn} productData={productData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
