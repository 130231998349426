import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportModel } from "../../models/TransportModel";
import { api } from "../api";
import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";

type GetTransportsResponse = {
    transports: TransportModel[];
    paginator?: FilterPaginatorApi;
}

export type IndexTransportProps = {
    driverUUID?: string,
    vehicleUUID?: string,
    contractUUID?: string,
    paginator?: FilterPaginatorApi
}

export async function indexTransport({ driverUUID, vehicleUUID, contractUUID, paginator }: IndexTransportProps): Promise<GetTransportsResponse> {
    let urlApi = "transports"
    if (driverUUID != undefined) {
        urlApi = urlApi + "/driver/" + driverUUID
    } else if (vehicleUUID != undefined) {
        urlApi = urlApi + "/vehicle/" + vehicleUUID
    } else if (contractUUID != undefined) {
        urlApi = urlApi + "/contract/" + contractUUID
    }

    const { data } = await api.get(urlApi, {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
    let transportsData: TransportModel[] = []
    let paginatorData = undefined

    const response = data as ResponseApi
    if (response.data.results != undefined) {
        transportsData = response.data.results as TransportModel[]
        paginatorData = response.data.paginator as FilterPaginatorApi
    } else {
        transportsData = response.data as TransportModel[]
    }

    return {
        transports: transportsData,
        paginator: paginatorData
    };
}

export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = [
            {
                field: "products.name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "drivers.name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "vehicles.plate",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "companies.social_name",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
        ]
    }

    return filter;
}