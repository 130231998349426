import { Button, Flex, FormControl, FormLabel, Stack, Switch, Text, useColorMode } from "@chakra-ui/react";
import { Input } from "../components/Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Logo } from "../components/Header/Logo";
import { Toast } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { destroyCookie } from "nookies"


const signInFormShema = yup.object().shape({
  email: yup.string().email("Formato inválido").required("Obrigatório"),
  password: yup.string().required("Obrigatório"),
});

export default function SignIn() {
  destroyCookie(undefined, "Multigrãos.token");
  destroyCookie(undefined, "Multigrãos.refresh_token");

  const { singIn } = useAuth();

  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const { register, handleSubmit, setError, formState } = useForm({
    resolver: yupResolver(signInFormShema),
  });
  const { errors } = formState;

  const handleSignIn: SubmitHandler<FieldValues> = async (values) => {
    const response = await singIn({
      email: values.email.toUpperCase(),
      password: values.password.toUpperCase(),
    });
    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate("/dashboard");
      Toast({ response, setError });
    } else {
      Toast({ response, setError, description: "E-mail ou senha inválidos" });
    }
  };

  return (
    <Flex overflow={"auto"} w={"100vw"} h={"100vh"} align={"center"} direction={"column"} justify={"center"} bg={colorMode === "dark" ? "gray.900" : "gray.50"} px={"6"}>
      <Flex
        as={"form"}
        w={"100%"}
        maxWidth={460}
        bg={colorMode === "dark" ? "gray.800" : "white"}
        p={8}
        borderRadius={8}
        direction={"column"}
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Stack spacing={4}>
          <Flex mb={"6"}>
            <Logo />
          </Flex>
          <Input
            label="Email"
            type="email"
            error={errors.email}
            {...register("email")}
          />
          <Input
            label="Senha"
            type="password"
            error={errors.password}
            {...register("password")}
          />
        </Stack>

        <Button
          type={"submit"}
          mt={6}
          colorScheme={"teal"}
          size={"lg"}
          isLoading={formState.isSubmitting}
        >
          Login
        </Button>

        <FormControl display="flex" alignItems="center" mt={"6"}>
          <FormLabel htmlFor="dark_mode" mb="0">
            Ativar modo escuro?
          </FormLabel>
          <Switch
            id="dark_mode"
            colorScheme="teal"
            size="lg"
            isChecked={colorMode === "dark"}
            onChange={toggleColorMode}
          />
        </FormControl>
      </Flex>
      <Text size={"sm"} color={"gray.300"} mt={"4"}>0.0.15</Text>
    </Flex>
  );
}
