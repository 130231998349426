import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransportContractModel } from "../../models/TransportContractModel";
import { api } from "../api";

export async function updateTransportContract(transportContractData: TransportContractModel): Promise<ResponseApi> {
    let urlApi = 'transport_contract/' + transportContractData.uuid

    let response = {} as ResponseApi
    await api.patch(urlApi, transportContractData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}