import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { FileModel } from "../../models/FileModel";
import { api } from "../api";
import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";

type GetFilesResponse = {
    files: FileModel[];
    paginator?: FilterPaginatorApi;
}

export type IndexFileProps = {
    driverUUID?: string,
    contractUUID?: string,
    paginator?: FilterPaginatorApi
}

export async function indexFile({ driverUUID, contractUUID, paginator }: IndexFileProps): Promise<GetFilesResponse> {
    let urlApi = "files"
    if (driverUUID != undefined) {
        urlApi = urlApi + "/driver/" + driverUUID
    } else if (contractUUID != undefined) {
        urlApi = urlApi + "/contract/" + contractUUID
    }

    const { data } = await api.get(urlApi, {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
    let filesData: FileModel[] = []
    let paginatorData = undefined

    const response = data as ResponseApi
    if (response.data.results != undefined) {
        filesData = response.data.results as FileModel[]
        paginatorData = response.data.paginator as FilterPaginatorApi
    } else {
        filesData = response.data as FileModel[]
    }

    return {
        files: filesData,
        paginator: paginatorData
    };
}

export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = []//TODO
    }

    return filter;
}