import { FilterPaginatorApi } from "../../models/Api/FilterPaginatorApi";
import { ResponseApi } from "../../models/Api/ResponseApi";
import { MaintenanceModel } from "../../models/MaintenanceModel";
import { api } from "../api";
import qs from "qs";
import { FilterData } from "../../contexts/FilterContext";

type GetMaintenancesResponse = {
    maintenances: MaintenanceModel[];
    paginator?: FilterPaginatorApi;
}

export type IndexMaintenanceProps = {
    vehicleUUID?: string,
    paginator?: FilterPaginatorApi
}

export async function indexMaintenance({ vehicleUUID, paginator }: IndexMaintenanceProps): Promise<GetMaintenancesResponse> {
    let urlApi = "maintenances"
    if (vehicleUUID != undefined) {
        urlApi = urlApi + "/vehicle/" + vehicleUUID
    }

    const { data } = await api.get(urlApi, {
        params: paginator,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
    let maintenancesData: MaintenanceModel[] = []
    let paginatorData = undefined

    const response = data as ResponseApi
    if (response.data.results != undefined) {
        maintenancesData = response.data.results as MaintenanceModel[]
        paginatorData = response.data.paginator as FilterPaginatorApi
    } else {
        maintenancesData = response.data as MaintenanceModel[]
    }

    return {
        maintenances: maintenancesData,
        paginator: paginatorData
    };
}

export function makeFilters(filterData?: FilterData): FilterPaginatorApi {
    const filter = {
        details: {
            limit: 20,
            page: filterData?.page ?? 1
        },
    } as FilterPaginatorApi;

    if (filterData?.search) {
        filter.filters = [
            {
                field: "vehicles.plate",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
            {
                field: "tractor.plate",
                value: filterData?.search || "",
                comparison_operators: "ILIKE",
                logical_operator: "OR",
            },
        ]
    }

    return filter;
}