import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction } from "react-router-dom";
import { AddressModel } from "../../../models/AddressModel";
import InputMask from "react-input-mask";
import { FormActions } from "../../../components/Form/FormActions";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    addressData?: AddressModel
}

export function Form({ navigate, useFormReturn, addressData }: FormProps) {
    const { register, formState, setValue } = useFormReturn
    const { errors } = formState;

    useEffect(() => {
        if (addressData) {
            setValue("address", addressData?.address);
            setValue("district", addressData?.district);
            setValue("city", addressData?.city);
            setValue("uf", addressData?.uf);
            setValue("postal_code", addressData?.postal_code);
        }
    }, [addressData])

    return (
        <>
            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Endereço completo"
                        error={errors.address}
                        {...register("address")}
                    />
                    <Input
                        label="Bairro"
                        error={errors.district}
                        {...register("district")}
                    />
                </SimpleGrid>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Cidade"
                        error={errors.city}
                        {...register("city")}
                    />
                    <SimpleGrid minChildWidth={"120px"} spacing={"4"}>
                        <Input
                            as={InputMask}
                            label="UF"
                            error={errors.uf}
                            maskChar={''}
                            defaultValue={addressData?.uf}
                            mask={"aa"}
                            {...register("uf")}
                        />
                        <Input
                            as={InputMask}
                            label="CEP"
                            maskChar={''}
                            defaultValue={addressData?.postal_code}
                            mask={"99999-999"}
                            error={errors.postal_code}
                            {...register("postal_code")}
                        />
                    </SimpleGrid>
                </SimpleGrid>
            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}