import { ResponseApi } from "../../models/Api/ResponseApi";
import { TransactionDataToCreateModel } from "../../models/TransactionModel";
import { api } from "../api";

export type ShowTransactionDataToCreateProps = {
    driverUUID?: string,
    partnerUUID?: string,
}

export async function showTransactionDataToCreate({ driverUUID, partnerUUID }: ShowTransactionDataToCreateProps): Promise<TransactionDataToCreateModel> {
    let urlApi = "transactions/data-to-create"

    var formData = new FormData();
    let response = {} as TransactionDataToCreateModel

    if (driverUUID != undefined) { formData.append("driver_uuid", driverUUID || ""); }
    if (partnerUUID != undefined) { formData.append("partner_uuid", partnerUUID || ""); }

    await api.post(urlApi, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then((result) => {
        response = result.data.data as TransactionDataToCreateModel;
    }).catch((error) => {
        response = error.response.data.data as TransactionDataToCreateModel;
    })

    return response
}