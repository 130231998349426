import { ResponseApi } from "../../models/Api/ResponseApi";
import { PhoneModel } from "../../models/PhoneModel";
import { api } from "../api";

type GetPhonesResponse = {
    phones: PhoneModel[];
}

export type IndexPhoneProps = {
    companyUUID?: string,
    partnerUUID?: string,
    driverUUID?: string,
}

export async function indexPhone({ partnerUUID, companyUUID, driverUUID }: IndexPhoneProps): Promise<GetPhonesResponse> {
    let urlApi = "phones/"
    if (companyUUID != undefined) {
        urlApi = urlApi + "company/" + companyUUID
    } else if (partnerUUID != undefined) {
        urlApi = urlApi + "partner/" + partnerUUID
    } else if (driverUUID != undefined) {
        urlApi = urlApi + "driver/" + driverUUID
    }
    const { data } = await api.get(urlApi)

    const response = data as ResponseApi
    const phonesData = response.data as PhoneModel[]

    return {
        phones: phonesData
    };
}