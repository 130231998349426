import { Text, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export function Logo() {
  const { colorMode } = useColorMode();

  return (
    <Text
      as={Link}
      to={"/dashboard"}
      fontSize={"3xl"}
      fontWeight={"bold"}
      letterSpacing={"tight"}
      w={"64"}
      color={colorMode === "dark" ? "gray.50" : "gray.600"}
    >
      Multigrãos
      <Text as={"span"} fontSize={"1.5xl"} ml={"1"} color={"teal.500"}>
        .
      </Text>
    </Text>
  );
}
