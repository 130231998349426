import { Flex, Heading, Icon, Stack } from "@chakra-ui/react";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ListTable } from "./components/ListTable";
import { RiSearchEyeLine } from "react-icons/ri";
import { ConfirmDeleteContextProvider } from "../../contexts/ConfirmDeleteContext";
import { useParams } from "react-router-dom";
import { useIndexFile } from "../../services/hooks/useIndexFile";
import { IndexFileProps, makeFilters } from "../../services/files/indexFile";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { useFilter } from "../../contexts/FilterContext";
import { SearchList } from "../../components/ContainerSearch/ContainerSearch";
import { Pagination } from "../../components/Pagination/Pagination";

export function List() {
  const { parent_uuid, parent } = useParams();
  const { filterData } = useFilter();
  const hasPaginator = (parent == "vehicles" || parent == "drivers" || parent == "contracts-general")

  const indexProps: IndexFileProps = {
    driverUUID: parent == "drivers" ? parent_uuid : undefined,
    contractUUID: parent == "contracts-general" ? parent_uuid : undefined,
    paginator: !hasPaginator ? makeFilters(filterData) : undefined,
  }
  const { data, isFetching, refetch } = useIndexFile(indexProps);
  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={8} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={3} uuid={parent_uuid} /> :
          <></>
      }

      <SearchList />

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox overflow={"hidden"}>
          <ContainerHeader
            title={"Arquivos"}
            createNew={{
              to: parent != undefined ? "/files/" + parent + "/" + parent_uuid + "/new" : "/files-general/new",
              label: "Cadastrar novo",
            }}
            isLoading={isFetching}
            refetch={refetch}
            search={!hasPaginator}
          />

          {data?.files.length || 0 > 0 ? (
            <Stack overflow={"auto"}>
              <ConfirmDeleteContextProvider>
                <ListTable files={data?.files} refetch={refetch} parent={parent!} parent_uuid={parent_uuid!} />
              </ConfirmDeleteContextProvider>
            </Stack>
          ) : (
            <Stack spacing={4} justify={"center"} align={"center"}>
              <Icon as={RiSearchEyeLine} boxSize={"50px"} color={"orange.300"} />
              <Heading as="h3" size="sm" mt={6} mb={2}>
                Ooops, nenhum registro localizado.
              </Heading>
            </Stack>
          )}

          <Pagination
            limit={data?.paginator?.details.limit}
            page={data?.paginator?.details.page}
            results={data?.files?.length}
          />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
