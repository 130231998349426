import { ResponseApi } from "../../models/Api/ResponseApi";
import { PartnerModel } from "../../models/PartnerModel";
import { api } from "../api";

export async function createPartner(partnerData: PartnerModel): Promise<ResponseApi> {

    let response = {} as ResponseApi
    await api.post('partners', partnerData).then((result) => {
        response = result.data as ResponseApi;
        response.httpStatus = result.status;
    }).catch((error) => {
        response = error.response.data as ResponseApi;
        response.httpStatus = error.response.status;
    })
    return response
}