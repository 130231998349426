import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SelectProps as ChakraSelectProps,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { Select as RSelect } from "chakra-react-select";

export interface OptionsProps {
  label: string;
  value: any;
  isSelected?: boolean;
}

interface SelectProps extends ChakraSelectProps {
  name: string;
  label?: string;
  error?: any;
  reloaded?: boolean;
  options: OptionsProps[];
  selectedValue?: any;
  useFormReturn: UseFormReturn<FieldValues, any>
  onChangeSelect?: (value: any) => void;
}

export function Select2({ name, label, error = null, options, useFormReturn, selectedValue, onChangeSelect, reloaded }: SelectProps) {
  const { setValue, getValues } = useFormReturn
  const [selectedOption, setSelectedOption] = useState<OptionsProps>();

  if (name == "company_uuid") {
    console.log("Render Select2")
  }

  useEffect(() => {
    if (selectedValue != undefined && selectedValue !== "") {
      let index = 0
      options.map((option, i) => {
        if (option.value === selectedValue) index = i
      })
      if (options[index] != undefined && options[index].value !== undefined && options[index].value !== "") {
        setValue(name, options[index].value)
        setSelectedOption(options[index])
      }
    }
  }, [selectedValue, options])

  if (reloaded) {
    useEffect(() => {
      setTimeout(() => {
        const selected = getValues(name) != undefined ? getValues(name) : (selectedValue != undefined ? selectedValue : undefined)

        if (selectedOption == undefined && selected !== "" && options.length > 0) {
          let index = 0
          options.map((option, i) => {
            if (option.value === selected) index = i
          })

          if (options[index].value !== undefined && options[index].value !== "") {
            setValue(name, options[index].value)
            setSelectedOption(options[index])
          }
        } else if (selectedOption != undefined && options.length > 0) {
          let index = 0
          options.map((option, i) => {
            if (option.value === selectedOption?.value) {
              index = i
            }
          })
          if (options[index].value !== undefined) {
            setSelectedOption(options[index])
            setValue(name, options[index].value)
          }
        }
      }, 200)
    }, [options, selectedOption])
  }


  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={useFormReturn.control}
        name={name}
        render={({ field: { onChange, ref } }) => (
          <RSelect
            ref={ref}
            options={options}
            size={"lg"}
            variant={"filled"}
            value={selectedOption}
            onChange={val => {
              setSelectedOption(options.find(c => c.value === val?.value))
              onChange(val?.value)
              if (onChangeSelect != undefined) onChangeSelect(val?.value)
            }}
          />
        )}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
}