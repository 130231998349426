import { Collapse, Flex, Heading, IconButton, Icon, Stack, SimpleGrid, Button } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import { DefaultBox } from "../DefaultBox/DefaultBox";
import { Input } from "../Form/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFilter } from "../../contexts/FilterContext";

const formShema = yup.object().shape({
  terms: yup.string().required("Obrigatório"),
});

export function SearchList() {
  const { disclosure, updateFilterData } = useFilter();

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(formShema),
  });
  const { errors } = formState;

  const handleFormSubmit: SubmitHandler<FieldValues> = async (values) => {
    updateFilterData({
      page: 1,
      search: values.terms,
    })
  };

  function clearForm() {
    reset()
    disclosure.onClose();
    updateFilterData({
      page: 1,
      search: undefined,
    })
  }

  return (
    <Collapse in={disclosure.isOpen} animateOpacity>
      <Flex w={"100%"} maxWidth={1480} mb={"6"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)} >
          <Flex mb={"8"} justify={"space-between"} align="center">
            <Heading size={"md"} fontWeight={"normal"}>
              Utilize os campos abaixo para filtrar resultados
            </Heading>
            <IconButton aria-label={"Fechar"} size={"sm"} fontSize={"sm"} variant={"outline"} colorScheme={"gray"} icon={<Icon as={RiCloseLine} fontSize={"20"} />} onClick={disclosure.onToggle} />
          </Flex>
          <Stack direction={"column"} spacing={4}>
            <SimpleGrid minChildWidth={"170px"} spacing={["6", "8"]} w={"100%"}>
              <Input {...register("terms")} label="Termos de busca" error={errors.terms} type="text" placeholder="Termos da busca" />
            </SimpleGrid>
          </Stack>

          <SimpleGrid minChildWidth={"170px"} spacing={["6", "8"]} w={"100%"}>
            <Button mt={"8"} w={"100%"} colorScheme={"blue"} size={"lg"} variant={"outline"} onClick={clearForm}>
              Limpar filtro
            </Button>
            <Button mt={"8"} w={"100%"} colorScheme={"orange"} size={"lg"} variant={"outline"} type={"submit"} >
              Aplicar filtro
            </Button>
          </SimpleGrid>
        </DefaultBox>
      </Flex>
    </Collapse>
  );
}
